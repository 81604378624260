import React from 'react';
// import db from "../../config/firebase";
// import LeftPanel from './views/LeftPanel';
import { toast } from "react-toastify";
import moment from 'moment-timezone';
// import { mailUrl } from "../../config/mailFunctions.js";
import { getDateInUTC } from "../livechat/components/Comman";
import { activityLog, logActivityInFirestore } from '../../services/service';

import { MAIL_FUNCTION_URL, DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY, COMMON_LOGIN_URL, commonLoginFacebook, commonLoginGoogle, currencyData, PAYMENT_CHECKOUT_URL, HIDE_TRY_NOW_COUNTRIES, PAYMENT_TRIAL_PERIOD, AMPLITUDE_KEY } from '../../config/siteConfig';

import { checkPaymentStatus } from '../../services/payment';
import firebaseServices from '../../firebase';

// import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
/* For Dropdown End */


// import { MAIL_FUNCTION_URL, DOMAIN_NAME, COMMON_LOGIN_URL, commonLoginFacebook, commonLoginGoogle, currencyData, PAYMENT_CHECKOUT_URL } from '../../config/siteConfig';
// const firebase = require("firebase");

///var md5 = require("md5");
// const dateFormat = "X";

class Subscription extends React.Component {

	constructor() {
		super();
		this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
		if (this.agent.roleType !== 'Owner') {
			window.location.href = "/" + this.agent.ownerIdentifire;
		}
		this.state = {
			plan: 'Free',
			// modalVisible: false,
			billing_cycle: '',
			amount: '',
			agents: '',
			chats_completed: '',
			next_billing_date: '',
			billing_information: '',
			plan_data: [],
			loader: false,
			billingList: '',
			no_of_agent: 1,
			requestKey: '',
			errormessage: '',
			currency: '',
			errormessage: '',
			planprice: '',
			totalprice: '',
			payment_list: [],
			historyCount: 0,
			history_link: '',
			owner_id: '',
			company_name: '',
			owner_email: '',
			current_plan_id: '',
			current_plan_period: '',
			current_agent: 1,
			commonlogin_user_id: '',
			payment_status: '',
			payment_enabled: 0,
			chat_count: 1,
			currencyData: currencyData,
			show_currency: '',
			country_code: 'IN',
			plan_list: [],
			totalAgents: 1,
			paymentDone: false,
			trialDaysleft: 0,
			showTryNowButon: false,
			trialStatus: true,
			currPaymentStatus: false,
			countryCode: '',
			originalCountryCode: '',
			currencyCode: '',
			last_billing_date: '-',
			add_no_of_agent: 1,
			total_add_agent_price: 0
		}

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getRequestKey = this.getRequestKey.bind(this);
		this.validateStatusData = this.validateStatusData.bind(this);
		this.openPopup = this.openPopup.bind(this);
	}

	componentWillUnmount() {

	}

	openPopup = () => {
		var billingData = this.state.billing_information.split('_');
		var agent = 0;
		if (this.state.payment_status === "trial" || this.state.payment_status === "trialWithoutCC" || this.state.plan === "Free") {
			agent = 0;
		} else {
			agent = parseInt(this.state.current_agent);
		}
		/* if (parseInt(this.state.add_no_of_agent) < parseInt(this.state.current_agent) || this.state.add_no_of_agent === '') {
			agent = parseInt(this.state.current_agent);
		} */
		// var netprice = parseInt(billingData[4]) * parseInt(agent);
		// var netprice2 = parseInt(billingData[4]) * parseInt(this.state.add_no_of_agent);
		this.setState({
			// total_add_agent_price: netprice2,
			// totalprice: netprice,
			no_of_agent: agent,
			// modalVisible: true,
			errormessage: '',
			loader: true
		});
		if (this.state.showTryNowButon && !this.state.trialStatus && this.state.currPaymentStatus === false) {
			this.handleSubmit('trial'); // todo
		}
		else {
			this.handleSubmit('pay'); // todo
		}
		logActivityInFirestore('Subscription upgrade button clicked', "upgrade_action_btn");
	}

	/* openPopup_DH = () => {
		var billingData = this.state.billing_information.split('_');
		var agent = this.state.no_of_agent;
		if (parseInt(this.state.no_of_agent) < parseInt(this.state.current_agent) || this.state.no_of_agent === '') {
			agent = parseInt(this.state.current_agent);
		}
		var netprice = parseInt(billingData[4]) * parseInt(agent);
		this.setState({
			totalprice: netprice, no_of_agent: agent, modalVisible: true, errormessage: ''
		})
		logActivityInFirestore('Subscription upgrade button clicked', "upgrade_action_btn");
	} */

	// closeModal = () => this.setState({
	// 	modalVisible: false
	// });

	handleHistory = () => {
		this.setState({
			loader: true
		})
		var agentData = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
		if (agentData) {
			var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };
			fetch(GATEWAY_URL + "/encryptData", {
				method: "post",
				body: JSON.stringify(body),
				headers: {
					"X-API-Key": FUNCTION_API_KEY,
					Accept: 'application/x-www-form-urlencoded',
					'Content-Type': 'application/json'
				}
			}).then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.status === 200) {
						this.setState({
							history_link: responseJson.historyLink,
							loader: false
						}, () => {
							// var form = document.getElementById('historydata');
							// form.submit();
							console.log("-------- handleSubmit no_of_agent  --------- >>>> " + responseJson.historyLink);
	
							const form = document.createElement("form");
                            form.setAttribute("id", "paymentForm");
                            form.setAttribute("method", "POST");
                            // form.setAttribute("target", "_blank");
                            form.setAttribute("action", PAYMENT_CHECKOUT_URL+`/billing/dashboard`);
                        
                            const input = document.createElement("input");
                            input.setAttribute("type", "hidden");
                            input.setAttribute("name", "request");
                            input.setAttribute("value", responseJson.historyLink);
                        
                            form.appendChild(input);
                        
                            document.body.appendChild(form);
                            form.submit();
                            document.body.removeChild(form);
						})
					} else {
						toast.error("Something went wrong, Please try again after some time!");
					}
				});
		} else {
			toast.error("Something went wrong, Please try again after some time!");
		}
	};

	// handleChange = (e) => {
	// 	let target = e.target;
	// 	let values;
	// 	if (target.name === "billing_information") {
	// 		var billingData = target.value.split('_');
	// 		let plan_period = (billingData && billingData.length > 0 && billingData[2]) ? billingData[2] : 'monthly';
	// 		if (this.state.totalAgents === '') {
	// 			var netprice = parseInt(billingData[4]);
	// 			this.setState({
	// 				total_add_agent_price: netprice,
	// 				billing_information: target.value,
	// 				planprice: billingData[4],
	// 				totalprice: netprice
	// 			})
	// 		} else {
	// 			let multiplyNumber = parseInt(this.state.add_no_of_agent);
	// 			if (isNaN(multiplyNumber)) {
	// 				multiplyNumber = 1;
	// 			}
	// 			let netprice2 = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 			let netprice = 0;
	// 			let tmpTotalAgents = 0;
	// 			if (plan_period === "yearly") {
	// 				if (this.state.current_plan_period === 'yearly') {
	// 					if (this.state.payment_status === 'trial' || this.state.plan === "Free") {
	// 						tmpTotalAgents = parseInt(multiplyNumber);
	// 					} else {
	// 						tmpTotalAgents = parseInt(this.state.current_agent) + parseInt(multiplyNumber);
	// 					}
	// 				} else {
	// 					tmpTotalAgents = parseInt(multiplyNumber);
	// 				}
	// 				netprice = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 			} else {
	// 				if (this.state.payment_status === 'trial' || this.state.plan === "Free") {
	// 					tmpTotalAgents = parseInt(multiplyNumber);
	// 				} else {
	// 					tmpTotalAgents = parseInt(this.state.current_agent) + parseInt(multiplyNumber);
	// 				}
	// 				netprice = parseInt(billingData[4]) * parseInt(this.state.no_of_agent);
	// 			}
	// 			this.setState({
	// 				no_of_agent: tmpTotalAgents,
	// 				total_add_agent_price: netprice2,
	// 				billing_information: target.value,
	// 				planprice: billingData[4],
	// 				totalprice: netprice,
	// 				errormessage: ''
	// 			});
	// 		}
	// 	} else if (target.name === "add_no_of_agent") {
	// 		var valueData = target.value.replace(/\+|-/ig, '');
	// 		valueData = valueData.replace('+', '');
	// 		valueData = valueData.replace('-', '');
	// 		valueData = valueData.replace('.', '');
	// 		var billingData = this.state.billing_information.split('_');
	// 		let plan_period = (billingData && billingData.length > 0 && billingData[2]) ? billingData[2] : 'monthly';
	// 		var multiplyNumber = parseInt(valueData);
	// 		if (isNaN(multiplyNumber)) {
	// 			multiplyNumber = 1;
	// 		}

	// 		let tmpTotalAgents = 0;
	// 		let netprice2 = 0;

	// 		if (plan_period === "yearly") {
	// 			if (this.state.current_plan_period === 'yearly') {
	// 				if (this.state.payment_status === 'trial' || this.state.plan === "Free") {
	// 					tmpTotalAgents = parseInt(multiplyNumber);
	// 				} else {
	// 					tmpTotalAgents = parseInt(this.state.current_agent) + parseInt(multiplyNumber);
	// 				}
	// 			} else {
	// 				tmpTotalAgents = parseInt(multiplyNumber);
	// 			}
	// 			netprice2 = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 		} else {
	// 			if (this.state.payment_status === 'trial' || this.state.plan === "Free") {
	// 				tmpTotalAgents = parseInt(multiplyNumber);
	// 			} else {
	// 				tmpTotalAgents = parseInt(this.state.current_agent) + parseInt(multiplyNumber);
	// 			}
	// 			netprice2 = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 		}
	// 		var netprice = parseInt(billingData[4]) * parseInt(tmpTotalAgents);
	// 		this.setState({
	// 			add_no_of_agent: (valueData <= 0) ? '' : valueData,
	// 			total_add_agent_price: netprice2,
	// 			no_of_agent: tmpTotalAgents,
	// 			totalprice: netprice,
	// 			errormessage: '',
	// 			totalAgents: tmpTotalAgents
	// 		});
	// 	}
	// 	/* else if (target.name === "no_of_agent") {
	// 		var valueData = target.value.replace(/\+|-/ig, '');;
	// 		valueData = valueData.replace('+', '');
	// 		valueData = valueData.replace('-', '');
	// 		valueData = valueData.replace('.', '');
	// 		var billingData = this.state.billing_information.split('_');
	// 		var multiplyNumber = parseInt(valueData);
	// 		var checkNumber = isNaN(valueData);
	// 		if (checkNumber === true) {
	// 			valueData = this.state.no_of_agent;
	// 			multiplyNumber = 1;
	// 		}
	// 		else if (valueData < 1) {
	// 			multiplyNumber = 1;
	// 		}
	// 		var netprice = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 		this.setState({ no_of_agent: valueData, totalprice: netprice, errormessage: '', totalAgents: valueData ? parseInt(valueData) + 1 : 1 });
	// 	} */ else {
	// 		values = target.value;
	// 	}

	// }

	async getRequestKey(requestData) {
		//const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
		const response = await fetch(GATEWAY_URL + "/generateKey", {
			method: "post",
			body: JSON.stringify(requestData),
			headers: {
				"X-API-Key": FUNCTION_API_KEY,
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status === 200) {
					this.setState({ requestKey: responseJson.stringdata });
					return true;
				} else {

					return false
				}
			});
		return response;
	}

	getPlanData() {
		var agentData = JSON.parse(localStorage.getItem('agent'));
		var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };

		//fetch(MAIL_FUNCTION_URL + "/payment/plandetail", {
		fetch(GATEWAY_URL + "/plandetail", {
			method: "post",
			body: JSON.stringify(body),
			headers: {
				"X-API-Key": FUNCTION_API_KEY,
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status === 200) {
					var priceData = responseJson.priceData;
					if (priceData.length > 0) {
						var billing_information = '';
						var planPrice;
						var agent = parseInt(this.state.no_of_agent);
						if (this.state.current_plan_period === 'yearly') {
							let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[1].currencySign;
							billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
							planPrice = priceData[1].planPrice;
						} else {
							let currencySign = priceData[0].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[0].currencySign;
							billing_information = priceData[0].productName + '_' + priceData[0].planId + '_' + priceData[0].planPeriod + '_' + currencySign + '_' + priceData[0].planPrice;
							planPrice = priceData[0].planPrice;
						}

						var netprice = parseInt(planPrice) * parseInt(agent);
						var planList = [];
						var optionData;

						for (const [index, value] of responseJson.priceData.entries()) {
							optionData = { name: value.planPeriod, keyValue: value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + this.state.currencyData[value.currencyCode] + '_' + value.planPrice };
							if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {

							}
							else {
								planList.push(optionData);
							}
						}

						var history_link = responseJson.history_link
						this.setState({ totalprice: netprice, history_link: history_link, currency: this.state.currencyData[priceData[0].currencyCode], planprice: planPrice, billing_information: billing_information, plan_data: responseJson.priceData, plan_list: planList, countryCode: priceData[0].country, originalCountryCode: priceData[0].originalCountryCode, currencyCode: priceData[0].currencyCode });
					}
					return true
				} else {
					return false;
				}
			});
	}

	validateStatusData = () => {
		var checkNumber = isNaN(this.state.no_of_agent);
		var checkPaymentType = true;
		// return false;
		var billing_information = this.state.billing_information;
		var billingData = billing_information.split('_');

		if (billingData[1] === this.state.billing_cycle) {
			checkPaymentType = true;
		} else if (this.state.billing_cycle === "monthly" && billingData[2] === "yearly") {
			checkPaymentType = false;
		}
		if (this.state.payment_status === 'trial' || this.state.payment_status === "trialWithoutCC" ) {
			checkPaymentType = false;
		}

		var billingData = this.state.billing_information.split('_');
		if (checkNumber) {
			this.setState({ loader: false, errormessage: 'Please enter valid no of Agent' });
			return false;
		}
		else if (this.state.no_of_agent < 1) {
			this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be greater than 0' });
			return false;
		}
		else if ((this.state.no_of_agent) > 999) {
			this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be less than 1000' });
			return false;
		}
		/* else if (parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent) && this.state.paymentDone === true && checkPaymentType === true) {
			this.setState({ loader: false, errormessage: 'Agent count cannot be less than or equalto count of agent for which subscription has already been bought' });
			return false;
		} */
		/*else if(( this.state.current_plan_period ===  billingData[2] ) && ( parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent )) )
		{
			this.setState({loader:false,errormessage:'Please plan and no of agent are same, either increase no of agent and change plan period'});	
			return false;
		}*/
		else {
			return true;
		}
	}

	async handleSubmit(paymentType = 'pay') {
		// e.preventDefault();
		// this.setState({ loader: true });
		var no_of_agent = this.state.agents;

		console.log("-------- handleSubmit no_of_agent  --------- >>>> " + no_of_agent);

		var billing_information = this.state.billing_information;
		var checkCondition = await this.validateStatusData();
		if (checkCondition === false) {
			return false;
		}
		else {
			var agentData = JSON.parse(localStorage.getItem('agent'));
			var ownerEmail;
			var ownerId;
			var companyName;
			var curentPlanId;
			var currentPlanPeriod;

			if (billing_information === undefined || billing_information === ''){
				billing_information = "livechat_6_monthly_$_10";
			}
			var billingData = billing_information.split('_');            			

			var uniqueId = Date.now() + ((Math.random() * 100000).toFixed());
			var orderId = 'livechat_' + uniqueId;
			if (curentPlanId === "undefined" || curentPlanId === undefined) {
				curentPlanId = "";
				currentPlanPeriod = "";
			}

			/* Added for script chat start */

			let success_url = DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentsuccess';
			if (paymentType === "pay") {
				success_url += '?ptype=paid&cpId=' + this.state.current_plan_id;
			} else {
				success_url += '?ptype=trial&cpId=' + this.state.current_plan_id;
			}
			if (this.state.plan === "Free") {
                no_of_agent = 0;
            }
            var agentString = "Current no of agent " + no_of_agent;
            var myArray = JSON.stringify({ "1": agentString });
			/* Added for script chat end */
			if (this.state.plan === "Free" && this.agent.payment_method !== "trialWithoutCC" && this.agent.plan_price !== "trialWithoutCC") {
				// logic to give free trial without credit card details
				console.log('------------new url----------->')				
				var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
				if (paymentType === "pay") {
					priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
				}
			}
			else {								
				console.log('------------older url----------->')
				var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
				if (paymentType === "pay") {
					priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
				}
			}
			var netprice = parseInt(billingData[4]) * parseInt(no_of_agent);			
			
			var requestResponse = await this.getRequestKey({ stringdata: priceKey, action: 'encrypt', no_of_agents: no_of_agent, netprice: netprice, currency: billingData[3], plan_name: 'Premium', plan_id: billingData[1], product_name: billingData[0], plan_period: billingData[2], order_id: orderId, product_id: this.state.owner_id });

			if (requestResponse === true) {
		
				if (paymentType === "pay") {
					logActivityInFirestore('Subscription upgrade plan button clicked', "upgrade_plan_btn");
				} else {
					logActivityInFirestore('Subscription try now button clicked', "try_now_btn");
				}
				var form = document.getElementById('checkout');
				this.setState({loader: false});
				form.submit();
			}
			else {
				return false;
			}
		}
	}

	async componentDidMount() {
		var body = document.getElementById('root');
		body.classList.add("plan-upgrade")
		this.setState({ loader: true });

		//await this.getRequestKey();
		const userDetails = JSON.parse(localStorage.getItem('agent'));
		let currentUnixTimeStamp = await getDateInUTC();
		await fetch('https://snappy.appypie.com/webservices/ClientDetails.php', {
			method: "get",
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status === 1) {
					const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(responseJson.CountryCode) > -1 ? false : true;
					this.setState({ country_code: responseJson.CountryCode, showTryNowButon: showTryNowButon });
				} else {

					this.setState({ country_code: 'US' });
				}
			});
		await firebaseServices.db.collection("users").doc(userDetails.ownerId).get().then(async user => {
			if (user.exists) {
				var currentStatus = '0';
				var plan_type = 'Free';
				var billing_cycle = '';
				var cost = '';
				var next_billing_date = '';
				var no_of_agents = 1;
				var plan_id = '';
				var user_id = user.data().user_id;
				var no_of_agent = 1;
				var owner_email = '';
				var payment_status = '';
				var payment_enabled = this.state.payment_enabled;
				var chat_count = 1;
				var show_currency = '';
				var totalAgents = 1;
				var paymentDone = false;
				var currPaymentStatus = await checkPaymentStatus(user.data());
				var last_billing_date = '';

				if (userDetails.ownerEmail === 'undefined' || userDetails.ownerEmail === undefined) {
					owner_email = user.data().email;

				}
				else {
					owner_email = userDetails.ownerEmail;
				}
				//	alert(user.data().payment_status);
				if (user.data().payment_status === 'undefined' || user.data().payment_status === undefined) {
					currentStatus = '0'
					payment_status = 'complete'
					payment_enabled = 0;
					chat_count = 1;
					show_currency = '';
					totalAgents = parseInt(no_of_agents) + 1;
				}
				else {
					//   var billingDate = user.data().next_billing_date;
					var billinUnixTimestamp = Math.round(new Date(user.data().next_billing_date + " 00:00:00.000").getTime());
					// var currentUnixTimeStamp =Math.round(new Date().getTime());
					let splitDate = user.data().next_billing_date ? user.data().next_billing_date.split('-') : '';
					let splitDay = parseInt(splitDate[2]);
					var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
					paymentDate = paymentDate + 86400000;

					var billingDate = new Date(user.data().next_billing_date);
					var dateString = '';
					if (billingDate.getDate() < 10) {
						dateString = '0' + billingDate.getDate();
					}
					else {
						dateString = billingDate.getDate();
					}
					if (billingDate.getMonth() + 1 < 10) {
						dateString = dateString + '-0' + (billingDate.getMonth() + 1);
					}
					else {
						dateString = dateString + '-' + (billingDate.getMonth() + 1);
					}
					dateString = dateString + '-' + billingDate.getFullYear();
					if (user.data().payment_status === 'complete') {
						show_currency = this.state.currencyData[user.data().currency]
						if (user.data().currency === 'undefined' || user.data().currency === undefined) {
							show_currency = this.state.currencyData.USD;
						}
						payment_enabled = 0;
						chat_count = 0;
						payment_status = user.data().payment_status;
						currentStatus = 1;
						plan_type = user.data().plan_type;
						billing_cycle = user.data().billing_cycle;
						cost = user.data().plan_price;
						next_billing_date = dateString;
						no_of_agents = user.data().no_of_agents;
						plan_id = user.data().plan_id;
						user_id = user.data().user_id;
						no_of_agent = parseInt(no_of_agents) + 1;
						totalAgents = parseInt(no_of_agent);
						paymentDone = true;
						last_billing_date = user.data().payment_data.addedon;
					}
					else if (paymentDate >= currentUnixTimeStamp) {
						show_currency = this.state.currencyData[user.data().currency]
						if (user.data().currency === 'undefined' || user.data().currency === undefined) {
							show_currency = this.state.currencyData.USD;
						}
						payment_enabled = 1;
						chat_count = 0;
						payment_status = user.data().payment_status;
						currentStatus = 1;
						plan_type = user.data().plan_type;
						billing_cycle = user.data().billing_cycle;
						cost = user.data().plan_price;
						next_billing_date = dateString;
						no_of_agents = user.data().no_of_agents;
						plan_id = user.data().plan_id;
						user_id = user.data().user_id;
						no_of_agent = parseInt(no_of_agents) + 1;
						totalAgents = parseInt(no_of_agent);
						paymentDone = true;
						last_billing_date = user.data().payment_data.addedon;
					}
					else {
						show_currency = '';
						payment_enabled = 0;
						chat_count = 1;
						totalAgents = parseInt(no_of_agents) + 1;
						paymentDone = false;
					};

					if (user.data().payment_status === 'trial'  || user.data().payment_status === "trialWithoutCC" ) {
						let serverTimeStamp = await getDateInUTC();
						var timeDiff = paymentDate - serverTimeStamp;
						var daysDifference = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
						no_of_agent = 1;
					}
				}
				var no_chat_served = user.data().no_chat_served;
				var chat_limited = user.data().chat_limited;
				if (user.data().no_chat_served === 'undefined' || user.data().no_chat_served === undefined) {
					no_chat_served = 0;
				}

				if (user.data().chat_limited === 'undefined' || user.data().chat_limited === undefined) {
					chat_limited = 500;
				}


				this.setState({
					payment_enabled: payment_enabled,
					payment_status: payment_status,
					owner_id: user.id,
					company_name: user.data().company_name,
					owner_email: owner_email,
					current_plan_id: plan_id,
					current_agent: no_of_agents,
					current_plan_period: billing_cycle,
					plan: plan_type,
					billing_cycle: billing_cycle,
					amount: cost,
					agents: no_of_agents,
					chats_completed: no_chat_served + '/' + chat_limited,
					commonlogin_user_id: user_id,
					next_billing_date: next_billing_date,
					no_of_agent: no_of_agent,
					chat_count: chat_count,
					show_currency: show_currency,
					totalAgents: totalAgents,
					paymentDone: paymentDone,
					trialDaysleft: daysDifference ? daysDifference : 0,
					trialStatus: user.data().trialStatus === true ? true : false,
					currPaymentStatus: currPaymentStatus,
					last_billing_date: moment(parseInt(last_billing_date)).format('DD-MM-YYYY')
				});
			}
		});
		await this.getPlanData();
		/*await firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then((docs) => {
				 if(docs.size>= 1)
				 {		 
					let data=docs.data();
					 this.setState({
					  plan:data.payment_data.plan_type,
					  billing_cycle:data.payment_data.billing_cycle,
					  amount:data.payment_data.cost,
					  agents:data.payment_data.no_of_agents,
					  chats_completed:'200/500',
					  next_billing_date:data.payment_data.next_billing_date
					})
				}
				})*/

		await firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('payment_log').get().then((docs) => {
			var payment_data = [];
			var i;
			if (docs.size >= 1) {
				docs.forEach((doc) => {
					payment_data.push(doc.data())
					i++;
				});
			}
			this.setState({ payment_list: payment_data, historyCount: docs.size });

		})

		this.setState({ loader: false });
		activityLog('Opened subscription page');
		logActivityInFirestore('View subscription page', "subscription");
	}

	sideMenuePhone = () => {
		var sideMenuePhone = document.getElementById('sidebarnavChild');
		sideMenuePhone.classList.toggle('sideMenue')
	}

	render() {
		/* const items = [];
		var keyValue;
		var i = 0;
		for (const [index, value] of this.state.plan_data.entries()) {
			keyValue = value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + value.currencySign + '_' + value.planPrice;
			i = i + 1;
			if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {
			}
			else {
				items.push(<option key={keyValue} value={keyValue}>{value.planPeriod}</option>)
			}
		} */
		return (
			<>
				<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
				<header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
					<h3 className="heding-text">Billing</h3>
					<div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
						<svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
					</div>
				</header>
				{/* <LeftPanel /> */}
				<div className="page-wrapper sidebar-collapse" data-mobile-height="" style={{marginLeft: "53px", width: "calc(100% - 53px)"}}>
					<div className="main-wrapper container-fluid">
						<div className="main-heading main-heading-title py-5">
							<h3 className="heding-text">Current Plan</h3>
						</div>
						<div className="body-section bg-white px-5 py-5">
							<div className="agent-section " id="resposnive-table-class">
								<div className="row">

									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<div className="agent-table">
											<table className="table table-borderless mb-0 liveCgatUserTable subscription-tbl">
												<thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
													<tr>
														<th>Plan Name</th>
														<th>Billing Cycle</th>
														<th>Amount</th>
														<th>Agents</th>
														{/* <th style={{ display: this.state.chat_count ? 'block' : 'none' }}>Chats Completed</th> */}
														<th>Chats Completed</th>
														<th>Last Billing Date</th>
														<th>{this.state.payment_status === 'canceled' ? 'Expiry Date' : 'Next Billing Date'}</th>
														{/* <th style={{ display: this.state.payment_enabled ? 'block' : 'none' }}>Payment Status</th> */}
														<th>Payment Status</th>
														<th style={{ textAlign: 'center' }}>Subscription</th>
													</tr>

												</thead>
												<tbody className="outline-1-black border-radius-table">
													{this.state.payment_status === 'trial' || this.state.payment_status === "trialWithoutCC"  ? (<tr>
														<td>Trial <span style={{ fontSize: '12px', color: '#DD6A6A', fontWeight: 'bold' }}>({this.state.trialDaysleft} {parseInt(this.state.trialDaysleft) < 2 ? 'Day' : 'Days'} Left)</span></td>
														<td>{this.state.billing_cycle}</td>
														<td>{this.state.show_currency}0</td>
														<td>{this.state.agents=='0'?'1':this.state.agents}</td>
														{/* <td style={{ display: this.state.chat_count ? 'block' : 'none' }}>{this.state.chats_completed}</td> */}
														<td>Unlimited</td>
														<td>-</td>
														<td>{this.state.next_billing_date}</td>
														{/* <td style={{ display: this.state.payment_enabled ? 'block' : 'none' }}>{this.state.payment_status}</td> */}
														<td>{this.state.plan === 'Free' ? '-' : this.state.payment_status}</td>
														<td style={{ textAlign: 'center' }}><button className="verifyBtn" onClick={this.openPopup}>Upgrade</button>&nbsp;
															{this.state.payment_status === 'canceled' ? (null) : (<span className="text-light-blue cursorPointer" onClick={this.handleHistory}>Cancel </span>)}
															{/* <button className="verifyBtn" onClick={this.handleHistory}>Cancel</button> */}
															<form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
																<input type="hidden" name="request" value={this.state.requestKey} />
															</form>
														</td>
													</tr>) : (<tr>
														<td>{this.state.plan}</td>
														<td>{this.state.plan === 'Free' ? '-' : this.state.billing_cycle}</td>
														<td>{this.state.plan === 'Free' ? '-' : this.state.show_currency + ' ' + this.state.amount}</td>
														<td>{this.state.plan === 'Free' ? '-' : this.state.agents}</td>
														{/* <td style={{ display: this.state.chat_count ? 'block' : 'none' }}>{this.state.chats_completed}</td> */}
														<td>{this.state.plan === 'Free' ? 'Test Drive' : 'Unlimited'}</td>
														<td>{this.state.plan === 'Free' ? '-' : this.state.last_billing_date}</td>
														<td>{this.state.plan === 'Free' ? '-' : this.state.next_billing_date}</td>
														{/* <td style={{ display: this.state.payment_enabled ? 'block' : 'none' }}>{this.state.payment_status}</td> */}
														<td>{this.state.plan === 'Free' ? '-' : this.state.payment_status}</td>
														<td style={{ textAlign: 'center' }}>
															<button className="verifyBtn" onClick={this.openPopup}>Upgrade</button>&nbsp;
															{this.state.plan === 'Free' || this.state.payment_status === 'canceled' ? '' : <span className="text-light-blue cursorPointer" onClick={this.handleHistory}>Cancel </span>}
															<form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
																<input type="hidden" name="request" value={this.state.requestKey} />
															</form>
														</td>
													</tr>)}
												</tbody>
											</table>
											<div className="text-right " style={{ display: this.state.historyCount ? 'block' : 'none' }}><form id="historydata" method="POST" action={PAYMENT_CHECKOUT_URL + "/billing/dashboard"} target="_blank"> <input type="hidden" name="request" value={this.state.history_link} /><span className="text-light-blue cursorPointer" onClick={this.handleHistory}>View payment history </span></form></div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>


				</div>

				{/* 
				<div className="popupShadow-upgradePlan" style={{ display: this.state.modalVisible ? 'block' : 'none' }}></div>
				<div className="popupInstallLivechat popupLivechat planPopup" style={{ display: this.state.modalVisible ? 'block' : 'none' }}>
					<div className="warning-ms">
						<p>
							<span className="info-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
								</svg>
							</span>
							<span className="plan-txt">Please upgrade to our paid plan to receive inquiries from your visitors in Live mode.</span>
							<span className="heder-icon-img" onClick={this.closeModal}>✖︎</span>
						</p>
					</div>
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
						<h6 className="card-title mb-0 white-text">Plan Selection</h6>
						//  <span className="close-popup ml-auto" onClick={this.closeModal} style={{ display: 'none' }}>
						// 	<svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
						// 		<path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
						// 		<g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
						// 			<rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
						// 			<rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
						// 		</g>
						// 	</svg>
						// </span> 
					</div>

					<div className="popupBody pb-5 px-8 text-center">
						<div className="billingDetails">
							<ul>
								<li>
									<div className="billing-text k-flex align-items-center">
										<div className="plan-section-left"><h6>Plan</h6></div>
										<div className="plane-section-right"> <p className="ml-auto">Premium</p></div>
									</div>
								</li>
								<li>
									<div className="billing-text k-flex align-items-center">
										<div className="plan-section-left"><h6>Billing Cycle</h6></div>
										<div className="plane-section-right">
											<div className="material-select">
												<FormControl variant="outlined" className="material-outlined w-150 no-border">
													<InputLabel id="billing_information-select-outlined-label">Billing Cycle</InputLabel>
													<Select MenuProps={{ disableScrollLock: true }} labelId="billing_information-select-outlined-label" id="billing_information" name="billing_information" value={this.state.billing_information ? this.state.billing_information : ""} defaultValue={this.state.billing_information ? this.state.billing_information : ""} onChange={this.handleChange} label="Billing Cycle">
														{this.state.plan_list.map((i, k) => {
															return <MenuItem key={i.keyValue} value={i.keyValue}>{i.name}</MenuItem>
														})}
													</Select>
												</FormControl>
											</div>
											// <select name="billing_information" id="billing_information" value={this.state.billing_information}
											// onChange={this.handleChange}  >  {this.state.plan_list.map((i, k) => {
											// 	return <option key={i.keyValue} value={i.keyValue}>{i.name}
											// 	</option>
											// })}</select> 

										</div>
									</div>
								</li>
								<li>
									<div className="billing-text k-flex align-items-center">
										<div className="plan-section-left"><h6>Agents</h6></div>
										<div className="plane-section-right">
											<div className="ml-auto no-border">
												<div className="input-group material-input">
													<TextField label="Agents" id="add_no_of_agent" type="number" className="form-control w-150" name="add_no_of_agent" value={this.state.add_no_of_agent} onChange={this.handleChange} onKeyUp={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 1 } }} variant="outlined" />
													// <TextField label="Agents" id="no_of_agent" type="number" className="form-control w-150" name="no_of_agent" value={this.state.no_of_agent} onChange={this.handleChange} onKeyUp={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 1 } }} variant="outlined" />
												</div>
												//  <input type="number" name="no_of_agent" id="no_of_agent" onChange={this.handleChange} onKeyUp={this.handleChange} value={this.state.no_of_agent} maxLength="3" min="1" />
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="billing-text k-flex align-items-center">
										<div className="plan-section-left"><h6>Total</h6></div>
										<div className="plane-section-right"><p className="ml-auto">{this.state.currency}{this.state.total_add_agent_price}
										//  this.state.totalprice
										</p></div>
									</div>
								</li>
							</ul>
						</div>

						<div className="keyFeatures mb-5">
							<div className="DetsilsTitle mb-3 text-left key-features-h mt-5">
								<h5>Key features</h5>
							</div>
							<div className="keyFeaturesList text-left features">
								<ul>
									<li>
										<div className="keyFeaturesListText k-flex align-items-center">
											<img src={require("../../assets/images/checked.svg")} alt="" />
											<p className="pl-2">Unlimited chats</p>
										</div>
									</li>
									<li>
										<div className="keyFeaturesListText k-flex align-items-center">
											<img src={require("../../assets/images/checked.svg")} alt="" />
											<p className="pl-2">Get Enriched Leads and Identify Prospects</p>
										</div>
									</li>
									<li>
										<div className="keyFeaturesListText k-flex align-items-center">
											<img src={require("../../assets/images/checked.svg")} alt="" />
											<p className="pl-2">Integrate with Twitter account</p>
										</div>
									</li>
									<li>
										<div className="keyFeaturesListText k-flex align-items-center">
											<img src={require("../../assets/images/checked.svg")} alt="" />
											<p className="pl-2">Integrate with Facebook page</p>
										</div>
									</li>
								</ul>
							</div>
							<div style={{ color: 'red' }}>{this.state.errormessage}</div>
							<div className={this.state.showTryNowButon && !this.state.trialStatus && this.state.currPaymentStatus === false ? "my-4 try-now" : "my-4"}>
								<button onClick={this.handleSubmit} className="btnBlueSettings add-agent-clik mt-5">Upgrade</button>
								{this.state.showTryNowButon && !this.state.trialStatus && this.state.currPaymentStatus === false ? (<button onClick={(e) => { this.handleSubmit(e, 'trial') }} className="btnBlueSettings add-agent-clik">Try Now</button>) : (null)}
							</div>
						</div>

					</div> 

				</div>
*/}
			</>

		)
	}

}

export default Subscription;
