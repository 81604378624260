import React from "react";
import { ToastContainer } from "react-toastify";
import Profile from '../../modules/profile/updateProfile';
import "react-toastify/dist/ReactToastify.css";
// import db from "../../config/firebase";
//import RawTooltip from "../Tooltip/rawtooltip";
import defaultRoles from "../../localization/roles.json";
// import { addAgentsInList } from "../../modules/livechat/components/Dblovefield";
//import { logoutCommonLogin } from "../../config/SignupLogin";
import { getAgentFromId, getOwnerDetails, ownerLogoutCommon } from "../../services/service";
import { deleteFilters } from "../../modules/livechat/components/Dblovefield";
import { loadCapabilityToken, startCall, endCall, dialExtension, sendOrCreateVisitorSMS } from '../../modules/livechat/components/Twilio';
import Incomingnotification from '../../modules/livechat/view/leftSection/Incomingnotification';
import { Link } from "react-router-dom";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig";
import { isMobileDevice } from '../../modules/livechat/components/Comman';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from "../../firebase";

const enableDialer = false;
const enablePopup = false;
const moment = require("moment-timezone");
console.log("sidebar")

class SidebarNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    this.state = {
      profile_display: false,
      logout_status: false,
      currentUrl: '',
      showEditProfile: false,
      agentProfilePic: '',
      agentName: '',
      agentEmail: '',
      agentId: '',
      ownerId: '',
      agentStatus: '',
      roles: [],
      OtherRoles: [],
      role: '',
      userManagementPage: 'agents',
      modalVisible: false,
      logoutModel: false,
      showDialer: false,
      showSmsArea: false,
      widgetVerify: false,
      loader: false,
      mobileMenuC: false,
      isMobileDevice: isMobileDevice()
    }

    /*Twilio Function Start */
    loadCapabilityToken(this.agent);
    /*Twilio Function End */
  }

  componentWillMount() {
    let userDetails = JSON.parse(localStorage.getItem('agent'));
    if (userDetails) {
      this.getRoles();
    }
  }

  getRoles = () => {
    let userDetails = JSON.parse(localStorage.getItem('agent'));
    let promise1 = new Promise((res, rej) => {
      // try {
      //   firebaseServices.db.collection('agent-roles').doc(userDetails.role).get().then((docs) => {
      //     if(docs.exists) {
      //         res(docs.data());
      //     } else {
      //         res(false);
      //     }
      //   }).catch(() => {
      //     rej();
      //   })
      // } catch (err) {}
      res(false);
    })

    let promise2 = new Promise((Resolve, Reject) => {
      try {
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').doc(userDetails.role).get().then((docs) => {
          if (docs.exists) {
            Resolve(docs.data());
          } else {
            Resolve(false);
          }
        }).catch((err) => {
          Reject();
        })
      } catch (err) {
        console.log(err);
      }

    })

    Promise.all([promise1, promise2]).then((result) => {
      if (result[0] !== false) {
        let name = result[0].name;
        if (localStorage.getItem('userRole') !== name) {
          localStorage.setItem('userRole', name);
        }
        this.setState({
          role: name
        })
        if (name === 'Agent') {
          let OtherRoles = [];
          Object.keys(defaultRoles['default'][name]['Others']).map(k => {
            OtherRoles.push(k);
          })
          this.setState({
            OtherRoles: OtherRoles
          })
        }
      } else {
        let roleType = result[1].type;
        if (roleType === 'default') {
          let name = result[1].name;
          if (localStorage.getItem('userRole') !== name) {
            localStorage.setItem('userRole', name);
          }
          this.setState({
            role: name
          })
          if (name === 'Agent') {
            let OtherRoles = [];
            Object.keys(defaultRoles['default'][name]['Others']).map(k => {
              OtherRoles.push(k);
            })
            this.setState({
              OtherRoles: OtherRoles
            })
          }
        } else {
          let access = result[1].access;
          let roles = [];
          let OtherRoles = [];
          if ('Users Management' in access) {
            if (Object.values(access['Users Management']).indexOf('agents') !== -1) {
              this.setState({
                userManagementPage: 'agents'
              })
            } else if (Object.values(access['Users Management']).indexOf('departments') !== -1) {
              this.setState({
                userManagementPage: 'departments'
              })
            } else if (Object.values(access['Users Management']).indexOf('roles') !== -1) {
              this.setState({
                userManagementPage: 'roles'
              })
            }
          }
          Object.keys(access).map(j => {
            roles.push(j);
          })

          if ('Others' in access) {
            Object.keys(access['Others']).map(k => {
              OtherRoles.push(access['Others'][k]);
            })
          }
          this.setState({
            roles: roles,
            OtherRoles: OtherRoles,
            role: result[1].name
          })
        }
      }
    }).catch((err) => {
      console.log(err);
      // this.getRoles();
    })
  }

  showEditProfile = () => {
    try {
      document.getElementById("shadowMasking").style.display = "block";
    } catch (err) { }
    if (this.state.isMobileDevice) {
      this.setState({
        showEditProfile: true,
        profile_display: false
      }, () => {
        this.mobileRemovC();
      })
    }
    else {
      this.setState({
        showEditProfile: true
      })
    }
  }

  showProfile = () => {
    let newStatus = this.state.profile_display === false ? true : false;
    this.setState({
      profile_display: newStatus,
      showEditProfile: false
    })
  }

  componentDidUpdate() {
    if (this.state.currentUrl !== window.location.pathname) {
      this.setState({
        profile_display: false,
        currentUrl: window.location.pathname,
        showEditProfile: false
      })
    }
  }

  componentDidMount() {
    let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    if (userDetails) {
      this.setState({
        agentId: userDetails.agentId,
        ownerId: userDetails.ownerId
      }, () => {
        this.getAgentProfile();
      })
      this.setState({
        currentUrl: window.location.pathname
      });
      document.body.addEventListener('click', this.handleBodyClick);

      // comment by dharmesh for ticket no 1320 starts here 
      // firebaseServices.db.collection('users').doc(`${userDetails.ownerId}`).get().then(userDocumentSnapshot => {
      //   if (userDocumentSnapshot.exists) {
      //     if (userDocumentSnapshot.data().widget_status) {
      //       this.setState({ widgetVerify: true });
      //     }
      //   }
      // });
      // comment by dharmesh for ticket no 1320 ends here 
    }
  }

  updateStatus = (e) => {
    let value = parseInt(e.target.value);
    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({ online_status: value }).then((ref) => {
      this.setState({
        agentStatus: value
      })
    }).catch((err) => {
      // console.log(err);
    })
  }

  getAgentProfile = () => {
    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).onSnapshot((docs) => {
      if (docs.exists) {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        let data = docs.data();
        if (data.roleType === 'Owner') {
          getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire).then((results) => {
            // getOwnerAfterLogin().then((results) => {
            if (results.status === 200) {
              let profile_img = '';
              if (results.data.thumbnail && results.data.thumbnail !== '' && results.data.thumbnail !== "notexist" && results.data.thumbnail !== 'NOT_EXIST') {
                let currentTime = new Date().getTime();
                profile_img = results.data.imageurl + '/' + results.data.thumbnail + '?v=' + currentTime
              }
              if (userDetails) {
                userDetails.first_name = results.data.first_name
                userDetails.last_name = results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : ''
                userDetails.profile_img = profile_img
                userDetails.profile_img_orig = profile_img
                userDetails.alias = results.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.alias : '';
                if (results.data.alias !== '' && results.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD) {
                  userDetails.name = results.data.alias;
                  userDetails.alias = results.data.alias;
                } else {
                  userDetails.name = results.data.last_name && results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.first_name + ' ' + results.data.last_name : results.data.first_name
                }
                userDetails.agent_name = results.data.last_name && results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.first_name + ' ' + results.data.last_name : results.data.first_name;
                userDetails.timezone = results.data.time_zone ? results.data.time_zone : (moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess());

                /* Added By AG Start */
                userDetails.incoming_desktop_notification = (data.incoming_desktop_notification !== "undefined" && typeof data.incoming_desktop_notification !== "undefined") ? data.incoming_desktop_notification : true;
                userDetails.incoming_play_sound = (data.incoming_play_sound !== "undefined" && typeof data.incoming_play_sound !== "undefined") ? data.incoming_play_sound : true;
                userDetails.new_message_desktop_notification = (data.new_message_desktop_notification !== "undefined" && typeof data.new_message_desktop_notification !== "undefined") ? data.new_message_desktop_notification : true;
                userDetails.new_message_play_sound = (data.new_message_play_sound !== "undefined" && typeof data.new_message_play_sound !== "undefined") ? data.new_message_play_sound : true;
                /* Added By AG End */

                if (localStorage.getItem('agent') !== null && localStorage.getItem('loggingout') === null) {
                  localStorage.setItem('agent', JSON.stringify(userDetails));
                }
              }
              this.setState({
                agentEmail: results.data.email,
                agentName: results.data.first_name + ' ' + (results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : ''),
                agentProfilePic: profile_img,
              })
            } else if (userDetails) {
              this.setState({
                agentEmail: userDetails.email,
                agentName: userDetails.first_name + ' ' + (userDetails.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetails.last_name : ''),
                agentProfilePic: userDetails.profile_img,
              })
            } else {
              this.setState({
                agentEmail: data.email,
                agentName: data.name,
                agentProfilePic: data.profile_img,
              })
            }
          })
        } else {
          getAgentFromId(this.state.agentId).then((results) => {
            if (results.status === 200) {
              let thumbnail = results.data.thumbnail;
              let imageulr = results.dataimageurl;
              let pic = results.data.pic;
              let agentDet = { sub: results.data.preferred_username, first_name: results.data.first_name, last_name: results.data.last_name, alias: results.data.alias, pic: pic, imageurl: imageulr, thumbnail: thumbnail, timezone: results.data.timezone, email: results.data.email };
              // addAgentsInList(agentDet);
              let profile_img = '';
              if (results.data.pic && results.data.pic !== '' && results.data.pic !== "notexist" && results.data.pic !== 'NOT_EXIST') {
                profile_img = results.data.imageurl + '/' + results.data.pic
              }
              if (userDetails) {
                userDetails.first_name = results.data.first_name
                userDetails.last_name = results.data.last_name && results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : '';
                userDetails.profile_img = profile_img
                userDetails.profile_img_orig = profile_img
                console.log(results.data);
                userDetails.alias = results.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.alias : '';
                if (results.data.alias !== '' && results.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD) {
                  userDetails.name = results.data.alias;
                  userDetails.alias = results.data.alias;
                } else {
                  userDetails.name = results.data.last_name && results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.first_name + ' ' + results.data.last_name : results.data.first_name
                }
                userDetails.agent_name = results.data.last_name && results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.first_name + ' ' + results.data.last_name : results.data.first_name;
                userDetails.timezone = results.data.timezone
                userDetails.role = data.role;

                /* Added By AG Start */
                userDetails.incoming_desktop_notification = (data.incoming_desktop_notification !== "undefined" && typeof data.incoming_desktop_notification !== "undefined") ? data.incoming_desktop_notification : true;
                userDetails.incoming_play_sound = (data.incoming_play_sound !== "undefined" && typeof data.incoming_play_sound !== "undefined") ? data.incoming_play_sound : true;
                userDetails.new_message_desktop_notification = (data.new_message_desktop_notification !== "undefined" && typeof data.new_message_desktop_notification !== "undefined") ? data.new_message_desktop_notification : true;
                userDetails.new_message_play_sound = (data.new_message_play_sound !== "undefined" && typeof data.new_message_play_sound !== "undefined") ? data.new_message_play_sound : true;
                /* Added By AG End */

                if (localStorage.getItem('agent') !== null && localStorage.getItem('loggingout') === null) {
                  localStorage.setItem('agent', JSON.stringify(userDetails));
                }
              }
              this.setState({
                agentEmail: results.data.email,
                agentName: results.data.first_name + ' ' + (results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : ''),
                agentProfilePic: profile_img,
              })
            } else if (userDetails) {
              this.setState({
                agentEmail: userDetails.email,
                agentName: userDetails.first_name + ' ' + (userDetails.last_name ? userDetails.last_name : ''),
                agentProfilePic: userDetails.profile_img,
              })
            } else {
              this.setState({
                agentEmail: data.email,
                agentName: data.name,
                agentProfilePic: data.profile_img,
              })
            }
          })
        }
        this.setState({
          agentStatus: data.online_status
        })
      }
    })
  }

  hideEditProfile = (status) => {
    try {
      document.getElementById("shadowMasking").style.display = "none";
    } catch (err) { }

    this.setState({
      showEditProfile: false
    })
  }


  hideProfileScreen = () => {
    try {
      document.getElementById("shadowMasking").style.display = "none";
    } catch (err) { }
    this.setState({
      profile_display: false,
      showEditProfile: false
    })
  }


  mobileAddC = () => {
    var mobMenuD = document.getElementById("respMobiMenu");
    mobMenuD.classList.add("mobileAddCNew");
    //Responsive Main site
    var respMain = document.getElementById("maintoproutediv");
    respMain.classList.add("responMaineDes");
  }

  mobileRemovC = () => {
    var mobMenuD = document.getElementById("respMobiMenu");
    mobMenuD.classList.remove("mobileAddCNew");
    //Responsive Main site
    var respMain = document.getElementById("maintoproutediv");
    respMain.classList.remove("responMaineDes");
  }

  /**
   * for logout common login
   * api call unset session data for all the product
   * added by Ruchi Kapil
   * */
  logoutAppypieProduct = async () => {
    this.setState({
      loader: true
    })

    try {
      deleteFilters();
    } catch (errrr) { }

    await ownerLogoutCommon();
    // var dataReturn = await logoutCommonLogin(localStorage.getItem('useragent_id'));
  }

  /**
  *  For hide show logout option 
  *  In case of Owner
  * */
  showHideDiv = () => {
    var loginStatus = true;
    if (this.state.logout_status === true) {
      loginStatus = false;
    }
    this.setState({
      logout_status: loginStatus
    });

  }

  /* For logout confirmation start */

  confirmLogout = () => this.setState({
    modalVisible: true
  });
  confirmAllLogout = () => {
   
    //make display none of all divs with id Free-user-plan-banner
    var divs = document.querySelectorAll('#Free-user-plan-banner');
    for (var i = 0; i < divs.length; i++) {
      divs[i].style.display = 'none';
    }

    this.setState({
    logoutModel: true
  });
}


  closeModal = () =>{ 
  //make display block of all divs with id Free-user-plan-banner
  var divs = document.querySelectorAll('#Free-user-plan-banner');
  for (var i = 0; i < divs.length; i++) {
    divs[i].style.display = 'block';
  }
    this.setState({
    modalVisible: false,
    logoutModel: false
  });}

  logOutMe = async () => {

    try {
      deleteFilters();
    } catch (errrr) { }

    if (this.agent.roleType === 'Owner') {
      localStorage.setItem('logoutowner', true);
    }
    window.location.href = "/" + this.agent.ownerIdentifire + "/logout";
    return false;
  }

  /* Dialer Functions Start  */

  handleBodyClick = (event) => {
    if (this.state.showDialer) {
      try {
        var clsNames = event.target.className ? event.target.className : "default";
        if (clsNames.indexOf("donthidebyclkonbdy") === -1) {
          this.hideDialer();
        }
      } catch (errr) { }
    }
  }

  handleKeyDown = (event) => {
    const keyCodes = [8, 86, 91, 107];
    if (event.shiftKey && event.which === 187) {
      // Do nothing
    } else if (keyCodes.indexOf(event.which) === -1 && (event.which <= 47 || event.which >= 58)) {
      event.preventDefault();
    }
  }

  handTextChange = (event) => {
    let txtValue = document.getElementById("phone_number").value;
    txtValue = txtValue.replace(/([a-zA-Z%,&@ ])/g, "");
    txtValue = txtValue.replace(/[`~!@$%^&()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    document.getElementById("phone_number").value = txtValue;
  }

  showDialer = () => {
    let newStatus = !this.state.showDialer ? true : false;
    /*if (newStatus) {
      document.addEventListener("keydown", this.handleKeyDown, true);
    } else {
      document.removeEventListener("keydown", this.handleKeyDown, true);
    }*/
    this.setState({
      showDialer: newStatus
    });
  }

  hideDialer = () => {
    //document.removeEventListener("keydown", this.handleKeyDown, true);
    try {
      document.getElementById("shadowMasking").style.display = "none";
    } catch (err) { }
    this.setState({
      showDialer: false
    });
  }

  isAValidPhoneNumber = (number) => {
    return /^[\d\+\-\(\) ]+$/.test(number);
  }

  dialNumber = (val, event) => {
    var prevValue = document.getElementById("phone_number").value;
    if (event.target.classList.contains('forDetectPlus') && (val === 0 || val === "0")) {
      return false;
    }
    if (val === "delete") {
      prevValue = prevValue.slice(0, -1);
      document.getElementById("phone_number").value = prevValue;
    } else if (val === "clear") {
      document.getElementById("phone_number").value = "";
    } else if (val === "call") {
      var phonenumber = prevValue.trim();
      var isValid = this.isAValidPhoneNumber(phonenumber);
      if (phonenumber && isValid && this.agent.phone_number) {
        startCall(phonenumber, this.agent, 'sidebar');
      }
    } else if (val === "hangup") {
      try {
        document.getElementById("phone_number").value = "";
      } catch (err) { }
      endCall();
    } else if (val === "send_sms") {
      let message = document.getElementById("txt_Sms").value;
      let phoneNum = document.getElementById("phone_number").value;
      phoneNum = phoneNum.trim();
      let isValidNo = this.isAValidPhoneNumber(phoneNum);
      if (!isValidNo || !message || !this.agent.phone_number) {
        console.log("Error in data!")
        return false;
      }
      let msgData = { message: message, phoneNumber: phoneNum };
      sendOrCreateVisitorSMS(this.agent, msgData, 'outside');
    } else {
      document.getElementById("phone_number").value = prevValue + val;
      dialExtension(val);
    }
    return false;
  }

  showHideSmsArea = () => {
    var showSmsArea = this.state.showSmsArea ? false : true;
    if (showSmsArea) {
      document.getElementById('btnsendsms').style.display = 'inline';
      document.getElementById('btncall').style.display = 'none';
    } else {
      document.getElementById('btnsendsms').style.display = 'none';
      document.getElementById('btncall').style.display = 'inline';
    }
    this.setState({ showSmsArea: showSmsArea });
  }

  showHidePopupContent = () => {
    let isHideOrShow = document.getElementById('incpopupcontent').style.display;
    if (isHideOrShow === "block") {
      document.getElementById('incpopupcontent').style.display = "none";
      document.getElementById('showHidePopupContent').style.display = "none";
      document.getElementById('hideHidePopupContent').style.display = "block";
    } else {
      document.getElementById('incpopupcontent').style.display = "block";
      document.getElementById('hideHidePopupContent').style.display = "none";
      document.getElementById('showHidePopupContent').style.display = "block";
    }
  }

  /* Dialer Functions End  */

  replaceImageWithText(e) {
    e.target.onError = null;
    e.target.outerHTML = (this.agent.agent_name.trim().split(" ")[0][0] + (this.agent.agent_name.trim().split(" ")[1] ? this.agent.agent_name.trim().split(" ")[1][0] : this.agent.agent_name.trim().split(" ")[0][1])).toUpperCase();
  }

  render() {
    let userManagement = '';
    let chatHistory = '';
    let settings = '';
    let updateStatus = '';
    let logoutItem = '';
    /* added by ruchi */
    if (this.state.role === 'Owner') {
      var appAccess = localStorage.getItem('owner_app_access') ? JSON.parse(localStorage.getItem('owner_app_access')) : [];

      if (appAccess.length > 1) {
        logoutItem = <span className="logout-mob" onClick={this.confirmAllLogout} style={{ cursor: "pointer" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 120.826 132.304">
            <g id="turn-off_1_" data-name="turn-off (1)" transform="translate(-21.322 0.095)">
              <g id="Group_2743" data-name="Group 2743" transform="translate(21.322 -0.095)">
                <g id="Group_2742" data-name="Group 2742" transform="translate(0)">
                  <g id="Group_2741" data-name="Group 2741">
                    <path id="Path_2232" data-name="Path 2232" d="M105.926,61.053a5.517,5.517,0,1,0-4.413,10.062,49.413,49.413,0,1,1-39.527,0,5.517,5.517,0,1,0-4.413-10.062,60.407,60.407,0,1,0,48.326,0Z" transform="translate(-21.322 -44.52)" fill="#BEBEBE" />
                    <path id="Path_2233" data-name="Path 2233" d="M240.147,60.83a5.517,5.517,0,0,0,5.517-5.517V5.945a5.517,5.517,0,1,0-10.984,0V55.335A5.517,5.517,0,0,0,240.147,60.83Z" transform="translate(-179.7 0.095)" fill="#BEBEBE" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span>Logout</span>
        </span>;
      } else {
        logoutItem = <span className="logout-mob" onClick={this.confirmAllLogout} style={{ cursor: "pointer" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 120.826 132.304">
            <g id="turn-off_1_" data-name="turn-off (1)" transform="translate(-21.322 0.095)">
              <g id="Group_2743" data-name="Group 2743" transform="translate(21.322 -0.095)">
                <g id="Group_2742" data-name="Group 2742" transform="translate(0)">
                  <g id="Group_2741" data-name="Group 2741">
                    <path id="Path_2232" data-name="Path 2232" d="M105.926,61.053a5.517,5.517,0,1,0-4.413,10.062,49.413,49.413,0,1,1-39.527,0,5.517,5.517,0,1,0-4.413-10.062,60.407,60.407,0,1,0,48.326,0Z" transform="translate(-21.322 -44.52)" fill="#BEBEBE" />
                    <path id="Path_2233" data-name="Path 2233" d="M240.147,60.83a5.517,5.517,0,0,0,5.517-5.517V5.945a5.517,5.517,0,1,0-10.984,0V55.335A5.517,5.517,0,0,0,240.147,60.83Z" transform="translate(-179.7 0.095)" fill="#BEBEBE" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span>Logout</span>
        </span>;
      }
    }
    else {
      logoutItem = <span style={{ cursor: "pointer" }} onClick={this.confirmLogout} className="logout-mob" >
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 120.826 132.304">
          <g id="turn-off_1_" data-name="turn-off (1)" transform="translate(-21.322 0.095)">
            <g id="Group_2743" data-name="Group 2743" transform="translate(21.322 -0.095)">
              <g id="Group_2742" data-name="Group 2742" transform="translate(0)">
                <g id="Group_2741" data-name="Group 2741">
                  <path id="Path_2232" data-name="Path 2232" d="M105.926,61.053a5.517,5.517,0,1,0-4.413,10.062,49.413,49.413,0,1,1-39.527,0,5.517,5.517,0,1,0-4.413-10.062,60.407,60.407,0,1,0,48.326,0Z" transform="translate(-21.322 -44.52)" fill="#BEBEBE" />
                  <path id="Path_2233" data-name="Path 2233" d="M240.147,60.83a5.517,5.517,0,0,0,5.517-5.517V5.945a5.517,5.517,0,1,0-10.984,0V55.335A5.517,5.517,0,0,0,240.147,60.83Z" transform="translate(-179.7 0.095)" fill="#BEBEBE" />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span>Logout</span>
      </span>;
    }
    /* For logout confirmation end */


    if (this.state.roles.indexOf('Users Management') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      let activeClass = 'nav-link position-relative p-0 py-xl-4';
      if (window.location.pathname.split('/')[2] === 'roles') {
        activeClass = activeClass + " activeNavigation";
      }
      let allUrls = ["/" + this.agent.ownerIdentifire + "/agents", "/" + this.agent.ownerIdentifire + "/departments", "/" + this.agent.ownerIdentifire + "/roles", "/" + this.agent.ownerIdentifire + "/add-roles"];
      if (allUrls.indexOf(window.location.pathname) >= 0) {
        activeClass = activeClass + " activeNavigation";
      }
      userManagement = <li className="nav-item ">
        {/* <a className={activeClass} title="Agents" role="tab" href={"/" + this.agent.ownerIdentifire + "/" + this.state.userManagementPage}> */}
        <Link className={activeClass} to={"/" + this.agent.ownerIdentifire + "/" + this.state.userManagementPage}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.734" height="25.246" viewBox="0 0 29.734 25.246">
              <g id="Group_2627" data-name="Group 2627" transform="translate(0.5 0.553)">
                <path id="Path_1747" data-name="Path 1747" d="M17.338,10.5a5.221,5.221,0,0,1,2.43,3.879,4.212,4.212,0,1,0-2.43-3.879Zm-2.761,8.628a4.214,4.214,0,1,0-4.215-4.214,4.214,4.214,0,0,0,4.215,4.214Zm1.787.287H12.79a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.068.3.094a24.559,24.559,0,0,0,7.336,1.183,15.06,15.06,0,0,0,6.4-1.2l.281-.142h.03V24.809a5.4,5.4,0,0,0-5.388-5.4Zm6.972-4.35H19.789a5.193,5.193,0,0,1-1.6,3.617,6.411,6.411,0,0,1,4.579,6.135v1.347a14.519,14.519,0,0,0,5.655-1.188l.281-.143h.03V20.458a5.4,5.4,0,0,0-5.4-5.4ZM7.184,14.776a4.187,4.187,0,0,0,2.242-.65A5.215,5.215,0,0,1,11.385,10.8c0-.079.012-.157.012-.237a4.213,4.213,0,1,0-4.213,4.213Zm3.784,3.9a5.2,5.2,0,0,1-1.6-3.6c-.132-.01-.262-.02-.4-.02H5.4a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.067.3.1A25.3,25.3,0,0,0,6.387,26.14V24.815a6.412,6.412,0,0,1,4.581-6.135Z" transform="translate(0 -6.348)" fill="none" stroke="#fff" strokeWidth="1" />
              </g>
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.734" height="24.191" viewBox="0 0 28.734 24.191">
              <path id="Path_1747" data-name="Path 1747" d="M17.338,10.5a5.221,5.221,0,0,1,2.43,3.879,4.212,4.212,0,1,0-2.43-3.879Zm-2.761,8.628a4.214,4.214,0,1,0-4.215-4.214,4.214,4.214,0,0,0,4.215,4.214Zm1.787.287H12.79a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.068.3.094a24.559,24.559,0,0,0,7.336,1.183,15.06,15.06,0,0,0,6.4-1.2l.281-.142h.03V24.809a5.4,5.4,0,0,0-5.388-5.4Zm6.972-4.35H19.789a5.193,5.193,0,0,1-1.6,3.617,6.411,6.411,0,0,1,4.579,6.135v1.347a14.519,14.519,0,0,0,5.655-1.188l.281-.143h.03V20.458a5.4,5.4,0,0,0-5.4-5.4ZM7.184,14.776a4.187,4.187,0,0,0,2.242-.65A5.215,5.215,0,0,1,11.385,10.8c0-.079.012-.157.012-.237a4.213,4.213,0,1,0-4.213,4.213Zm3.784,3.9a5.2,5.2,0,0,1-1.6-3.6c-.132-.01-.262-.02-.4-.02H5.4a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.067.3.1A25.3,25.3,0,0,0,6.387,26.14V24.815a6.412,6.412,0,0,1,4.581-6.135Z" transform="translate(0 -6.35)" fill="#fff" />
            </svg>
          </span>
          <span className="sidemenutext">Users</span>
        </Link>
        {/* </a> */}
      </li>
    }
    if ((this.state.OtherRoles.indexOf('Conversation history') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner')) {
      chatHistory = <li className="nav-item ">

        <Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/chathistory" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} to={"/" + this.agent.ownerIdentifire + "/chathistory"}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.725" height="28.719" viewBox="0 0 28.725 28.719">
              <g id="clock" transform="translate(-0.007 -0.01)">
                <path id="Path_2196" data-name="Path 2196" d="M25.185,14.426h-.2A12.558,12.558,0,1,0,14.426,24.987v2.9a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.546-3.546v-5.41A3.551,3.551,0,0,0,25.185,14.426ZM12.563,23.442a10.872,10.872,0,1,1,10.719-9.016h-5.31a3.545,3.545,0,0,0-3.546,3.546v5.31a10.966,10.966,0,0,1-1.863.16Zm14.485-.06a1.865,1.865,0,0,1-1.863,1.863H17.972a.841.841,0,0,0-.467.141l-1.4.931V17.972a1.862,1.862,0,0,1,1.863-1.863h7.213a1.865,1.865,0,0,1,1.863,1.863Zm0,0" fill="#fff" />
                <path id="Path_2197" data-name="Path 2197" d="M327.583,321.332h-5.41a.842.842,0,0,0,0,1.684h5.41a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -303.3)" fill="#fff" />
                <path id="Path_2198" data-name="Path 2198" d="M324.879,385.6h-2.7a.842.842,0,1,0,0,1.684h2.7a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -363.963)" fill="#fff" />
                <path id="Path_2199" data-name="Path 2199" d="M118.72,64.266a.842.842,0,0,0-.842.842V72.38H113.31a.842.842,0,1,0,0,1.683h5.41a.842.842,0,0,0,.842-.842V65.107a.842.842,0,0,0-.842-.841Zm0,0" transform="translate(-106.157 -60.659)" fill="#fff" />
              </g>
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.736" height="28.732" viewBox="0 0 28.736 28.732">
              <g id="clock_1_" data-name="clock (1)" transform="translate(0.003 0.002)">
                <path id="Path_2204" data-name="Path 2204" d="M25.121,12.742v-.18A12.562,12.562,0,1,0,12.56,25.123h.182V17.972a5.236,5.236,0,0,1,5.23-5.23ZM13.4,12.563a.842.842,0,0,1-.842.842h-5.4a.842.842,0,1,1,0-1.683h4.568V4.448a.842.842,0,1,1,1.683,0Zm0,0" fill="#fff" />
                <path id="Path_2205" data-name="Path 2205" d="M267.826,257.066h-7.213a3.544,3.544,0,0,0-3.546,3.546v9.918a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.547-3.547v-5.41a3.551,3.551,0,0,0-3.548-3.545Zm-4.448,8.9h-1.863a.842.842,0,0,1,0-1.684h1.863a.842.842,0,1,1,0,1.684Zm3.547-3.607h-5.41a.842.842,0,0,1,0-1.683h5.41a.842.842,0,1,1,0,1.683Zm0,0" transform="translate(-242.641 -242.641)" fill="#fff" />
              </g>
            </svg>
          </span>
          <span className="sidemenutext">Archives</span>
        </Link>
        {/* 
        <a className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/chathistory" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Conversation history" role="tab" href={"/" + this.agent.ownerIdentifire + "/chathistory"}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.725" height="28.719" viewBox="0 0 28.725 28.719">
              <g id="clock" transform="translate(-0.007 -0.01)">
                <path id="Path_2196" data-name="Path 2196" d="M25.185,14.426h-.2A12.558,12.558,0,1,0,14.426,24.987v2.9a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.546-3.546v-5.41A3.551,3.551,0,0,0,25.185,14.426ZM12.563,23.442a10.872,10.872,0,1,1,10.719-9.016h-5.31a3.545,3.545,0,0,0-3.546,3.546v5.31a10.966,10.966,0,0,1-1.863.16Zm14.485-.06a1.865,1.865,0,0,1-1.863,1.863H17.972a.841.841,0,0,0-.467.141l-1.4.931V17.972a1.862,1.862,0,0,1,1.863-1.863h7.213a1.865,1.865,0,0,1,1.863,1.863Zm0,0" fill="#fff" />
                <path id="Path_2197" data-name="Path 2197" d="M327.583,321.332h-5.41a.842.842,0,0,0,0,1.684h5.41a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -303.3)" fill="#fff" />
                <path id="Path_2198" data-name="Path 2198" d="M324.879,385.6h-2.7a.842.842,0,1,0,0,1.684h2.7a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -363.963)" fill="#fff" />
                <path id="Path_2199" data-name="Path 2199" d="M118.72,64.266a.842.842,0,0,0-.842.842V72.38H113.31a.842.842,0,1,0,0,1.683h5.41a.842.842,0,0,0,.842-.842V65.107a.842.842,0,0,0-.842-.841Zm0,0" transform="translate(-106.157 -60.659)" fill="#fff" />
              </g>
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.736" height="28.732" viewBox="0 0 28.736 28.732">
              <g id="clock_1_" data-name="clock (1)" transform="translate(0.003 0.002)">
                <path id="Path_2204" data-name="Path 2204" d="M25.121,12.742v-.18A12.562,12.562,0,1,0,12.56,25.123h.182V17.972a5.236,5.236,0,0,1,5.23-5.23ZM13.4,12.563a.842.842,0,0,1-.842.842h-5.4a.842.842,0,1,1,0-1.683h4.568V4.448a.842.842,0,1,1,1.683,0Zm0,0" fill="#fff" />
                <path id="Path_2205" data-name="Path 2205" d="M267.826,257.066h-7.213a3.544,3.544,0,0,0-3.546,3.546v9.918a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.547-3.547v-5.41a3.551,3.551,0,0,0-3.548-3.545Zm-4.448,8.9h-1.863a.842.842,0,0,1,0-1.684h1.863a.842.842,0,1,1,0,1.684Zm3.547-3.607h-5.41a.842.842,0,0,1,0-1.683h5.41a.842.842,0,1,1,0,1.683Zm0,0" transform="translate(-242.641 -242.641)" fill="#fff" />
              </g>
            </svg>
          </span>
        </a>*/}
      </li>
    }

    if (this.state.OtherRoles.indexOf('Settings') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      let activeClass = 'nav-link position-relative p-0 py-xl-4';
      let allUrls = ["/" + this.agent.ownerIdentifire + "/idle-time", "/" + this.agent.ownerIdentifire + "/setup", "/" + this.agent.ownerIdentifire + "/chatsettingform", "/" + this.agent.ownerIdentifire + "/widgetsetting", "/" + this.agent.ownerIdentifire + "/gdpr-consent", "/" + this.agent.ownerIdentifire + "/notifications", "/" + 
      // this.agent.ownerIdentifire + "/previous-chat", "/" + 
      this.agent.ownerIdentifire + "/subscriptions", "/" + this.agent.ownerIdentifire + "/my-notifications"];
      if (allUrls.indexOf(window.location.pathname) >= 0) {
        activeClass = activeClass + " activeNavigation";
      }
      settings = <li className="nav-item ">
        <Link className={activeClass} to={"/" + this.agent.ownerIdentifire + "/chatsettingform"} onClick={this.mobileRemovC}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29.002" viewBox="0 0 29.002 29.002">
              <path id="Union_1" data-name="Union 1" d="M12.161,29a.468.468,0,0,1-.467-.468v-2.2A12.045,12.045,0,0,1,8.117,24.85L6.562,26.407a.481.481,0,0,1-.661,0L2.593,23.1a.468.468,0,0,1,0-.662L4.15,20.883a12.049,12.049,0,0,1-1.484-3.577H.468A.468.468,0,0,1,0,16.839V12.161a.468.468,0,0,1,.468-.467h2.2a12.045,12.045,0,0,1,1.48-3.577L2.593,6.562a.467.467,0,0,1,0-.661L5.9,2.593a.467.467,0,0,1,.661,0L8.118,4.15a12.016,12.016,0,0,1,3.576-1.484V.468A.468.468,0,0,1,12.161,0h4.678a.468.468,0,0,1,.467.468v2.2A12.029,12.029,0,0,1,20.882,4.15L22.437,2.6a.468.468,0,0,1,.662,0L26.4,5.9a.467.467,0,0,1,0,.661L24.85,8.117a12.049,12.049,0,0,1,1.484,3.577h2.2a.468.468,0,0,1,.468.467v4.678a.468.468,0,0,1-.468.467h-2.2a12.046,12.046,0,0,1-1.482,3.575l1.555,1.555a.467.467,0,0,1,0,.661L23.1,26.4a.468.468,0,0,1-.662,0l-1.555-1.55a12.049,12.049,0,0,1-3.577,1.484v2.2a.468.468,0,0,1-.467.468ZM8.312,23.864A11.108,11.108,0,0,0,12.255,25.5a.468.468,0,0,1,.374.459v2.1h3.742v-2.1a.468.468,0,0,1,.374-.459,11.108,11.108,0,0,0,3.943-1.637.468.468,0,0,1,.59.059l1.491,1.491,2.646-2.646-1.491-1.491a.468.468,0,0,1-.059-.59A11.108,11.108,0,0,0,25.5,16.745a.468.468,0,0,1,.459-.374h2.1V12.629h-2.1a.468.468,0,0,1-.459-.374,11.108,11.108,0,0,0-1.637-3.943.468.468,0,0,1,.059-.59l1.491-1.491L22.768,3.586,21.277,5.077a.469.469,0,0,1-.59.059A11.106,11.106,0,0,0,16.745,3.5a.468.468,0,0,1-.374-.459V.935H12.629v2.1a.468.468,0,0,1-.375.458A11.106,11.106,0,0,0,8.312,5.135a.468.468,0,0,1-.59-.059L6.231,3.585,3.585,6.231,5.077,7.722a.469.469,0,0,1,.059.59A11.108,11.108,0,0,0,3.5,12.255a.468.468,0,0,1-.459.374H.935v3.742h2.1a.468.468,0,0,1,.458.375A11.108,11.108,0,0,0,5.13,20.689a.468.468,0,0,1-.059.589L3.585,22.769l2.646,2.645,1.491-1.491A.467.467,0,0,1,8.312,23.864ZM7.966,14.5A6.534,6.534,0,1,1,14.5,21.034,6.534,6.534,0,0,1,7.966,14.5Zm1.188,0A5.346,5.346,0,1,0,14.5,9.154,5.346,5.346,0,0,0,9.154,14.5Z" fill="#fff" />
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29" viewBox="0 0 29.002 29">
              <path id="Union_2" data-name="Union 2" d="M16.838,29H12.162a.469.469,0,0,1-.468-.468v-2.2A12.029,12.029,0,0,1,8.118,24.85L6.563,26.408a.482.482,0,0,1-.662,0L2.593,23.1a.469.469,0,0,1,0-.662L4.15,20.883a12.076,12.076,0,0,1-1.484-3.577H.468A.469.469,0,0,1,0,16.838V12.163a.469.469,0,0,1,.468-.468h2.2a11.987,11.987,0,0,1,1.48-3.577L2.593,6.563a.466.466,0,0,1-.137-.333.46.46,0,0,1,.137-.33L5.9,2.593a.467.467,0,0,1,.662,0L8.118,4.15a12.048,12.048,0,0,1,3.576-1.484V.468A.469.469,0,0,1,12.162,0h4.676a.469.469,0,0,1,.468.468v2.2A12,12,0,0,1,20.883,4.15L22.438,2.6a.467.467,0,0,1,.662,0l3.3,3.3a.46.46,0,0,1,.137.329.466.466,0,0,1-.137.333L24.85,8.118a12.022,12.022,0,0,1,1.484,3.577h2.2a.469.469,0,0,1,.468.468v4.676a.469.469,0,0,1-.468.468h-2.2a12.027,12.027,0,0,1-1.482,3.574l1.555,1.555a.469.469,0,0,1,0,.662L23.1,26.406a.467.467,0,0,1-.662,0l-1.555-1.555a12.064,12.064,0,0,1-3.577,1.484v2.2a.469.469,0,0,1-.468.465ZM14.5,7.965A6.534,6.534,0,1,0,21.033,14.5,6.534,6.534,0,0,0,14.5,7.965Z" fill="#fff" />
            </svg>
          </span>
          <span className="sidemenutext">Settings</span>
        </Link>
      </li>
    } else if (this.state.role === 'Agent') {
      let activeClass = 'nav-link position-relative p-0 py-xl-4';
      let allUrls = ["/" + this.agent.ownerIdentifire + "/my-notifications"];
      if (allUrls.indexOf(window.location.pathname) >= 0) {
        activeClass = activeClass + " activeNavigation";
      }
      settings = <li className="nav-item ">
        <Link className={activeClass} to={"/" + this.agent.ownerIdentifire + "/my-notifications"}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29.002" viewBox="0 0 29.002 29.002">
              <path id="Union_1" data-name="Union 1" d="M12.161,29a.468.468,0,0,1-.467-.468v-2.2A12.045,12.045,0,0,1,8.117,24.85L6.562,26.407a.481.481,0,0,1-.661,0L2.593,23.1a.468.468,0,0,1,0-.662L4.15,20.883a12.049,12.049,0,0,1-1.484-3.577H.468A.468.468,0,0,1,0,16.839V12.161a.468.468,0,0,1,.468-.467h2.2a12.045,12.045,0,0,1,1.48-3.577L2.593,6.562a.467.467,0,0,1,0-.661L5.9,2.593a.467.467,0,0,1,.661,0L8.118,4.15a12.016,12.016,0,0,1,3.576-1.484V.468A.468.468,0,0,1,12.161,0h4.678a.468.468,0,0,1,.467.468v2.2A12.029,12.029,0,0,1,20.882,4.15L22.437,2.6a.468.468,0,0,1,.662,0L26.4,5.9a.467.467,0,0,1,0,.661L24.85,8.117a12.049,12.049,0,0,1,1.484,3.577h2.2a.468.468,0,0,1,.468.467v4.678a.468.468,0,0,1-.468.467h-2.2a12.046,12.046,0,0,1-1.482,3.575l1.555,1.555a.467.467,0,0,1,0,.661L23.1,26.4a.468.468,0,0,1-.662,0l-1.555-1.55a12.049,12.049,0,0,1-3.577,1.484v2.2a.468.468,0,0,1-.467.468ZM8.312,23.864A11.108,11.108,0,0,0,12.255,25.5a.468.468,0,0,1,.374.459v2.1h3.742v-2.1a.468.468,0,0,1,.374-.459,11.108,11.108,0,0,0,3.943-1.637.468.468,0,0,1,.59.059l1.491,1.491,2.646-2.646-1.491-1.491a.468.468,0,0,1-.059-.59A11.108,11.108,0,0,0,25.5,16.745a.468.468,0,0,1,.459-.374h2.1V12.629h-2.1a.468.468,0,0,1-.459-.374,11.108,11.108,0,0,0-1.637-3.943.468.468,0,0,1,.059-.59l1.491-1.491L22.768,3.586,21.277,5.077a.469.469,0,0,1-.59.059A11.106,11.106,0,0,0,16.745,3.5a.468.468,0,0,1-.374-.459V.935H12.629v2.1a.468.468,0,0,1-.375.458A11.106,11.106,0,0,0,8.312,5.135a.468.468,0,0,1-.59-.059L6.231,3.585,3.585,6.231,5.077,7.722a.469.469,0,0,1,.059.59A11.108,11.108,0,0,0,3.5,12.255a.468.468,0,0,1-.459.374H.935v3.742h2.1a.468.468,0,0,1,.458.375A11.108,11.108,0,0,0,5.13,20.689a.468.468,0,0,1-.059.589L3.585,22.769l2.646,2.645,1.491-1.491A.467.467,0,0,1,8.312,23.864ZM7.966,14.5A6.534,6.534,0,1,1,14.5,21.034,6.534,6.534,0,0,1,7.966,14.5Zm1.188,0A5.346,5.346,0,1,0,14.5,9.154,5.346,5.346,0,0,0,9.154,14.5Z" fill="#fff" />
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29" viewBox="0 0 29.002 29">
              <path id="Union_2" data-name="Union 2" d="M16.838,29H12.162a.469.469,0,0,1-.468-.468v-2.2A12.029,12.029,0,0,1,8.118,24.85L6.563,26.408a.482.482,0,0,1-.662,0L2.593,23.1a.469.469,0,0,1,0-.662L4.15,20.883a12.076,12.076,0,0,1-1.484-3.577H.468A.469.469,0,0,1,0,16.838V12.163a.469.469,0,0,1,.468-.468h2.2a11.987,11.987,0,0,1,1.48-3.577L2.593,6.563a.466.466,0,0,1-.137-.333.46.46,0,0,1,.137-.33L5.9,2.593a.467.467,0,0,1,.662,0L8.118,4.15a12.048,12.048,0,0,1,3.576-1.484V.468A.469.469,0,0,1,12.162,0h4.676a.469.469,0,0,1,.468.468v2.2A12,12,0,0,1,20.883,4.15L22.438,2.6a.467.467,0,0,1,.662,0l3.3,3.3a.46.46,0,0,1,.137.329.466.466,0,0,1-.137.333L24.85,8.118a12.022,12.022,0,0,1,1.484,3.577h2.2a.469.469,0,0,1,.468.468v4.676a.469.469,0,0,1-.468.468h-2.2a12.027,12.027,0,0,1-1.482,3.574l1.555,1.555a.469.469,0,0,1,0,.662L23.1,26.406a.467.467,0,0,1-.662,0l-1.555-1.555a12.064,12.064,0,0,1-3.577,1.484v2.2a.469.469,0,0,1-.468.465ZM14.5,7.965A6.534,6.534,0,1,0,21.033,14.5,6.534,6.534,0,0,0,14.5,7.965Z" fill="#fff" />
            </svg>
          </span>
          <span className="sidemenutext">Settings</span>
        </Link>
      </li>
    }

    if (this.state.OtherRoles.indexOf('Change Status') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      updateStatus = <div className="profile-status k-flex align-items-center">

        <div className="material-select- matLabOnlStatus w-100">
          <FormControl variant="outlined" className="material-outlined- w-100">
            <InputLabel id="agentStatus-outlined-label" className="">Status</InputLabel>
            <Select className="position-relative" MenuProps={{ disableScrollLock: true }} labelId="agentStatus-outlined-label" id="agentStatus" name="agentStatus" value={this.state.agentStatus ? this.state.agentStatus : ""} onChange={this.updateStatus} label="Online Status">
              <MenuItem value="1"><span className="online"></span>Online</MenuItem>
              <MenuItem value="2"><span className="offline"></span>Offline</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* <div className="wrapperInfo ml-auto" style={{ 'width': '150px' }}>
          <select name="agentStatus" className="customArrow" value={this.state.agentStatus} onChange={this.updateStatus}>
            <option value="1">Online</option>
            <option value="2">Offline</option>
          </select>
        </div> */}
      </div>
    }

    let onlineImg = '';
    if (this.state.agentStatus === 1) {
      onlineImg = 'online.png';
    } else {
      onlineImg = 'offline.png';
    }

    let layoutClass = 'navigation navbar navbar-light justify-content-center py-7 navigation-not-liveChat ';
    let isLiveChatScreen = false;
    var organization_alias = this.agent.ownerIdentifire ? this.agent.ownerIdentifire : this.agent.organization_alias;
    organization_alias = organization_alias ? organization_alias.trim() : "";

    if (window.location.pathname === '/' + organization_alias) {
      isLiveChatScreen = true;
      layoutClass = 'navigation navbar navbar-light justify-content-center py-xl-7 navigation-visible-overflow';
    }

    let profile_img = '';
    if (this.state.agentProfilePic !== '' && this.state.agentProfilePic !== undefined) {
      profile_img = <img src={this.state.agentProfilePic} className="avatar-img" alt="" onError={(e) => this.replaceImageWithText(e)} />;
    } else {
      let names = this.state.agentName.trim().split(" ");
      if (names.length > 1) {
        profile_img = (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase();
      } else {
        profile_img = (names[0].substring(0, 2)).toUpperCase();
      }
    }
    return (
      <>
        {!isLiveChatScreen ? (<Incomingnotification />) : (null)}
        <ToastContainer autoClose={5000} />
        {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
        <div className={layoutClass} id="respMobiMenu">
          <span className="left-menu-title-logo mb-6 cursorPointer"><img alt="" src={require('../../assets/images/avatars/LC.svg')} /></span>
          <ul className="nav navbar-nav flex-xl-column flex-grow-1 justify-content-center py-3 py-lg-0" role="tablist">
            <li className="nav-item">
              {/* <a className={window.location.pathname === '/' + this.agent.ownerIdentifire ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" href={"/" + this.agent.ownerIdentifire}> */}
              <Link className={window.location.pathname === '/' + this.agent.ownerIdentifire ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} to={"/" + this.agent.ownerIdentifire}>
                <span className="before-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.732" height="28.738" viewBox="0 0 28.732 28.738">
                    <g id="Group_2625" data-name="Group 2625" transform="translate(0.004 -0.006)">
                      <path id="Path_513" data-name="Path 513" d="M24.642,4.213A14.369,14.369,0,0,0,3.371,23.485,5.393,5.393,0,0,1,1.108,26.1a1.341,1.341,0,0,0,.385,2.528,6.838,6.838,0,0,0,1.017.078h0a9.293,9.293,0,0,0,5.164-1.673A14.373,14.373,0,0,0,24.642,4.213Zm-1.15,19.17A12.75,12.75,0,0,1,8.03,25.363a.809.809,0,0,0-.909.054,7.739,7.739,0,0,1-4.508,1.655,8.179,8.179,0,0,0,2.462-3.4.818.818,0,0,0-.138-.867,12.744,12.744,0,1,1,18.556.578Z" transform="translate(-0.117)" fill="#fff" />
                      <path id="Path_514" data-name="Path 514" d="M138.133,160H126.1a.813.813,0,0,0,0,1.625h12.038a.813.813,0,0,0,0-1.625Z" transform="translate(-117.75 -150.37)" fill="#fff" />
                      <path id="Path_515" data-name="Path 515" d="M138.133,225.3H126.1a.813.813,0,0,0,0,1.625h12.038a.813.813,0,0,0,0-1.625Z" transform="translate(-117.75 -211.74)" fill="#fff" />
                      <path id="Path_516" data-name="Path 516" d="M138.133,290.6H126.1a.813.813,0,0,0,0,1.625h12.038a.813.813,0,1,0,0-1.625Z" transform="translate(-117.75 -273.109)" fill="#fff" />
                    </g>
                  </svg>
                </span>
                <span className="after-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.738" height="28.75" viewBox="0 0 28.738 28.75">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M14.365,28.746a14.422,14.422,0,0,1-6.808-1.71A9.232,9.232,0,0,1,2.393,28.71a6.918,6.918,0,0,1-1.017-.078A1.341,1.341,0,0,1,.991,26.1a5.4,5.4,0,0,0,2.263-2.618,14.364,14.364,0,1,1,11.111,5.264ZM8.346,17.491a.813.813,0,1,0,0,1.625H20.383a.813.813,0,0,0,0-1.625Zm0-3.93a.813.813,0,0,0,0,1.625H20.383a.813.813,0,0,0,0-1.625Zm0-3.93a.813.813,0,1,0,0,1.625H20.383a.813.813,0,0,0,0-1.625Z" transform="translate(0.007 0.004)" fill="#fff" />
                  </svg>
                </span>
                <span className="sidemenutext">Chats</span>
              </Link>
              {/* </a> */}


            </li>

            {/*<li className="nav-item">
            <a className="nav-link position-relative p-0 py-xl-4" data-toggle="tab" href="#tab-content-create-chat"
              title="Create chat" role="tab">
              <span><img alt="" src={require('../../assets/images/left-sidebar/chatbot.svg')} /></span>
            </a>
          </li>

          <li className="nav-item ">
            <a className="nav-link position-relative p-0 py-xl-4" data-toggle="tab" href="#tab-content-friends"
              title="Friends" role="tab">
              <span><img alt="" src={require('../../assets/images/left-sidebar/connect.svg')} /></span>
            </a>
          </li>

          <li className="nav-item ">
            <a className="nav-link position-relative p-0 py-xl-4" data-toggle="tab" href="#tab-content-dialogs"
              title="Chats" role="tab">
              <span><img alt="" src={require('../../assets/images/left-sidebar/analytices.svg')} /></span>
            </a>
    </li> */}

            {userManagement}
            {chatHistory}
            {/* <li className="nav-item ">
            <a className="'nav-link position-relative p-0 py-xl-4'" title="Agents" role="tab" href={"/"+this.agent.ownerIdentifire+"/banned-users"}>
              <span className="before-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="29.734" height="25.246" viewBox="0 0 29.734 25.246">
                  <g id="Group_2627" data-name="Group 2627" transform="translate(0.5 0.553)">
                    <path id="Path_1747" data-name="Path 1747" d="M17.338,10.5a5.221,5.221,0,0,1,2.43,3.879,4.212,4.212,0,1,0-2.43-3.879Zm-2.761,8.628a4.214,4.214,0,1,0-4.215-4.214,4.214,4.214,0,0,0,4.215,4.214Zm1.787.287H12.79a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.068.3.094a24.559,24.559,0,0,0,7.336,1.183,15.06,15.06,0,0,0,6.4-1.2l.281-.142h.03V24.809a5.4,5.4,0,0,0-5.388-5.4Zm6.972-4.35H19.789a5.193,5.193,0,0,1-1.6,3.617,6.411,6.411,0,0,1,4.579,6.135v1.347a14.519,14.519,0,0,0,5.655-1.188l.281-.143h.03V20.458a5.4,5.4,0,0,0-5.4-5.4ZM7.184,14.776a4.187,4.187,0,0,0,2.242-.65A5.215,5.215,0,0,1,11.385,10.8c0-.079.012-.157.012-.237a4.213,4.213,0,1,0-4.213,4.213Zm3.784,3.9a5.2,5.2,0,0,1-1.6-3.6c-.132-.01-.262-.02-.4-.02H5.4a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.067.3.1A25.3,25.3,0,0,0,6.387,26.14V24.815a6.412,6.412,0,0,1,4.581-6.135Z" transform="translate(0 -6.348)" fill="none" stroke="#fff" strokeWidth="1"/>
                  </g>
                </svg>
              </span>
            </a>
          </li> */}
            {/* {(this.state.OtherRoles.indexOf('Banned Users') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') ? (<li className="nav-item ">
            <Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/banned-users" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/banned-users"}>
              {/* <a className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/banned-users" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Ban User" role="tab" href={"/" + this.agent.ownerIdentifire + "/banned-users"}> */}
            {/* <span className="before-icon">
                  <svg id="remove-user" xmlns="http://www.w3.org/2000/svg" width="41.121" height="41.121" viewBox="0 0 41.121 41.121">
                    <g id="Group_5418" data-name="Group 5418">
                      <path id="Path_3346" data-name="Path 3346" d="M35.205,34.986a.689.689,0,0,0,.065-.077A20.518,20.518,0,0,0,36.579,7.687a.685.685,0,0,0-.1-.134l-.016-.014a20.741,20.741,0,0,0-2.877-2.877l-.014-.016a.682.682,0,0,0-.134-.1A20.549,20.549,0,0,0,4.542,33.434a.686.686,0,0,0,.1.134l.016.015a20.737,20.737,0,0,0,2.877,2.877l.015.016a.689.689,0,0,0,.12.1q.666.537,1.372,1.015c.075.051.152.1.228.151l.131.086a20.427,20.427,0,0,0,9.784,3.251l.122.008.307.017.242.009.219.007c.16,0,.321.006.481.006q.265,0,.528-.007h.033A20.4,20.4,0,0,0,35.205,34.986ZM22.031,39.694c-.142.011-.285.02-.428.027l-.069,0c-.131.007-.262.011-.393.015l-.1,0q-.242.006-.484.006c-.15,0-.3,0-.449-.006l-.206-.007-.224-.009-.289-.016-.11-.007A19.25,19.25,0,0,1,9.8,36.44q-.368-.25-.724-.517L28.1,16.9v.921a1.368,1.368,0,0,1-.635,1.149.682.682,0,0,0-.3.422,13.016,13.016,0,0,1-2.338,4.843.692.692,0,0,0-.159.439v2.5a3.18,3.18,0,0,0,1.659,2.793l6.115,3.336a4.754,4.754,0,0,1,1.362,1.124,19.129,19.129,0,0,1-11.761,5.262Zm12.737-6.248A6.121,6.121,0,0,0,33.1,32.1l-6.115-3.335a1.811,1.811,0,0,1-.944-1.591V24.91a14.4,14.4,0,0,0,2.4-4.96,2.727,2.727,0,0,0,1.026-2.131V15.527l6.453-6.453a19.154,19.154,0,0,1-1.155,24.372ZM20.56,1.371A19.09,19.09,0,0,1,32.047,5.2L28.769,8.475a5.964,5.964,0,0,0-1.418-3.315c-1.408-1.606-3.693-2.419-6.791-2.419s-5.383.813-6.79,2.419a6.145,6.145,0,0,0-1.434,4.473v3.645a2.73,2.73,0,0,0-.685,1.8v2.741a2.726,2.726,0,0,0,1.026,2.131A13.959,13.959,0,0,0,14,23.242l-8.8,8.8A19.18,19.18,0,0,1,20.56,1.371ZM15.014,22.23a12.218,12.218,0,0,1-1.062-2.839.686.686,0,0,0-.3-.422,1.368,1.368,0,0,1-.635-1.149V15.078a1.351,1.351,0,0,1,.459-1.01.687.687,0,0,0,.226-.509V9.595a.793.793,0,0,0-.005-.09,4.755,4.755,0,0,1,1.1-3.44c1.136-1.3,3.074-1.953,5.76-1.953s4.61.652,5.747,1.938A4.821,4.821,0,0,1,27.42,9.505a.654.654,0,0,0-.006.09v.236ZM6.063,33.119,28.582,10.6l0,0L33.12,6.063A19.366,19.366,0,0,1,35.058,8L28.3,14.755l-.007.007L8,35.058A19.35,19.35,0,0,1,6.063,33.119Z" fill="#fff" />
                    </g>
                  </svg>

                </span>
                <span className="after-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="41.125" height="41.125" viewBox="0 0 41.125 41.125">
                    <g id="remove-user_1_" data-name="remove-user (1)" transform="translate(0 -0.003)">
                      <g id="Group_5420" data-name="Group 5420" transform="translate(0 0.003)">
                        <g id="Group_5419" data-name="Group 5419">
                          <path id="Path_3347" data-name="Path 3347" d="M94.385,65.387a20.622,20.622,0,0,0-1.938-1.938l-5.5,5.5L73.7,82.2,63.453,92.444a20.7,20.7,0,0,0,1.938,1.938l.522-.522L86.66,73.113Z" transform="translate(-58.356 -58.353)" fill="#fff" />
                          <path id="Path_3348" data-name="Path 3348" d="M335.126,100.839l-7.43,7.429v2.292a2.728,2.728,0,0,1-1.026,2.131,14.4,14.4,0,0,1-2.4,4.961v2.267a1.811,1.811,0,0,0,.944,1.591l6.115,3.335c.312.17,1.442,1.053,2.782,2.148a20.51,20.51,0,0,0,1.016-26.155Z" transform="translate(-298.223 -92.74)" fill="#fff" />
                          <path id="Path_3349" data-name="Path 3349" d="M119.074,223.48a3.18,3.18,0,0,1-1.659-2.794v-2.506a.685.685,0,0,1,.16-.439,13.038,13.038,0,0,0,2.337-4.844.685.685,0,0,1,.3-.422,1.365,1.365,0,0,0,.635-1.149v-.921l-19.576,19.576-.427.427a20.512,20.512,0,0,0,26.838-1.656c-1.232-1.006-2.247-1.805-2.489-1.937Z" transform="translate(-92.74 -193.506)" fill="#fff" />
                          <path id="Path_3350" data-name="Path 3350" d="M162.594,61.159a1.349,1.349,0,0,0-.459,1.01v2.742a1.368,1.368,0,0,0,.635,1.149.685.685,0,0,1,.3.422,12.239,12.239,0,0,0,1.062,2.84l12.4-12.4v-.236a.656.656,0,0,1,.006-.09,4.821,4.821,0,0,0-1.112-3.455c-1.137-1.286-3.071-1.938-5.748-1.938s-4.624.657-5.76,1.952a4.763,4.763,0,0,0-1.1,3.442.655.655,0,0,1,.006.09V60.65A.687.687,0,0,1,162.594,61.159Z" transform="translate(-149.112 -47.091)" fill="#fff" />
                          <path id="Path_3351" data-name="Path 3351" d="M12.678,19.955a2.727,2.727,0,0,1-1.026-2.131V15.082a2.732,2.732,0,0,1,.685-1.8V9.637a6.146,6.146,0,0,1,1.434-4.473c1.408-1.606,3.693-2.419,6.791-2.419s5.384.814,6.792,2.42A5.967,5.967,0,0,1,28.772,8.48l4.254-4.254a20.548,20.548,0,0,0-28.8,28.8L14,23.247A13.939,13.939,0,0,1,12.678,19.955Z" transform="translate(0 -0.003)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </svg>


                </span>
                <span className="sidemenutext">Banned Users</span>
              </Link>
            </li>) : (null)} */}

            {(this.state.role === 'Admin' || this.state.role === 'Owner' || this.state.role === 'Agent' || this.state.OtherRoles.indexOf('Analytics') > -1) ? (<><li className="nav-item ">
              {(this.state.role === 'Admin' || this.state.role === 'Owner') ? (<Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/chatanalytics" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/chatanalytics"}>
                <span className="before-icon">
                  <svg id="Group_1687" data-name="Group 1687" xmlns="http://www.w3.org/2000/svg" width="23.676" height="23.676" viewBox="0 0 23.676 23.676">
                    <path id="Path_1488" data-name="Path 1488" d="M62.184,69.508l3.671-3.671.9.9a.693.693,0,0,0,.981,0L72.1,62.369v1.1a.694.694,0,1,0,1.387,0V60.695A.7.7,0,0,0,72.791,60H70.017a.694.694,0,1,0,0,1.387h1.1l-3.875,3.875-.9-.9a.693.693,0,0,0-.981,0L61.2,68.528a.694.694,0,1,0,.981.981Z" transform="translate(-58.179 -57.226)" fill="#fff" />
                    <path id="Path_1489" data-name="Path 1489" d="M61.694,335.642h4.162a.693.693,0,0,0,.694-.694v-4.254a.693.693,0,0,0-.694-.694H61.694a.693.693,0,0,0-.694.694v4.254A.693.693,0,0,0,61.694,335.642Z" transform="translate(-58.179 -314.741)" fill="#fff" />
                    <path id="Path_1491" data-name="Path 1491" d="M331.692,67.391h.694v10.5a.693.693,0,0,0,.694.694h4.208a.693.693,0,0,0,.694-.694v-10.5h.694a.693.693,0,0,0,.588-1.061l-3.514-5.549a.721.721,0,0,0-1.176,0L331.1,66.33a.693.693,0,0,0,.588,1.061Z" transform="translate(-315.693 -57.681)" fill="#fff" />
                    <path id="Path_1492" data-name="Path 1492" d="M22.982,22.289H1.387V.695A.694.694,0,0,0,0,.695V22.983a.693.693,0,0,0,.694.694H22.982a.694.694,0,1,0,0-1.387Z" transform="translate(0 -0.001)" fill="#fff" />
                    <path id="Path_1726" data-name="Path 1726" d="M215.855,240h-4.162a.693.693,0,0,0-.694.694v8.416a.693.693,0,0,0,.694.694h4.162a.693.693,0,0,0,.694-.694v-8.416A.693.693,0,0,0,215.855,240Z" transform="translate(-201.243 -228.903)" fill="#fff" />
                  </svg>
                </span>
                <span className="after-icon">
                  <svg id="Group_1687" data-name="Group 1687" xmlns="http://www.w3.org/2000/svg" width="23.676" height="23.676" viewBox="0 0 23.676 23.676">
                    <path id="Path_1488" data-name="Path 1488" d="M62.184,69.508l3.671-3.671.9.9a.693.693,0,0,0,.981,0L72.1,62.369v1.1a.694.694,0,1,0,1.387,0V60.695A.7.7,0,0,0,72.791,60H70.017a.694.694,0,1,0,0,1.387h1.1l-3.875,3.875-.9-.9a.693.693,0,0,0-.981,0L61.2,68.528a.694.694,0,1,0,.981.981Z" transform="translate(-58.179 -57.226)" fill="#fff" />
                    <path id="Path_1489" data-name="Path 1489" d="M61.694,335.642h4.162a.693.693,0,0,0,.694-.694v-4.254a.693.693,0,0,0-.694-.694H61.694a.693.693,0,0,0-.694.694v4.254A.693.693,0,0,0,61.694,335.642Z" transform="translate(-58.179 -314.741)" fill="#fff" />
                    <path id="Path_1491" data-name="Path 1491" d="M331.692,67.391h.694v10.5a.693.693,0,0,0,.694.694h4.208a.693.693,0,0,0,.694-.694v-10.5h.694a.693.693,0,0,0,.588-1.061l-3.514-5.549a.721.721,0,0,0-1.176,0L331.1,66.33a.693.693,0,0,0,.588,1.061Z" transform="translate(-315.693 -57.681)" fill="#fff" />
                    <path id="Path_1492" data-name="Path 1492" d="M22.982,22.289H1.387V.695A.694.694,0,0,0,0,.695V22.983a.693.693,0,0,0,.694.694H22.982a.694.694,0,1,0,0-1.387Z" transform="translate(0 -0.001)" fill="#fff" />
                    <path id="Path_1726" data-name="Path 1726" d="M215.855,240h-4.162a.693.693,0,0,0-.694.694v8.416a.693.693,0,0,0,.694.694h4.162a.693.693,0,0,0,.694-.694v-8.416A.693.693,0,0,0,215.855,240Z" transform="translate(-201.243 -228.903)" fill="#fff" />
                  </svg>
                </span>
                <span className="sidemenutext">Analytics</span>
              </Link>) : (<Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/agentsleaderboard" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/agentsleaderboard"}>
                <span className="before-icon">
                  <svg id="Group_1687" data-name="Group 1687" xmlns="http://www.w3.org/2000/svg" width="23.676" height="23.676" viewBox="0 0 23.676 23.676">
                    <path id="Path_1488" data-name="Path 1488" d="M62.184,69.508l3.671-3.671.9.9a.693.693,0,0,0,.981,0L72.1,62.369v1.1a.694.694,0,1,0,1.387,0V60.695A.7.7,0,0,0,72.791,60H70.017a.694.694,0,1,0,0,1.387h1.1l-3.875,3.875-.9-.9a.693.693,0,0,0-.981,0L61.2,68.528a.694.694,0,1,0,.981.981Z" transform="translate(-58.179 -57.226)" fill="#fff" />
                    <path id="Path_1489" data-name="Path 1489" d="M61.694,335.642h4.162a.693.693,0,0,0,.694-.694v-4.254a.693.693,0,0,0-.694-.694H61.694a.693.693,0,0,0-.694.694v4.254A.693.693,0,0,0,61.694,335.642Z" transform="translate(-58.179 -314.741)" fill="#fff" />
                    <path id="Path_1491" data-name="Path 1491" d="M331.692,67.391h.694v10.5a.693.693,0,0,0,.694.694h4.208a.693.693,0,0,0,.694-.694v-10.5h.694a.693.693,0,0,0,.588-1.061l-3.514-5.549a.721.721,0,0,0-1.176,0L331.1,66.33a.693.693,0,0,0,.588,1.061Z" transform="translate(-315.693 -57.681)" fill="#fff" />
                    <path id="Path_1492" data-name="Path 1492" d="M22.982,22.289H1.387V.695A.694.694,0,0,0,0,.695V22.983a.693.693,0,0,0,.694.694H22.982a.694.694,0,1,0,0-1.387Z" transform="translate(0 -0.001)" fill="#fff" />
                    <path id="Path_1726" data-name="Path 1726" d="M215.855,240h-4.162a.693.693,0,0,0-.694.694v8.416a.693.693,0,0,0,.694.694h4.162a.693.693,0,0,0,.694-.694v-8.416A.693.693,0,0,0,215.855,240Z" transform="translate(-201.243 -228.903)" fill="#fff" />
                  </svg>
                </span>
                <span className="after-icon">
                  <svg id="Group_1687" data-name="Group 1687" xmlns="http://www.w3.org/2000/svg" width="23.676" height="23.676" viewBox="0 0 23.676 23.676">
                    <path id="Path_1488" data-name="Path 1488" d="M62.184,69.508l3.671-3.671.9.9a.693.693,0,0,0,.981,0L72.1,62.369v1.1a.694.694,0,1,0,1.387,0V60.695A.7.7,0,0,0,72.791,60H70.017a.694.694,0,1,0,0,1.387h1.1l-3.875,3.875-.9-.9a.693.693,0,0,0-.981,0L61.2,68.528a.694.694,0,1,0,.981.981Z" transform="translate(-58.179 -57.226)" fill="#fff" />
                    <path id="Path_1489" data-name="Path 1489" d="M61.694,335.642h4.162a.693.693,0,0,0,.694-.694v-4.254a.693.693,0,0,0-.694-.694H61.694a.693.693,0,0,0-.694.694v4.254A.693.693,0,0,0,61.694,335.642Z" transform="translate(-58.179 -314.741)" fill="#fff" />
                    <path id="Path_1491" data-name="Path 1491" d="M331.692,67.391h.694v10.5a.693.693,0,0,0,.694.694h4.208a.693.693,0,0,0,.694-.694v-10.5h.694a.693.693,0,0,0,.588-1.061l-3.514-5.549a.721.721,0,0,0-1.176,0L331.1,66.33a.693.693,0,0,0,.588,1.061Z" transform="translate(-315.693 -57.681)" fill="#fff" />
                    <path id="Path_1492" data-name="Path 1492" d="M22.982,22.289H1.387V.695A.694.694,0,0,0,0,.695V22.983a.693.693,0,0,0,.694.694H22.982a.694.694,0,1,0,0-1.387Z" transform="translate(0 -0.001)" fill="#fff" />
                    <path id="Path_1726" data-name="Path 1726" d="M215.855,240h-4.162a.693.693,0,0,0-.694.694v8.416a.693.693,0,0,0,.694.694h4.162a.693.693,0,0,0,.694-.694v-8.416A.693.693,0,0,0,215.855,240Z" transform="translate(-201.243 -228.903)" fill="#fff" />
                  </svg>
                </span>
                <span className="sidemenutext">Analytics</span>
              </Link>)}
            </li>
            </>) : (null)}

            {(this.state.role === 'Owner' || this.state.role === 'Admin') ? (<><li className="nav-item ">
              <Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/integration" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/integration"}>
                <span className="before-icon integration-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33.185" height="33.164" viewBox="0 0 33.185 33.164">
                    <g id="puzzle" transform="translate(-0.406 16.963) rotate(-45)">
                      <g id="Group_1660" data-name="Group 1660" transform="translate(9.817 12.228)">
                        <g id="Group_1659" data-name="Group 1659" transform="translate(0)">
                          <path id="Path_1797" data-name="Path 1797" d="M205.065,256.937c-.434.386-1.051.217-1.051-.535v-1.808a1,1,0,0,0-.993-.993h-1.268a2.113,2.113,0,0,1,.222.931,2.027,2.027,0,0,1-2.064,2.006h-.077a2.027,2.027,0,0,1-2.064-2.006,2.082,2.082,0,0,1,.222-.931h-1.345a.974.974,0,0,0-.974.974V256.4c0,.752-.627.916-1.061.535a1.128,1.128,0,1,0,0,1.717c.434-.386,1.066-.217,1.066.535v1.789a.974.974,0,0,0,.974.974h1.813c.752,0,.916.632.535,1.066a1.132,1.132,0,1,0,1.717,0c-.386-.434-.217-1.07.535-1.07h1.8a.974.974,0,0,0,.974-.974v-1.789c0-.752.612-.916,1.046-.535a1.125,1.125,0,1,0,0-1.712Z" transform="translate(-192.75 -253.6)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1662" data-name="Group 1662" transform="translate(12.72 0.024)">
                        <g id="Group_1661" data-name="Group 1661">
                          <path id="Path_1798" data-name="Path 1798" d="M262.372,6.71c-.434.386-1.07.217-1.07-.535V4.382a.974.974,0,0,0-.974-.974h-1.789c-.752,0-.916-.612-.535-1.046a1.128,1.128,0,1,0-1.717,0c.386.434.217,1.051-.535,1.051h-1.808a1,1,0,0,0-.993.993V5.669a2.113,2.113,0,0,1,.931-.222,2.028,2.028,0,0,1,2.011,2.064v.077a2.027,2.027,0,0,1-2.006,2.064,2.082,2.082,0,0,1-.931-.222v1.345a.974.974,0,0,0,.974.974h1.827c.752,0,.916.627.535,1.061a1.128,1.128,0,1,0,1.717,0c-.386-.434-.217-1.066.535-1.066h1.789a.974.974,0,0,0,.974-.974V8.962c0-.752.632-.916,1.066-.535a1.1,1.1,0,0,0,1.871-.858A1.1,1.1,0,0,0,262.372,6.71Z" transform="translate(-252.95 -0.5)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1664" data-name="Group 1664" transform="translate(0.55 9.292)">
                        <g id="Group_1663" data-name="Group 1663" transform="translate(0)">
                          <path id="Path_1799" data-name="Path 1799" d="M10.864,195.622H9.036c-.752,0-.916-.627-.535-1.061a1.092,1.092,0,0,0-.858-1.861,1.09,1.09,0,0,0-.858,1.856c.386.434.217,1.066-.535,1.066H4.46a.974.974,0,0,0-.974.974v1.813c0,.752-.632.916-1.066.535a1.129,1.129,0,1,0,0,1.707c.434-.386,1.07-.217,1.07.535v1.8a.974.974,0,0,0,.974.974H6.249c.752,0,.916.612.535,1.046a1.092,1.092,0,0,0,.858,1.861,1.089,1.089,0,0,0,.858-1.856c-.386-.434-.217-1.051.535-1.051h1.808a1,1,0,0,0,.993-.993V201.7a2.112,2.112,0,0,1-.931.222A2.027,2.027,0,0,1,8.9,199.86v-.077a2.027,2.027,0,0,1,2.006-2.064,2.082,2.082,0,0,1,.931.222V196.6A.974.974,0,0,0,10.864,195.622Z" transform="translate(-0.55 -192.7)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1666" data-name="Group 1666" transform="translate(0.574 0)">
                        <g id="Group_1665" data-name="Group 1665" transform="translate(0 0)">
                          <path id="Path_1800" data-name="Path 1800" d="M13.365,6.23c-.434.386-1.066.217-1.066-.535V3.91a.974.974,0,0,0-.974-.974H9.512c-.752,0-.916-.632-.535-1.066A1.1,1.1,0,0,0,8.119,0,1.1,1.1,0,0,0,7.26,1.871c.386.434.217,1.07-.535,1.07H4.932a.974.974,0,0,0-.974.974V5.7c0,.752-.612.916-1.046.535A1.092,1.092,0,0,0,1.05,7.1a1.088,1.088,0,0,0,1.856.849c.434-.386,1.051-.217,1.051.535V10.29a1,1,0,0,0,.993.993H6.219A2.113,2.113,0,0,1,6,10.352,2.027,2.027,0,0,1,8.061,8.347h.077A2.027,2.027,0,0,1,10.2,10.352a2.082,2.082,0,0,1-.222.931h1.345a.974.974,0,0,0,.974-.974V8.482c0-.752.627-.916,1.061-.535a1.092,1.092,0,0,0,1.861-.858A1.09,1.09,0,0,0,13.365,6.23Z" transform="translate(-1.05 0)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="after-icon integration-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33.185" height="33.164" viewBox="0 0 33.185 33.164">
                    <g id="puzzle" transform="translate(-0.406 16.963) rotate(-45)">
                      <g id="Group_1660" data-name="Group 1660" transform="translate(9.817 12.228)">
                        <g id="Group_1659" data-name="Group 1659" transform="translate(0)">
                          <path id="Path_1797" data-name="Path 1797" d="M205.065,256.937c-.434.386-1.051.217-1.051-.535v-1.808a1,1,0,0,0-.993-.993h-1.268a2.113,2.113,0,0,1,.222.931,2.027,2.027,0,0,1-2.064,2.006h-.077a2.027,2.027,0,0,1-2.064-2.006,2.082,2.082,0,0,1,.222-.931h-1.345a.974.974,0,0,0-.974.974V256.4c0,.752-.627.916-1.061.535a1.128,1.128,0,1,0,0,1.717c.434-.386,1.066-.217,1.066.535v1.789a.974.974,0,0,0,.974.974h1.813c.752,0,.916.632.535,1.066a1.132,1.132,0,1,0,1.717,0c-.386-.434-.217-1.07.535-1.07h1.8a.974.974,0,0,0,.974-.974v-1.789c0-.752.612-.916,1.046-.535a1.125,1.125,0,1,0,0-1.712Z" transform="translate(-192.75 -253.6)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1662" data-name="Group 1662" transform="translate(12.72 0.024)">
                        <g id="Group_1661" data-name="Group 1661">
                          <path id="Path_1798" data-name="Path 1798" d="M262.372,6.71c-.434.386-1.07.217-1.07-.535V4.382a.974.974,0,0,0-.974-.974h-1.789c-.752,0-.916-.612-.535-1.046a1.128,1.128,0,1,0-1.717,0c.386.434.217,1.051-.535,1.051h-1.808a1,1,0,0,0-.993.993V5.669a2.113,2.113,0,0,1,.931-.222,2.028,2.028,0,0,1,2.011,2.064v.077a2.027,2.027,0,0,1-2.006,2.064,2.082,2.082,0,0,1-.931-.222v1.345a.974.974,0,0,0,.974.974h1.827c.752,0,.916.627.535,1.061a1.128,1.128,0,1,0,1.717,0c-.386-.434-.217-1.066.535-1.066h1.789a.974.974,0,0,0,.974-.974V8.962c0-.752.632-.916,1.066-.535a1.1,1.1,0,0,0,1.871-.858A1.1,1.1,0,0,0,262.372,6.71Z" transform="translate(-252.95 -0.5)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1664" data-name="Group 1664" transform="translate(0.55 9.292)">
                        <g id="Group_1663" data-name="Group 1663" transform="translate(0)">
                          <path id="Path_1799" data-name="Path 1799" d="M10.864,195.622H9.036c-.752,0-.916-.627-.535-1.061a1.092,1.092,0,0,0-.858-1.861,1.09,1.09,0,0,0-.858,1.856c.386.434.217,1.066-.535,1.066H4.46a.974.974,0,0,0-.974.974v1.813c0,.752-.632.916-1.066.535a1.129,1.129,0,1,0,0,1.707c.434-.386,1.07-.217,1.07.535v1.8a.974.974,0,0,0,.974.974H6.249c.752,0,.916.612.535,1.046a1.092,1.092,0,0,0,.858,1.861,1.089,1.089,0,0,0,.858-1.856c-.386-.434-.217-1.051.535-1.051h1.808a1,1,0,0,0,.993-.993V201.7a2.112,2.112,0,0,1-.931.222A2.027,2.027,0,0,1,8.9,199.86v-.077a2.027,2.027,0,0,1,2.006-2.064,2.082,2.082,0,0,1,.931.222V196.6A.974.974,0,0,0,10.864,195.622Z" transform="translate(-0.55 -192.7)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1666" data-name="Group 1666" transform="translate(0.574 0)">
                        <g id="Group_1665" data-name="Group 1665" transform="translate(0 0)">
                          <path id="Path_1800" data-name="Path 1800" d="M13.365,6.23c-.434.386-1.066.217-1.066-.535V3.91a.974.974,0,0,0-.974-.974H9.512c-.752,0-.916-.632-.535-1.066A1.1,1.1,0,0,0,8.119,0,1.1,1.1,0,0,0,7.26,1.871c.386.434.217,1.07-.535,1.07H4.932a.974.974,0,0,0-.974.974V5.7c0,.752-.612.916-1.046.535A1.092,1.092,0,0,0,1.05,7.1a1.088,1.088,0,0,0,1.856.849c.434-.386,1.051-.217,1.051.535V10.29a1,1,0,0,0,.993.993H6.219A2.113,2.113,0,0,1,6,10.352,2.027,2.027,0,0,1,8.061,8.347h.077A2.027,2.027,0,0,1,10.2,10.352a2.082,2.082,0,0,1-.222.931h1.345a.974.974,0,0,0,.974-.974V8.482c0-.752.627-.916,1.061-.535a1.092,1.092,0,0,0,1.861-.858A1.09,1.09,0,0,0,13.365,6.23Z" transform="translate(-1.05 0)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="sidemenutext">Integration</span>
              </Link>
            </li>
            </>) : (null)}

            {/* {(this.state.role === 'Owner') ? (<><li className="nav-item ">
              <Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/ticketing" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/ticketing"}>
                <span className="before-icon integration-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.185" height="33.164" viewBox="0 0 33.185 33.164">
                    <g id="puzzle" transform="translate(-0.406 16.963) rotate(-45)">
                      <g id="Group_1660" data-name="Group 1660" transform="translate(9.817 12.228)">
                        <g id="Group_1659" data-name="Group 1659" transform="translate(0)">
                          <path id="Path_1797" data-name="Path 1797" d="M205.065,256.937c-.434.386-1.051.217-1.051-.535v-1.808a1,1,0,0,0-.993-.993h-1.268a2.113,2.113,0,0,1,.222.931,2.027,2.027,0,0,1-2.064,2.006h-.077a2.027,2.027,0,0,1-2.064-2.006,2.082,2.082,0,0,1,.222-.931h-1.345a.974.974,0,0,0-.974.974V256.4c0,.752-.627.916-1.061.535a1.128,1.128,0,1,0,0,1.717c.434-.386,1.066-.217,1.066.535v1.789a.974.974,0,0,0,.974.974h1.813c.752,0,.916.632.535,1.066a1.132,1.132,0,1,0,1.717,0c-.386-.434-.217-1.07.535-1.07h1.8a.974.974,0,0,0,.974-.974v-1.789c0-.752.612-.916,1.046-.535a1.125,1.125,0,1,0,0-1.712Z" transform="translate(-192.75 -253.6)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1662" data-name="Group 1662" transform="translate(12.72 0.024)">
                        <g id="Group_1661" data-name="Group 1661">
                          <path id="Path_1798" data-name="Path 1798" d="M262.372,6.71c-.434.386-1.07.217-1.07-.535V4.382a.974.974,0,0,0-.974-.974h-1.789c-.752,0-.916-.612-.535-1.046a1.128,1.128,0,1,0-1.717,0c.386.434.217,1.051-.535,1.051h-1.808a1,1,0,0,0-.993.993V5.669a2.113,2.113,0,0,1,.931-.222,2.028,2.028,0,0,1,2.011,2.064v.077a2.027,2.027,0,0,1-2.006,2.064,2.082,2.082,0,0,1-.931-.222v1.345a.974.974,0,0,0,.974.974h1.827c.752,0,.916.627.535,1.061a1.128,1.128,0,1,0,1.717,0c-.386-.434-.217-1.066.535-1.066h1.789a.974.974,0,0,0,.974-.974V8.962c0-.752.632-.916,1.066-.535a1.1,1.1,0,0,0,1.871-.858A1.1,1.1,0,0,0,262.372,6.71Z" transform="translate(-252.95 -0.5)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1664" data-name="Group 1664" transform="translate(0.55 9.292)">
                        <g id="Group_1663" data-name="Group 1663" transform="translate(0)">
                          <path id="Path_1799" data-name="Path 1799" d="M10.864,195.622H9.036c-.752,0-.916-.627-.535-1.061a1.092,1.092,0,0,0-.858-1.861,1.09,1.09,0,0,0-.858,1.856c.386.434.217,1.066-.535,1.066H4.46a.974.974,0,0,0-.974.974v1.813c0,.752-.632.916-1.066.535a1.129,1.129,0,1,0,0,1.707c.434-.386,1.07-.217,1.07.535v1.8a.974.974,0,0,0,.974.974H6.249c.752,0,.916.612.535,1.046a1.092,1.092,0,0,0,.858,1.861,1.089,1.089,0,0,0,.858-1.856c-.386-.434-.217-1.051.535-1.051h1.808a1,1,0,0,0,.993-.993V201.7a2.112,2.112,0,0,1-.931.222A2.027,2.027,0,0,1,8.9,199.86v-.077a2.027,2.027,0,0,1,2.006-2.064,2.082,2.082,0,0,1,.931.222V196.6A.974.974,0,0,0,10.864,195.622Z" transform="translate(-0.55 -192.7)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1666" data-name="Group 1666" transform="translate(0.574 0)">
                        <g id="Group_1665" data-name="Group 1665" transform="translate(0 0)">
                          <path id="Path_1800" data-name="Path 1800" d="M13.365,6.23c-.434.386-1.066.217-1.066-.535V3.91a.974.974,0,0,0-.974-.974H9.512c-.752,0-.916-.632-.535-1.066A1.1,1.1,0,0,0,8.119,0,1.1,1.1,0,0,0,7.26,1.871c.386.434.217,1.07-.535,1.07H4.932a.974.974,0,0,0-.974.974V5.7c0,.752-.612.916-1.046.535A1.092,1.092,0,0,0,1.05,7.1a1.088,1.088,0,0,0,1.856.849c.434-.386,1.051-.217,1.051.535V10.29a1,1,0,0,0,.993.993H6.219A2.113,2.113,0,0,1,6,10.352,2.027,2.027,0,0,1,8.061,8.347h.077A2.027,2.027,0,0,1,10.2,10.352a2.082,2.082,0,0,1-.222.931h1.345a.974.974,0,0,0,.974-.974V8.482c0-.752.627-.916,1.061-.535a1.092,1.092,0,0,0,1.861-.858A1.09,1.09,0,0,0,13.365,6.23Z" transform="translate(-1.05 0)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="after-icon integration-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33.185" height="33.164" viewBox="0 0 33.185 33.164">
                    <g id="puzzle" transform="translate(-0.406 16.963) rotate(-45)">
                      <g id="Group_1660" data-name="Group 1660" transform="translate(9.817 12.228)">
                        <g id="Group_1659" data-name="Group 1659" transform="translate(0)">
                          <path id="Path_1797" data-name="Path 1797" d="M205.065,256.937c-.434.386-1.051.217-1.051-.535v-1.808a1,1,0,0,0-.993-.993h-1.268a2.113,2.113,0,0,1,.222.931,2.027,2.027,0,0,1-2.064,2.006h-.077a2.027,2.027,0,0,1-2.064-2.006,2.082,2.082,0,0,1,.222-.931h-1.345a.974.974,0,0,0-.974.974V256.4c0,.752-.627.916-1.061.535a1.128,1.128,0,1,0,0,1.717c.434-.386,1.066-.217,1.066.535v1.789a.974.974,0,0,0,.974.974h1.813c.752,0,.916.632.535,1.066a1.132,1.132,0,1,0,1.717,0c-.386-.434-.217-1.07.535-1.07h1.8a.974.974,0,0,0,.974-.974v-1.789c0-.752.612-.916,1.046-.535a1.125,1.125,0,1,0,0-1.712Z" transform="translate(-192.75 -253.6)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1662" data-name="Group 1662" transform="translate(12.72 0.024)">
                        <g id="Group_1661" data-name="Group 1661">
                          <path id="Path_1798" data-name="Path 1798" d="M262.372,6.71c-.434.386-1.07.217-1.07-.535V4.382a.974.974,0,0,0-.974-.974h-1.789c-.752,0-.916-.612-.535-1.046a1.128,1.128,0,1,0-1.717,0c.386.434.217,1.051-.535,1.051h-1.808a1,1,0,0,0-.993.993V5.669a2.113,2.113,0,0,1,.931-.222,2.028,2.028,0,0,1,2.011,2.064v.077a2.027,2.027,0,0,1-2.006,2.064,2.082,2.082,0,0,1-.931-.222v1.345a.974.974,0,0,0,.974.974h1.827c.752,0,.916.627.535,1.061a1.128,1.128,0,1,0,1.717,0c-.386-.434-.217-1.066.535-1.066h1.789a.974.974,0,0,0,.974-.974V8.962c0-.752.632-.916,1.066-.535a1.1,1.1,0,0,0,1.871-.858A1.1,1.1,0,0,0,262.372,6.71Z" transform="translate(-252.95 -0.5)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1664" data-name="Group 1664" transform="translate(0.55 9.292)">
                        <g id="Group_1663" data-name="Group 1663" transform="translate(0)">
                          <path id="Path_1799" data-name="Path 1799" d="M10.864,195.622H9.036c-.752,0-.916-.627-.535-1.061a1.092,1.092,0,0,0-.858-1.861,1.09,1.09,0,0,0-.858,1.856c.386.434.217,1.066-.535,1.066H4.46a.974.974,0,0,0-.974.974v1.813c0,.752-.632.916-1.066.535a1.129,1.129,0,1,0,0,1.707c.434-.386,1.07-.217,1.07.535v1.8a.974.974,0,0,0,.974.974H6.249c.752,0,.916.612.535,1.046a1.092,1.092,0,0,0,.858,1.861,1.089,1.089,0,0,0,.858-1.856c-.386-.434-.217-1.051.535-1.051h1.808a1,1,0,0,0,.993-.993V201.7a2.112,2.112,0,0,1-.931.222A2.027,2.027,0,0,1,8.9,199.86v-.077a2.027,2.027,0,0,1,2.006-2.064,2.082,2.082,0,0,1,.931.222V196.6A.974.974,0,0,0,10.864,195.622Z" transform="translate(-0.55 -192.7)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_1666" data-name="Group 1666" transform="translate(0.574 0)">
                        <g id="Group_1665" data-name="Group 1665" transform="translate(0 0)">
                          <path id="Path_1800" data-name="Path 1800" d="M13.365,6.23c-.434.386-1.066.217-1.066-.535V3.91a.974.974,0,0,0-.974-.974H9.512c-.752,0-.916-.632-.535-1.066A1.1,1.1,0,0,0,8.119,0,1.1,1.1,0,0,0,7.26,1.871c.386.434.217,1.07-.535,1.07H4.932a.974.974,0,0,0-.974.974V5.7c0,.752-.612.916-1.046.535A1.092,1.092,0,0,0,1.05,7.1a1.088,1.088,0,0,0,1.856.849c.434-.386,1.051-.217,1.051.535V10.29a1,1,0,0,0,.993.993H6.219A2.113,2.113,0,0,1,6,10.352,2.027,2.027,0,0,1,8.061,8.347h.077A2.027,2.027,0,0,1,10.2,10.352a2.082,2.082,0,0,1-.222.931h1.345a.974.974,0,0,0,.974-.974V8.482c0-.752.627-.916,1.061-.535a1.092,1.092,0,0,0,1.861-.858A1.09,1.09,0,0,0,13.365,6.23Z" transform="translate(-1.05 0)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="sidemenutext">Ticketing</span>
              </Link>
            </li>
            </>) : (null)} */}

            {/* { (this.state.role === 'Admin' || this.state.role === 'Owner') ? (<li className="nav-item ">
            <a className="nav-link position-relative p-0 py-xl-4" title="Test Drive" target="_blank" role="tab" href={"/" + this.agent.ownerIdentifire + "/test-drive?widget_type=drive"}>
              {/* <a className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/banned-users" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Ban User" role="tab" href={"/" + this.agent.ownerIdentifire + "/banned-users"}> */}
            {/* <span className="before-icon">
                <img src={require('../../assets/img/Test_Drive_Icon.png')} style={{width:'27px'}} />

                </span>
                <span className="after-icon">
                  <img src={require('../../assets/img/Test_Drive_Icon.png')} style={{width:'27px'}} />
                </span>
              </a>
            </li>) : (null)} */}

            {/* Dialer Start */}

            {(this.agent.phone_number && enableDialer) ? (<><li className="nav-item position-relative donthidebyclkonbdy">
              <span onClick={this.showDialer} className="nav-link position-relative p-0 py-xl-4 donthidebyclkonbdy" data-toggle="tab" role="tab">
                <span className="donthidebyclkonbdy"><img className="donthidebyclkonbdy" src={require('../../assets/images/livechat/dialer.png')} /></span>
              </span>
              <div className="dialer-nav donthidebyclkonbdy" style={{ 'display': this.state.showDialer ? 'block' : 'none' }}>
                <span className="dialer-crose" onClick={this.hideDialer}><img src={require('../../assets/img/icon/right-panel-crose.svg')} /></span>
                <div className="dialpad compact donthidebyclkonbdy">
                  <div className="number donthidebyclkonbdy">
                    <input style={{ width: "calc(100% - 10px)", border: "none" }} className="donthidebyclkonbdy" onChange={this.handTextChange} onKeyDown={this.handleKeyDown} type="text" name="phone_number" id="phone_number" />
                  </div>
                  <div className="dials donthidebyclkonbdy">
                    {this.state.showSmsArea ? (<textarea placeholder="Type your sms..." style={{ borderRadius: "10px", padding: "0 5px" }} className="donthidebyclkonbdy" name="txt_Sms" id="txt_Sms" rows="4" cols="28"></textarea>) : (null)}
                    <ol className="numberdials donthidebyclkonbdy">
                      <li onClick={(e) => this.dialNumber(1, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">1</strong></p></li>
                      <li onClick={(e) => this.dialNumber(2, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">2</strong></p></li>
                      <li onClick={(e) => this.dialNumber(3, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">3</strong></p></li>
                      <li onClick={(e) => this.dialNumber(4, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">4</strong></p></li>
                      <li onClick={(e) => this.dialNumber(5, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">5</strong></p></li>
                      <li onClick={(e) => this.dialNumber(6, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">6</strong></p></li>
                      <li onClick={(e) => this.dialNumber(7, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">7</strong></p></li>
                      <li onClick={(e) => this.dialNumber(8, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">8</strong></p></li>
                      <li onClick={(e) => this.dialNumber(9, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">9</strong></p></li>
                      <li onClick={(e) => this.dialNumber("*", e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">*</strong></p></li>
                      <li onClick={(e) => this.dialNumber(0, e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">0</strong><sup className="forDetectPlus donthidebyclkonbdy" id="forDetectPlus" style={{ fontSize: "26px" }} onClick={(e) => this.dialNumber("+", e)}>+</sup></p></li>
                      <li onClick={(e) => this.dialNumber("#", e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy">#</strong></p></li>
                      {/*<li onClick={this.showHideSmsArea} className="digitsms donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy"><i className="fa fa-refresh donthidebyclkonbdy"></i></strong><sup className="donthidebyclkonbdy">Call/Sms</sup></p></li> */}
                      {this.state.showSmsArea ? (<li onClick={this.showHideSmsArea} className="digitsms donthidebyclkonbdy"><img className="donthidebyclkonbdy" width="40px" alt="" style={{ paddingTop: "8px" }} src={require('../../assets/images/livechat/call-icon.png')} /></li>) : (<li onClick={this.showHideSmsArea} className="digitsms donthidebyclkonbdy"><img className="donthidebyclkonbdy" width="40px" style={{ paddingTop: "8px" }} alt="" src={require('../../assets/images/livechat/sms-icon.png')} /></li>)}
                      <li onClick={(e) => this.dialNumber("delete", e)} className="digits donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy"><i className="fa fa-times donthidebyclkonbdy"></i></strong><sup className="donthidebyclkonbdy">Delete</sup></p></li>
                      <li id="btncall" onClick={(e) => this.dialNumber("call", e)} className="digits pad-action"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy"><i className="fa fa-phone donthidebyclkonbdy"></i></strong> <sup className="donthidebyclkonbdy">Call</sup></p></li>
                      <li style={{ display: "none" }} id="btnhangup" onClick={(e) => this.dialNumber("hangup", e)} className="digits pad-hangup donthidebyclkonbdy"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy"><i className="fa fa-phone donthidebyclkonbdy"></i></strong> <sup className="donthidebyclkonbdy">Hangup</sup></p></li>
                      <li style={{ display: "none" }} id="btnsendsms" onClick={(e) => this.dialNumber("send_sms", e)} className="digits pad-action"><p className="donthidebyclkonbdy"><strong className="donthidebyclkonbdy"><i className="fa fa-phone donthidebyclkonbdy"></i></strong> <sup className="donthidebyclkonbdy">Send</sup></p></li>
                    </ol>
                  </div>
                </div>
              </div>
            </li></>) : (null)}

            {/* Dialer End */}

            <li className="nav-item flex-xl-grow-1 mt-auto">
              {/* <a className="nav-link position-relative p-0 py-xl-4" data-toggle="tab" style={{cursor:'pointer'}}
              title="Demos" role="tab">
              <span><img alt="" src={require('../../assets/images/left-sidebar/2.svg')} /></span>
            </a> */}
            </li>

            {(this.state.role === 'Owner') ? (<><li className="nav-item ">
              <Link className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/subscription" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} role="tab" to={"/" + this.agent.ownerIdentifire + "/subscription"}>
                <span className="before-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={30} viewBox="0 0 39.932 37.879" style={{width:'26px'}}>
                    <g id="Group_20351" data-name="Group 20351" transform="translate(-266.159 -276.494)">
                      <path id="Path_2731" data-name="Path 2731" d="M306.091,285.768c-.1.493-.181.993-.313,1.478a8.485,8.485,0,1,1,.241-3.336,2.655,2.655,0,0,0,.072.3Zm-10.142-3.1a2.084,2.084,0,0,1,.051-.325,1.618,1.618,0,0,1,3.178.288c.066.51.3.761.684.716s.583-.362.523-.853a2.635,2.635,0,0,0-1.5-2.219c-.526-.242-.89-.435-.807-1.1.041-.327-.275-.536-.6-.517a.57.57,0,0,0-.568.537,1.694,1.694,0,0,0-.007.427c.028.266-.054.4-.333.5a2.806,2.806,0,0,0-.014,5.257,5,5,0,0,0,1.179.218,1.621,1.621,0,1,1-1.78,1.693c-.044-.466-.291-.71-.681-.669-.36.037-.563.333-.525.772a2.58,2.58,0,0,0,1.344,2.2c.562.293.947.545.835,1.265-.047.3.237.515.566.521a.553.553,0,0,0,.6-.5,2.109,2.109,0,0,0,.019-.544c-.022-.249.068-.355.314-.433a2.807,2.807,0,0,0,1.978-2.617,2.844,2.844,0,0,0-2.832-2.9,1.63,1.63,0,0,1-1.624-1.716Z" fill="#fff" />
                      <path id="Path_2732" data-name="Path 2732" d="M271.751,306.613l.153-23.639h16.2a9.841,9.841,0,0,0,.68,5.994h-6.346a1.788,1.788,0,0,0-.388.016.57.57,0,0,0-.495.608.55.55,0,0,0,.5.563,3.149,3.149,0,0,0,.466.023c2.183,0,4.365.005,6.548-.005a.633.633,0,0,1,.6.315,9.374,9.374,0,0,0,5.781,3.934c.266.061.344.166.343.439-.009,5.092,0,10.185-.01,15.278a4.122,4.122,0,0,1-8.03,1.407,5.121,5.121,0,0,1-.22-1.412c-.034-.895-.01-1.793-.011-2.689,0-.672-.157-.832-.822-.832H271.751Zm14.985-10.406h4.322a1.771,1.771,0,0,0,.35-.013.571.571,0,0,0,.517-.586.561.561,0,0,0-.5-.6,1.522,1.522,0,0,0-.31-.021h-8.722a1.655,1.655,0,0,0-.385.036.589.589,0,0,0-.016,1.14,1.616,1.616,0,0,0,.423.039Q284.576,296.21,286.736,296.207Zm0,6.016h4.516a.6.6,0,0,0,.676-.589c0-.359-.241-.585-.661-.6-.117-.006-.234,0-.35,0h-7.125c-.519,0-1.041-.031-1.556.016a.891.891,0,0,0-.6.324c-.266.4.077.854.624.856q2.238-.006,4.477-.007ZM279.848,289.6a2.118,2.118,0,1,0-2.1,2.064,2.087,2.087,0,0,0,2.1-2.064Zm-2.119,9.945a2.042,2.042,0,1,0,2.118,2.034,2.084,2.084,0,0,0-2.118-2.032Zm.017-1.906a2.042,2.042,0,1,0-2.132-2.03,2.086,2.086,0,0,0,2.132,2.032Z" fill="#fff" />
                      <path id="Path_2733" data-name="Path 2733" d="M266.169,307.842H286.3v.422c.014.844-.01,1.691.053,2.531a4.978,4.978,0,0,0,1.443,3.118c.123.13.271.236.491.424-.232.017-.34.032-.449.032H270.492a4.147,4.147,0,0,1-4.328-4.349Z" fill="#fff" />
                      <path id="Path_2740" data-name="Path 2740" d="M277.731,290.451a.86.86,0,0,1-.9-.83.851.851,0,0,1,.887-.838.838.838,0,1,1,.01,1.668Z" fill="#fff" />
                      <path id="Path_2741" data-name="Path 2741" d="M276.835,301.591a.869.869,0,0,1,.887-.846.883.883,0,0,1,.922.856.892.892,0,0,1-.913.829A.869.869,0,0,1,276.835,301.591Z" fill="#fff" />
                      <path id="Path_2742" data-name="Path 2742" d="M277.737,294.757a.845.845,0,1,1-.777.4.847.847,0,0,1,.777-.4Z" fill="#fff" />
                    </g>
                  </svg> 
                </span>
                <span className="after-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={30} viewBox="0 0 39.932 37.879" style={{width:'26px'}}>
                    <g id="Group_20351" data-name="Group 20351" transform="translate(-266.159 -276.494)">
                      <path id="Path_2731" data-name="Path 2731" d="M306.091,285.768c-.1.493-.181.993-.313,1.478a8.485,8.485,0,1,1,.241-3.336,2.655,2.655,0,0,0,.072.3Zm-10.142-3.1a2.084,2.084,0,0,1,.051-.325,1.618,1.618,0,0,1,3.178.288c.066.51.3.761.684.716s.583-.362.523-.853a2.635,2.635,0,0,0-1.5-2.219c-.526-.242-.89-.435-.807-1.1.041-.327-.275-.536-.6-.517a.57.57,0,0,0-.568.537,1.694,1.694,0,0,0-.007.427c.028.266-.054.4-.333.5a2.806,2.806,0,0,0-.014,5.257,5,5,0,0,0,1.179.218,1.621,1.621,0,1,1-1.78,1.693c-.044-.466-.291-.71-.681-.669-.36.037-.563.333-.525.772a2.58,2.58,0,0,0,1.344,2.2c.562.293.947.545.835,1.265-.047.3.237.515.566.521a.553.553,0,0,0,.6-.5,2.109,2.109,0,0,0,.019-.544c-.022-.249.068-.355.314-.433a2.807,2.807,0,0,0,1.978-2.617,2.844,2.844,0,0,0-2.832-2.9,1.63,1.63,0,0,1-1.624-1.716Z" fill="#fff" />
                      <path id="Path_2732" data-name="Path 2732" d="M271.751,306.613l.153-23.639h16.2a9.841,9.841,0,0,0,.68,5.994h-6.346a1.788,1.788,0,0,0-.388.016.57.57,0,0,0-.495.608.55.55,0,0,0,.5.563,3.149,3.149,0,0,0,.466.023c2.183,0,4.365.005,6.548-.005a.633.633,0,0,1,.6.315,9.374,9.374,0,0,0,5.781,3.934c.266.061.344.166.343.439-.009,5.092,0,10.185-.01,15.278a4.122,4.122,0,0,1-8.03,1.407,5.121,5.121,0,0,1-.22-1.412c-.034-.895-.01-1.793-.011-2.689,0-.672-.157-.832-.822-.832H271.751Zm14.985-10.406h4.322a1.771,1.771,0,0,0,.35-.013.571.571,0,0,0,.517-.586.561.561,0,0,0-.5-.6,1.522,1.522,0,0,0-.31-.021h-8.722a1.655,1.655,0,0,0-.385.036.589.589,0,0,0-.016,1.14,1.616,1.616,0,0,0,.423.039Q284.576,296.21,286.736,296.207Zm0,6.016h4.516a.6.6,0,0,0,.676-.589c0-.359-.241-.585-.661-.6-.117-.006-.234,0-.35,0h-7.125c-.519,0-1.041-.031-1.556.016a.891.891,0,0,0-.6.324c-.266.4.077.854.624.856q2.238-.006,4.477-.007ZM279.848,289.6a2.118,2.118,0,1,0-2.1,2.064,2.087,2.087,0,0,0,2.1-2.064Zm-2.119,9.945a2.042,2.042,0,1,0,2.118,2.034,2.084,2.084,0,0,0-2.118-2.032Zm.017-1.906a2.042,2.042,0,1,0-2.132-2.03,2.086,2.086,0,0,0,2.132,2.032Z" fill="#fff" />
                      <path id="Path_2733" data-name="Path 2733" d="M266.169,307.842H286.3v.422c.014.844-.01,1.691.053,2.531a4.978,4.978,0,0,0,1.443,3.118c.123.13.271.236.491.424-.232.017-.34.032-.449.032H270.492a4.147,4.147,0,0,1-4.328-4.349Z" fill="#fff" />
                      <path id="Path_2740" data-name="Path 2740" d="M277.731,290.451a.86.86,0,0,1-.9-.83.851.851,0,0,1,.887-.838.838.838,0,1,1,.01,1.668Z" fill="#fff" />
                      <path id="Path_2741" data-name="Path 2741" d="M276.835,301.591a.869.869,0,0,1,.887-.846.883.883,0,0,1,.922.856.892.892,0,0,1-.913.829A.869.869,0,0,1,276.835,301.591Z" fill="#fff" />
                      <path id="Path_2742" data-name="Path 2742" d="M277.737,294.757a.845.845,0,1,1-.777.4.847.847,0,0,1,.777-.4Z" fill="#fff" />
                    </g>
                  </svg>  
                </span>
                <span className="sidemenutext">Billing</span>
              </Link>
            </li>
            </>) : (null)}

            {settings}
            <li className="nav-item position-relative mb-2 mob-icon profile-icon" style={{ height: '70px', margin: '0' }}>
              <a href="#" className={this.state.profile_display === true ? "nav-link position-relative p-0 py-xl-4 active" : "nav-link position-relative p-0 py-xl-4 "} style={{ cursor: 'pointer' }} data-toggle="tab" role="tab" onClick={this.showProfile}>
                <span className="user-profile-nav" id={this.state.agentProfilePic}>{profile_img}</span>
                <img alt="" src={(require(`../../assets/img/icon/${onlineImg}`))} style={{ position: 'absolute', minWidth: 10, minHeight: 10, width: 10, height: 10, bottom: 2, right: 15 }} />
                <span className="sidemenutext">My Profile</span>
              </a>
              <div className="profile-nav" style={{ 'display': this.state.profile_display === true ? 'block' : 'none' }}>
                <span className="profile-crose" onClick={this.hideProfileScreen}><img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} /></span>
                <div className="my-profile-section-rightP py-3 px-4 border-bottom">
                  <div className="media text-center text-xl-left profile-click">
                    <div className="avatar avatar-sm d-none d-xl-inline-block mr-4"><span>{profile_img}</span><img alt="" src={(require(`../../assets/img/icon/${onlineImg}`))} style={{ position: 'relative', minWidth: 10, minHeight: 10, width: 10, height: 10, top: -8, left: 27 }} /></div>
                    <div className="media-body align-self-center text-truncate text-left">
                      <h5 className="heding-text text-truncate mb-n3 text-truncate">{this.state.agentName}</h5>
                      <small className="text-muted text-truncate">{this.state.agentEmail}</small>
                      {this.agent.phone_number ? (<><br /><span><svg xmlns="http://www.w3.org/2000/svg" width="15.099" height="15.2" viewBox="0 0 48.099 48.2">
                        <g id="phone-call" transform="translate(-0.539)">
                          <g id="Group_4584" data-name="Group 4584" transform="translate(0.539 0)">
                            <path id="Path_2553" data-name="Path 2553" d="M47.376,35.374,40.65,28.647A4.474,4.474,0,0,0,33.2,30.329a4.578,4.578,0,0,1-5.285,2.883c-4.8-1.2-11.291-7.447-12.492-12.492a4.354,4.354,0,0,1,2.883-5.285A4.474,4.474,0,0,0,19.99,7.988L13.264,1.261a4.8,4.8,0,0,0-6.486,0L2.213,5.826c-4.564,4.8.48,17.537,11.771,28.828S38.007,51.229,42.812,46.424l4.564-4.564A4.8,4.8,0,0,0,47.376,35.374Z" transform="translate(-0.539 0)" fill="#aab0b7" />
                          </g>
                        </g>
                      </svg></span><small className="text-muted text-truncate ml-2">{this.agent.phone_number}</small></>) : (null)}
                    </div>
                  </div>
                </div>
                <div className="roleWithStatus d-flex align-items-center justify-content-center">
                  <div className="role">
                    <p>Role</p>
                    <div className="OutPutRole">
                      <h6>
                        <span className="pr-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 19.035 21.289">
                            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5.5 -4)">
                              <path id="Path_2733" data-name="Path 2733" d="M24.035,29.263V27.009A4.509,4.509,0,0,0,19.526,22.5H10.509A4.509,4.509,0,0,0,6,27.009v2.254" transform="translate(0 -4.474)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} />
                              <path id="Path_2734" data-name="Path 2734" d="M21.017,9.009A4.509,4.509,0,1,1,16.509,4.5a4.509,4.509,0,0,1,4.509,4.509Z" transform="translate(-1.491)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} />
                            </g>
                          </svg>
                        </span>
                        {this.state.role}</h6>
                    </div>
                  </div>
                  <div className="status">
                    {updateStatus}
                  </div>
                </div>
                <div className="myProfilePopups d-flex align-items-center justify-content-center border-top">
                  {this.state.OtherRoles.indexOf('Manage Own Profile') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner' ? (<div className="my-profile-list border-right" onClick={this.showEditProfile}>
                    <p className="card-body-text cursor-pointer">
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 32.326 24.244">
                        <path id="Icon_metro-profile" data-name="Icon metro-profile" d="M34.515,11.176h-2.26V7.784l-4.666-.072.024,3.463H13.59l.1-3.463-4.569.072v3.463l-2.309-.072A2.31,2.31,0,0,0,4.5,13.485V29.647a2.31,2.31,0,0,0,2.309,2.309H34.515a2.31,2.31,0,0,0,2.309-2.309V13.485A2.31,2.31,0,0,0,34.515,11.176ZM28.743,8.867h2.309v4.618H28.743ZM13.735,16.731c1.395,0,2.526,1.486,2.526,3.319s-1.131,3.319-2.526,3.319-2.526-1.486-2.526-3.319,1.131-3.319,2.526-3.319ZM10.271,8.867H12.58v4.618H10.271ZM8.732,27.278s.274-2.2.9-2.61a8.854,8.854,0,0,1,2.413-.69S13.2,25.219,13.7,25.219s1.654-1.241,1.654-1.241a8.827,8.827,0,0,1,2.414.69c.731.487.913,2.61.913,2.61H8.732Zm23.474-1.094H21.816V25.029h10.39Zm0-2.309H21.816V22.721h10.39Zm0-2.309H21.816V20.412h10.39Zm0-2.309H21.816V18.1h10.39Z" transform="translate(-4.499 -7.712)" fill="#bebebe" />
                      </svg>
                      </span>My Profile</p>
                  </div>) : (null)}

                  {logoutItem}
                </div>
              </div>
            </li>
            <li className="nav-item py-xl-4 position-relative mob-view mobile-Menu-RightPanel">
              <span className="py-xl-4 addobiCancel" onClick={this.mobileAddC}>
                <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83">
                  <path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" fill="#fff" />
                  <path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" fill="#fff" />
                  <path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" fill="#fff" />
                </svg>
              </span>
              <span className="py-xl-4 removeCancel mob-mt-20" onClick={this.mobileRemovC}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21.013" height="21.013" viewBox="0 0 21.013 21.013">
                  <g id="cancel_1_" data-name="cancel (1)" transform="translate(0 -0.001)">
                    <g id="Group_4614" data-name="Group 4614" transform="translate(0 0.001)">
                      <path id="Path_2561" data-name="Path 2561" d="M11.667,10.507,20.772,1.4A.821.821,0,0,0,19.611.241L10.506,9.346,1.4.241A.821.821,0,1,0,.24,1.4l9.105,9.105L.24,19.612A.821.821,0,0,0,1.4,20.773l9.105-9.105,9.105,9.105a.821.821,0,1,0,1.161-1.161Z" transform="translate(0 -0.001)" fill="#fff" />
                    </g>
                  </g>
                </svg>
              </span>
            </li>
          </ul>
        </div>
        {this.state.showEditProfile ? <Profile showProfile={this.state.showEditProfile} hideProfile={this.hideEditProfile} updateProfile={this.getAgentProfile} agentStatus={this.state.agentStatus} /> : (null)}

        {/* For logout confirmation start */}
        <div className="popupShadow" style={{ display: this.state.modalVisible ? 'block' : 'none' }}></div>
        <div className="popupInstallLivechat popupLivechat login-popup" style={{ display: this.state.modalVisible ? 'block' : 'none' }}>
          <div className="popupHeader py-4 px-5 k-flex align-items-center">
            <h6 className="card-title mb-0 white-text">Confirm</h6>
            <span className="close-popup ml-auto" onClick={this.closeModal}>
              <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                  <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                  <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                </g>
              </svg>
            </span>
          </div>

          <div className="popupBody pb-5 px-5 text-center">
            <div className="py-5 feilds-instruction">
              <h6 className="card-title mb-0">Seems like you're trying to logout. Do you wish to continue?</h6>
              <h6 className="card-title mb-0">Note: Once you logout all your inactive chats will be removed.</h6>
            </div>
            <div>
              <button className="btnBlueSettings  py-2 px-7" onClick={this.logOutMe}>Yes</button>&nbsp;
              <button className="btn  py-2 px-7" onClick={this.closeModal}>No</button>
            </div>
          </div>
        </div>
        {/* For logout confirmation end */}

        {/* For logout confirmation for all product logout start */}
        <div className="popupShadow" style={{ display: this.state.logoutModel ? 'block' : 'none' }}></div>
        <div className="popupInstallLivechat popupLivechat login-popup" style={{ display: this.state.logoutModel ? 'block' : 'none' }}>
          <div className="popupHeader py-4 px-5 k-flex align-items-center">
            <h6 className="card-title mb-0 white-text">Confirm</h6>
            <span className="close-popup ml-auto" onClick={this.closeModal}>
              <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                  <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                  <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                </g>
              </svg>
            </span>
          </div>

          <div className="popupBody pb-5 px-5 text-center">
            <div className="py-5 feilds-instruction">
              <h6 className="card-title mb-0">Seems like you're trying to logout. Do you wish to continue?</h6>
              <h6 className="card-title mb-0">Note: Once you logout all your inactive chats will be removed.</h6>
            </div>
            <div>
              <button className="btnBlue  py-2 px-7" onClick={this.logoutAppypieProduct}>Yes</button>&nbsp;
              <button className="btn  py-2 px-7" onClick={this.closeModal}>No</button>
            </div>
          </div>
        </div>
        {/* For logout confirmation for all product logout */}

        {/* For Incoming Call alert start */}

        {enablePopup ? (<div id="incomingCallPopup" className="popupInstallLivechat popupLivechat callingPopup" style={{ display: 'none' }}>
          <div className="CallingPopupHeader py-5 px-5">
            <h5 id="phone_call_heading" className="card-title mb-0 white-text">Calling...</h5>
            <span className="collapse-call-popup" id="showHidePopupContent"><img alt="" id="downToggleIcon" src={require('../../assets/images/livechat/down.svg')} /></span>
            <span className="maximize-minimize" id="maxminCallPopup"><img alt="" id="minimizeIcon" src={require('../../assets/images/livechat/minimize.png')} /></span>
          </div>

          <div id="incpopupcontent" style={{ display: "block" }}>
            <div className="popupBody pt-5 px-5 text-center">
              <div className="user-caling-display my-5 py-2">
                <div className="call-animation">
                  <img className="img-circle" src={require('../../assets/images/boy.png')} alt="" />
                </div>
              </div>
              <div className="py-5 feilds-instruction">
                <h4 id="fromNumberIncCall" className="card-title mb-0">Calling...</h4>
                {/*<p className="card-body-text">Lorem Ipsum</p> */}
              </div>
            </div>

            <div className="footer-calling-popup text-center pb-5 mb-5">
              <button style={{ display: "none" }} id="acceptIncCall" className="btn-calling btn-accept">
                <span className="mr-3">
                  <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="22.94" height="22.94" viewBox="0 0 31.94 31.94">
                    <g id="Group_4586" data-name="Group 4586" transform="translate(15.97 8.517)">
                      <g id="Group_4585" data-name="Group 4585">
                        <path id="Path_2554" data-name="Path 2554" d="M241.065,128a1.065,1.065,0,0,0,0,2.129,4.263,4.263,0,0,1,4.259,4.259,1.065,1.065,0,1,0,2.129,0A6.4,6.4,0,0,0,241.065,128Z" transform="translate(-240 -128)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4588" data-name="Group 4588" transform="translate(15.97 4.259)">
                      <g id="Group_4587" data-name="Group 4587">
                        <path id="Path_2555" data-name="Path 2555" d="M241.065,64a1.065,1.065,0,1,0,0,2.129,8.526,8.526,0,0,1,8.517,8.517,1.065,1.065,0,0,0,2.129,0A10.659,10.659,0,0,0,241.065,64Z" transform="translate(-240 -64)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4590" data-name="Group 4590" transform="translate(15.97)">
                      <g id="Group_4589" data-name="Group 4589">
                        <path id="Path_2556" data-name="Path 2556" d="M241.065,0a1.065,1.065,0,0,0,0,2.129,12.789,12.789,0,0,1,12.776,12.776,1.065,1.065,0,1,0,2.129,0A14.923,14.923,0,0,0,241.065,0Z" transform="translate(-240 0)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4592" data-name="Group 4592" transform="translate(0 2.129)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>
                </span>Accept</button>
              <button style={{ display: "none" }} id="rejectIncCall" className="btn-calling btn-reject ml-3">
                <span className="mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                    <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>

                </span>Reject</button>

              <button style={{ display: "none" }} id="hangupIncCall" className="btn-calling btn-reject">
                <span className="mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                    <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>

                </span>HangUp</button>
              <button style={{ display: "none" }} id="unmuteIncCall" className="btn-calling btn-mute  ml-3">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24.467" height="28.877" viewBox="0 0 32.467 33.877">
                    <g id="Group_4599" data-name="Group 4599" transform="translate(13554 -336)">
                      <g id="no-sound" transform="translate(-13554 336)">
                        <g id="Group_4594" data-name="Group 4594" transform="translate(9.882 0.001)">
                          <g id="Group_4593" data-name="Group 4593">
                            <path id="Path_2558" data-name="Path 2558" d="M167.772.008h-2.823a4.946,4.946,0,0,0-4.941,4.941V19.063a4.873,4.873,0,0,0,.185,1.266L172.712,7.241V4.949A4.946,4.946,0,0,0,167.772.008Z" transform="translate(-160.008 -0.008)" fill="#fff" />
                          </g>
                        </g>
                        <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                          <g id="Group_4595" data-name="Group 4595">
                            <path id="Path_2559" data-name="Path 2559" d="M25.482,24H28.3a4.946,4.946,0,0,0,4.941-4.941V11.318L42.93,1.193A.706.706,0,0,0,41.91.217L10.855,32.684a.706.706,0,0,0,1.021.975l8.066-8.433a6.257,6.257,0,0,0,4.129,1.592h2.117v5.646H21.247a.706.706,0,1,0,0,1.412H32.54a.706.706,0,1,0,0-1.412H27.6V26.819h2.117a6.36,6.36,0,0,0,6.352-6.352V13.409a.706.706,0,0,0-1.412,0v7.058a4.946,4.946,0,0,1-4.941,4.941H24.07a4.844,4.844,0,0,1-3.158-1.2l1.379-1.441A4.843,4.843,0,0,0,25.482,24Z" transform="translate(-10.659 0)" fill="#fff" />
                          </g>
                        </g>
                        <g id="Group_4598" data-name="Group 4598" transform="translate(7.059 12.704)">
                          <g id="Group_4597" data-name="Group 4597">
                            <path id="Path_2560" data-name="Path 2560" d="M118.753,199.751v-7.058a.706.706,0,1,0-1.412,0v7.058a6.31,6.31,0,0,0,.5,2.475l1.11-1.16A4.869,4.869,0,0,1,118.753,199.751Z" transform="translate(-117.341 -191.987)" fill="#fff" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span></button>
              <button style={{ display: "none" }} id="muteIncCall" className="btn-calling btn-mute  ml-3">
                <span><img alt="Mute" src={require('../../assets/images/umute.svg')} /></span>
              </button>
            </div>
          </div>
        </div>) : (null)}
        {/* For Incoming Call alert end */}
      </>
    );
  }
}

export default SidebarNavigation