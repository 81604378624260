import React, { PureComponent } from 'react';
import { DESK_API_URL, commonLoginApi, REACT_APP_REALM_API_ID, REACT_APP_REALM_API_KEY } from '../../config/siteConfig';
import { toast } from "react-toastify";
// import db from "../../config/firebase";
import LeftPanel from '../settings/views/LeftPanel';
import * as Realm from "realm-web";

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../firebase';
/* For Dropdown End */

const realApp = new Realm.App({ id: REACT_APP_REALM_API_ID });
class Ticketing extends React.Component {
    constructor(props) {
        super(props);
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.deskPaymentLink = '';
        this.state = {
            ticketingEnabled: false,
            deskUserId: '',
            deskOrgId: '',
            deskPaymentStatus: false,
            isAutoTicketing: true,
            loader: true,
            showDeskBuyPopup: false,
            statusIDs: [],
            deskAgents: [],
            deskTeams: [],
            filteredDeskAgents: [],
            filteredDeskTeams: [],
            showAssignedToMenu: false,
            isAgentTab: true,
            priorityStatus: 'low',
            subjectTicket: 'Conversation with Client',
            descriptionTicket: 'Please refer to the chat transcript for more information.',
            statusTicket: '',
            assignedTo: '',
            assignedToName: '',
            assignedToEntity: '',
            assignedToStatus: '',
            assignedToRoleType: '',
            assignedToAgentData: '',
            subjectTicket_error: '',
            assignedTo_error: '',
            priorityStatus_error: '',
            statusTicket_error: '',
            descriptionTicket_error: '',
            ticketingEnabled_error: 'Please enable ticketing through desk switch to edit settings..!',
            allAgentsRealm: [],
        };
    }

    componentDidMount = () => {
        if (this.agent.roleType === 'admin' || this.agent.roleType === 'Owner') {
            this.getUserId();
            this.getAgentListFromCommonLoginWithRealm();
        }
        else
            window.location.href = "/";
    }

    getAgentListFromCommonLoginWithRealm = async () => {
        const credentials = Realm.Credentials.apiKey(REACT_APP_REALM_API_KEY);
        var realmAgent = realApp.currentUser
        if (!realmAgent) {
            realmAgent = await realApp.logIn(credentials);
        }
        const limit = 500;
        const page = 1;
        let allAgentsRealm = await realmAgent.functions.getData(this.agent.organization_alias, limit, page);
        return this.setState({ allAgentsRealm: allAgentsRealm });
    }

    getUserId = () => {

        fetch(commonLoginApi + "api/common/getcompanynew", { // API to get desk userID from commonlogin kdapi
            method: "post",
            body: {email:this.agent.ownerEmail,user:localStorage.alias},
            headers: {
                "Authorization": "Bearer YXBwbGljYXRpb246c2VjcmV0",
                "Content-Type": "application/x-www-form-urlencoded",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                
                if (parseInt(response.status) === 200) {
                    let userId = '';
                    response.userdata.apps_access.forEach((apps) => {
                        if (apps.product === 'helpdesk') {
                            userId = apps.user_id;
                        }
                    })
                    this.setState({ deskUserId: userId });
                    return userId;
                }
                else {
                    this.setState({ deskUserId: '', deskOrgId: '' });
                    return '';
                }
            }).then((userId) => { // API to check desk payment status
                this.checkDeskPaymentStatus(userId);
            }).catch(error => console.log('error', error));
    }

    checkDeskPaymentStatus = (userId) => {
        fetch(DESK_API_URL + "/openTicketApi/api/isPaymentDone", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Origin": window.location.origin
            },
            body: JSON.stringify({
                "user_id": userId
            }),
        }).then(res => res.json())
            .then(response => {
                if (response.data[0] === undefined) { // if data is empty, payment is incomplete
                    this.deskPaymentLink = `https://${this.agent.organization_alias}.desk.appypie.com/ticket/loader-page?plantype=Premium`;
                    this.setState({ deskPaymentStatus: false });
                    return false;
                } else if (response.data[0].checkoutUrl) { // if checkoutUrl is received, payment is incomplete
                    this.deskPaymentLink = response.data[0].checkoutUrl;
                    this.setState({ deskPaymentStatus: false });
                    return false;
                }
                else if (response.data[0].payment_detail.payment_status === 'complete') {
                    this.setState({ deskPaymentStatus: true });
                    return true;
                }
            }).then(res => {
                if (res) { // if desk payment status is true check position of switch from DB
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
                        if (user.exists) {
                            let ticketingEnabled = user.data().ticketingEnabled ? user.data().ticketingEnabled : false;
                            this.setState({ ticketingEnabled: ticketingEnabled },
                                () => this.getStatusIds());
                        }
                    }).catch(err => {
                        console.log('Error while getting data of user from DB-->', err);
                        this.setState({ loader: false });
                    })
                } else { // if desk payment status is false, set switch to OFF
                    this.setState({ ticketingEnabled: false, loader: false });
                }
            }).catch(error => { console.log('error', error); this.setState({ loader: false }); });
    }

    getStatusIds = async () => {
        var email = 'noreply@appypie.com'; // TODO: default email id
        var maxPrevTicket = 1;
        let body = JSON.stringify({
            "count": maxPrevTicket,
            "email": email ? email : 'noreply@appypie.com',
            "user_id": this.state.deskUserId // todo static
        });
        fetch(DESK_API_URL + `/openTicketApi/api/getPreviousTicket`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Origin": window.location.origin
            },
            body: body
        }).then(res => res.json())
            .then((response) => {
                if (response.success) {
                    this.setState({
                        statusIDs: response.results.status_id,
                        deskAgents: response.results.agents,
                        deskTeams: response.results.teams,
                        filteredDeskAgents: response.results.agents,
                        filteredDeskTeams: response.results.teams,
                        statusTicket: response.results.status_id[0].id,
                        deskOrgId: response.results.org_id,
                    }, () => {
                        this.setdefaultValues();
                    });
                }
                else {
                    this.setState({ loader: false });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({ loader: false });
            });

    }

    setdefaultValues = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
            if (user.exists) {
                if (user.data().ticketingEnabled !== undefined)
                    return user.data().ticketingSettings;
                else
                    return false
            } else {
                return false
            }
        }).then((ticketingSettings) => {
            if (ticketingSettings === false || ticketingSettings === undefined) {
                this.setState({
                    subjectTicket: 'Conversation with Client',
                    descriptionTicket: '',
                    assignedTo: '',
                    assignedToName: '',
                    assignedToEntity: '',
                    assignedToStatus: '',
                    assignedToRoleType: '',
                    assignedToAgentData: '',
                    statusTicket: '',
                    priorityStatus: '',
                    isAutoTicketing: true,
                    loader: false
                })
            } else {
                this.setState({
                    subjectTicket: ticketingSettings.subjectTicket,
                    assignedTo: ticketingSettings.assignedTo,
                    assignedToName: ticketingSettings.assignedToName,
                    assignedToEntity: ticketingSettings.assignedToEntity,
                    assignedToStatus: ticketingSettings.assignedToStatus,
                    assignedToRoleType: ticketingSettings.assignedToRoleType,
                    assignedToAgentData: ticketingSettings.assignedToAgentData,
                    statusTicket: ticketingSettings.statusTicket,
                    priorityStatus: ticketingSettings.priorityStatus,
                    descriptionTicket: ticketingSettings.descriptionTicket,
                    isAutoTicketing: ticketingSettings.isAutoTicketing,
                    loader: false
                })
            }
        }).catch((err) => { console.log("Error while getting old settings-->", err); this.setState({ loader: false }); })
    }

    handleClick = (e) => {
        this.setState({ loader: true });
        if (this.state.deskPaymentStatus) {
            this.setState({ ticketingEnabled: e.target.checked }, () => {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
                    if (user.exists) {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).update({
                            ticketingEnabled: this.state.ticketingEnabled
                        })
                        if (this.state.ticketingEnabled) {
                            this.setState({ loader: false });
                            toast.success("Ticketing enabled successfully!");
                        }
                        else {
                            this.setState({ loader: false });
                            toast.success("Ticketing disabled successfully!");
                        }
                    }
                }).catch(error => { console.log('error', error); toast.error("Something went wrong! Please try again after some time"); this.setState({ loader: false }); });
            });
        }
        else {
            this.setState({ showDeskBuyPopup: true, loader: false });
            toast.error("Please purchase Appy Pie Desk..!");
        }
    }

    goToCheckoutPage = () => {
        window.open(this.deskPaymentLink, '_self');
        this.setState({ ticketingEnabled: false });
        firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
            if (user.exists) {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).update({
                    ticketingEnabled: false
                })
            }
        }).catch(error => console.log('error', error));
    }

    addAssignedTo = () => {
        this.setState((prevState) => ({ showAssignedToMenu: !prevState.showAssignedToMenu }));
    }

    handleChange = (e) => {
        let trgtName = e.target.name ? e.target.name : "";
        if (!trgtName) {
            return false;
        }
        let trgtValue = e.target.value ? e.target.value : "";

        this.setState({ [trgtName]: trgtValue }, () => {
            if (trgtName === "priorityStatus") {
                let priorityList = ['low', 'medium', 'high', 'urgent'];
                priorityList.forEach((priority) => {
                    let priorityDiv = document.getElementById(`priorityStatus_${priority}`);
                    priorityDiv.style.background = "#ffffff";
                });
                let priorityDiv = document.getElementById(`priorityStatus_${trgtValue}`);
                priorityDiv.style.background = "#DFF0FD";
            }
        });

    }

    filterAgents = (e) => {
        if (e.target.value) {
            let filteredDeskAgents = this.state.deskAgents.filter(agent => agent.firstname.toLowerCase().includes(e.target.value.toLowerCase()) || agent.lastname.toLowerCase().includes(e.target.value.toLowerCase()));
            this.setState({ filteredDeskAgents: filteredDeskAgents });
        } else {
            this.setState(prevState => ({ filteredDeskAgents: prevState.deskAgents }));
        }
    }

    filterTeams = (e) => {
        if (e.target.value) {
            let filteredDeskTeams = this.state.deskTeams.filter(team => team.name.toLowerCase().includes(e.target.value.toLowerCase()));
            this.setState({ filteredDeskTeams: filteredDeskTeams });
        } else {
            this.setState(prevState => ({ filteredDeskTeams: prevState.deskTeams }));
        }
    }

    validateForm = (e) => {
        let returnValue = true;
        if (this.state.subjectTicket.trim() === '') {
            returnValue = false;
            this.setState({
                subjectTicket_error: 'This field can not be blank!'
            })
        }
        // if (this.state.assignedTo.trim() === '') {
        //     returnValue = false;
        //     this.setState({
        //         assignedTo_error: 'Please select Assigned To!'
        //     })
        // }
        if (this.state.priorityStatus === '') {
            returnValue = false;
            this.setState({
                priorityStatus_error: 'Please select Priority!'
            })
        }
        if (this.state.statusTicket === '') {
            returnValue = false;
            this.setState({
                statusTicket_error: 'Please select Status!'
            })
        }
        if (this.state.descriptionTicket.trim() === '') {
            returnValue = false;
            this.setState({
                descriptionTicket_error: 'This field can not be blank!'
            })
        }
        return returnValue;
    }

    saveSettings = async () => {

        var validate = await this.validateForm();
        if (!validate && this.state.isAutoTicketing) {
            return false;
        }
        else {
            this.setState({ loader: true });
            let object = {
                subjectTicket: this.state.subjectTicket,
                assignedTo: this.state.assignedTo,
                assignedToEntity: this.state.assignedToEntity ? this.state.assignedToEntity : 'O',
                assignedToName: this.state.assignedToName,
                assignedToStatus: this.state.assignedToStatus,
                assignedToRoleType: this.state.assignedToRoleType,
                assignedToAgentData: this.state.assignedToAgentData.length > 0 ? this.state.assignedToAgentData : [],
                statusTicket: this.state.statusTicket,
                priorityStatus: this.state.priorityStatus,
                descriptionTicket: this.state.descriptionTicket,
                isAutoTicketing: this.state.isAutoTicketing,
                deskUserId: this.state.deskUserId,
                deskOrgId: this.state.deskOrgId,
            }
            firebaseServices.db.collection('users').doc(this.agent.ownerId).update({
                ticketingSettings: object
            }).then(() => {
                this.setState({ loader: false });
                toast.success("Settings saved successfully!");
            }).catch(err => {
                this.setState({ loader: false });
                toast.error("Something went wrong! Please try again after some time");
            });
        }
    }

    render() {
        return (<>
            <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text mr-auto">Settings</h3>
                <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className='ticket-section my-3 '>
                                <div className='pb-3 pt-1'>
                                    <h3 className="heding-text">Ticketing through Appy Pie Desk</h3>
                                </div>
                                <div className='ticketing-cont'>
                                    <div className='d-flex mb-5'>
                                        <label htmlFor="ticketingEnabled"><h6 className='pr-5'>Enable ticketing through Desk</h6></label>
                                        {/* <input id="ticketingEnabled" name="ticketingEnabled" checked={this.state.ticketingEnabled} onChange={this.handleChange} type="checkbox" /> */}
                                        <div className="material-switch pull-right mt-2 pl-5 mr-4">
                                            <input id="ticketingEnabled" type="checkbox" name="ticketingEnabled" onClick={this.handleClick} checked={this.state.ticketingEnabled} onChange={e => { }} />
                                            <label htmlFor="ticketingEnabled" className="label-default" />
                                        </div>
                                    </div>
                                    <div className='top-sec d-flex align-items-center flex-wrap mb-5'>
                                        <div>
                                            <label className='pl-0' htmlFor="create_tickets"><h5 >Create Tickets</h5></label>
                                        </div>
                                        <div>
                                            <input type="radio" id="auto" name="create_tickets" defaultValue="auto" checked={this.state.isAutoTicketing} onClick={() => { this.setState({ isAutoTicketing: true }) }} onChange={e => { }} disabled={!this.state.ticketingEnabled} />
                                            <label htmlFor="auto">Automatically once the chat ends</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="manual" name="create_tickets" defaultValue="manual" checked={!this.state.isAutoTicketing} onClick={() => { this.setState({ isAutoTicketing: false }) }} onChange={e => { }} disabled={!this.state.ticketingEnabled} />
                                            <label htmlFor="manual">Manually by the agent</label>
                                        </div>
                                    </div>
                                    {this.state.isAutoTicketing ? (<>
                                        <div className='ticket-subject'>
                                            {/* <label htmlFor="subjectTicket">
                                                <h5>Subject</h5>
                                            </label> */}
                                            <div className='input-group material-input mb-5'>
                                                <TextField className="form-control w-150" id="subjectTicket" name="subjectTicket" label="Subject" placeholder="Type Subject..." variant="outlined" onChange={this.handleChange} value={this.state.subjectTicket} disabled={!this.state.ticketingEnabled} />
                                            </div>
                                            {/* <input type="text" id="subjectTicket" name="subjectTicket" placeholder="Type Subject..." onChange={this.handleChange} value={this.state.subjectTicket} disabled={!this.state.ticketingEnabled} /> */}
                                            {this.state.subjectTicket_error ? (<p className="error">{this.state.subjectTicket_error}</p>) : (null)}
                                        </div>
                                        <div className='ticket-row border-bottom pb-4 mb-4 mt-4 ps-relative'>
                                            <label htmlFor="assigned_to"><h5>Assigned To</h5></label>
                                            {this.state.assignedTo === '' ?
                                                (<>
                                                    <button className='link-btn' type="button" id="assigned_to" onClick={this.addAssignedTo} disabled={!this.state.ticketingEnabled}>+ Add New</button>
                                                    {this.state.assignedTo_error ? (<p className="error">{this.state.assignedTo_error}</p>) : (null)}
                                                    {/* Start: Assigned to dropdown */}
                                                    {this.state.showAssignedToMenu ?
                                                        (<div className="navbarDropdown dropdown-menu w-300 dropdown-menu-right top60">
                                                            <div className="agentTeamTab mx-3 mb-2">
                                                                <span className={this.state.isAgentTab ? "active" : ""} onClick={() => this.setState({ isAgentTab: true })}>Agent</span><span> | </span><span className={this.state.isAgentTab ? "" : "active"} onClick={() => this.setState({ isAgentTab: false })}>Team</span>
                                                            </div>
                                                            {this.state.isAgentTab ?
                                                                (<>
                                                                    <div className="departmentSearch pl-3 pr-3">
                                                                        <div>
                                                                            <div className="md-form md-form-custom ps-relative ">
                                                                                <span className="searchIcon-dep cursor-pointer">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                        <path id="ic_zoom_out_24px"
                                                                                            d="M13.006,11.805h-.632l-.224-.216a5.211,5.211,0,1,0-.56.56l.216.224v.632l4,3.994L17,15.807Zm-4.8,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,8.2,11.805Z"
                                                                                            transform="translate(-3 -3)" fill="#64b5f6"></path>
                                                                                    </svg>
                                                                                </span>
                                                                                <input type="text" className="form-control" autoComplete="off" placeholder="Search Agent" onChange={this.filterAgents} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="department-acc pb-2 scrollbar department-acc-height">
                                                                        {this.state.filteredDeskAgents.map((deskAgent) => (<div key={deskAgent.user_id} className="partment-row pl-3 pr-3" onClick={() => {
                                                                            let assignedToAgentData = this.state.allAgentsRealm.filter(agent => agent.preferred_username === deskAgent.user_id);
                                                                            assignedToAgentData = assignedToAgentData.length > 0 ? assignedToAgentData : [{
                                                                                "multiple_session": false,
                                                                                "email_verified": true,
                                                                                "user_type": "Owner",
                                                                                "first_name": this.agent.first_name,
                                                                                "product_name": "livechat",
                                                                                "last_name": this.agent.last_name,
                                                                                "imageurl": "https://userappypie.s3.amazonaws.com",
                                                                                "phone_number": this.agent.phone_number,
                                                                                "pic": this.agent.profile_img,
                                                                                "thumbnail": this.agent.profile_img,
                                                                                "company_name": this.agent.company_name,
                                                                                "company_identifier": this.agent.organization_alias,
                                                                                "email": this.agent.ownerEmail,
                                                                                "alias": this.agent.alias,
                                                                                "subid": this.agent.agentId,
                                                                                "preferred_username": this.agent.agentId,
                                                                                "added_date": this.agent.addedon,
                                                                                "update_date": this.agent.updatedon,
                                                                                "timezone": this.agent.timezone,
                                                                                "agent_id": 733,
                                                                                "__v": 0
                                                                            }];
                                                                            this.setState({ assignedTo: deskAgent.user_id, assignedToName: deskAgent.firstname + ' ' + deskAgent.lastname, assignedToEntity: 'U', assignedToStatus: deskAgent.status, assignedToRoleType: deskAgent.role_type, assignedToAgentData: assignedToAgentData }, () => this.addAssignedTo())
                                                                        }}>
                                                                            <div className="d-flex align-items-center cursor-pointer">
                                                                                <img src="data:image/svg+xml,%3Csvg id='Group_1955' data-name='Group 1955' xmlns='http://www.w3.org/2000/svg' width='42.658' height='42.66' viewBox='0 0 42.658 42.66'%3E %3Cpath id='Path_1761' data-name='Path 1761' d='M16.823,35.006l-6.364,3.472a5.719,5.719,0,0,0-1.021.727,21.316,21.316,0,0,0,27.388.071,5.646,5.646,0,0,0-1.122-.752l-6.815-3.407a2.6,2.6,0,0,1-1.437-2.325V30.118a10.292,10.292,0,0,0,.645-.829,15.681,15.681,0,0,0,2.119-4.269,2.141,2.141,0,0,0,1.518-2.036V20.129a2.131,2.131,0,0,0-.713-1.582V14.422S31.867,8,23.172,8s-7.848,6.421-7.848,6.421v4.126a2.128,2.128,0,0,0-.713,1.582v2.854a2.14,2.14,0,0,0,.987,1.8,14.164,14.164,0,0,0,2.581,5.339v2.608A2.6,2.6,0,0,1,16.823,35.006Z' transform='translate(-1.841 -1.561)' fill='%23e7eced' /%3E %3Cg id='Group_1917' data-name='Group 1917' transform='translate(0 0)'%3E %3Cpath id='Path_1762' data-name='Path 1762' d='M21.695,0A21.316,21.316,0,0,0,7.6,37.637a5.668,5.668,0,0,1,1.012-.72l6.364-3.472a2.6,2.6,0,0,0,1.355-2.282V28.556a14.15,14.15,0,0,1-2.581-5.339,2.141,2.141,0,0,1-.987-1.8V18.567a2.131,2.131,0,0,1,.713-1.582V12.86s-.848-6.421,7.848-6.421,7.848,6.421,7.848,6.421v4.126a2.128,2.128,0,0,1,.713,1.582v2.854a2.141,2.141,0,0,1-1.518,2.036,15.681,15.681,0,0,1-2.118,4.269,10.294,10.294,0,0,1-.645.829V31.23a2.6,2.6,0,0,0,1.437,2.325l6.815,3.407a5.675,5.675,0,0,1,1.119.75A21.325,21.325,0,0,0,21.695,0Z' transform='translate(0 0)' fill='%23556080' /%3E %3C/g%3E %3C/svg%3E" alt="" />
                                                                                &nbsp;&nbsp;<div className="fnt-13 clr-grey">{deskAgent.firstname} {deskAgent.lastname}</div>
                                                                            </div>
                                                                        </div>))}
                                                                    </div></>
                                                                ) : (
                                                                    <>
                                                                        <div className="departmentSearch pl-3 pr-3">
                                                                            <div>
                                                                                <div className="md-form md-form-custom ps-relative ">
                                                                                    <span className="searchIcon-dep cursor-pointer">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                            <path id="ic_zoom_out_24px"
                                                                                                d="M13.006,11.805h-.632l-.224-.216a5.211,5.211,0,1,0-.56.56l.216.224v.632l4,3.994L17,15.807Zm-4.8,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,8.2,11.805Z"
                                                                                                transform="translate(-3 -3)" fill="#64b5f6"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <input type="text" className="form-control" autoComplete="off" placeholder="Search Team" onChange={this.filterTeams} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="department-acc pb-2 scrollbar department-acc-height">
                                                                            {this.state.filteredDeskTeams.map((deskTeam) => (<div key={deskTeam.team_id} className="partment-row pl-3 pr-3" onClick={() => { this.setState({ assignedTo: deskTeam.team_id, assignedToName: deskTeam.name, assignedToEntity: 'T', assignedToStatus: deskTeam.status }, () => this.addAssignedTo()) }}>
                                                                                <div className="d-flex align-items-center cursor-pointer">
                                                                                    <img src="data:image/svg+xml,%3Csvg id='Group_1955' data-name='Group 1955' xmlns='http://www.w3.org/2000/svg' width='42.658' height='42.66' viewBox='0 0 42.658 42.66'%3E %3Cpath id='Path_1761' data-name='Path 1761' d='M16.823,35.006l-6.364,3.472a5.719,5.719,0,0,0-1.021.727,21.316,21.316,0,0,0,27.388.071,5.646,5.646,0,0,0-1.122-.752l-6.815-3.407a2.6,2.6,0,0,1-1.437-2.325V30.118a10.292,10.292,0,0,0,.645-.829,15.681,15.681,0,0,0,2.119-4.269,2.141,2.141,0,0,0,1.518-2.036V20.129a2.131,2.131,0,0,0-.713-1.582V14.422S31.867,8,23.172,8s-7.848,6.421-7.848,6.421v4.126a2.128,2.128,0,0,0-.713,1.582v2.854a2.14,2.14,0,0,0,.987,1.8,14.164,14.164,0,0,0,2.581,5.339v2.608A2.6,2.6,0,0,1,16.823,35.006Z' transform='translate(-1.841 -1.561)' fill='%23e7eced' /%3E %3Cg id='Group_1917' data-name='Group 1917' transform='translate(0 0)'%3E %3Cpath id='Path_1762' data-name='Path 1762' d='M21.695,0A21.316,21.316,0,0,0,7.6,37.637a5.668,5.668,0,0,1,1.012-.72l6.364-3.472a2.6,2.6,0,0,0,1.355-2.282V28.556a14.15,14.15,0,0,1-2.581-5.339,2.141,2.141,0,0,1-.987-1.8V18.567a2.131,2.131,0,0,1,.713-1.582V12.86s-.848-6.421,7.848-6.421,7.848,6.421,7.848,6.421v4.126a2.128,2.128,0,0,1,.713,1.582v2.854a2.141,2.141,0,0,1-1.518,2.036,15.681,15.681,0,0,1-2.118,4.269,10.294,10.294,0,0,1-.645.829V31.23a2.6,2.6,0,0,0,1.437,2.325l6.815,3.407a5.675,5.675,0,0,1,1.119.75A21.325,21.325,0,0,0,21.695,0Z' transform='translate(0 0)' fill='%23556080' /%3E %3C/g%3E %3C/svg%3E" alt="" />
                                                                                    &nbsp;&nbsp;<div className="fnt-13 clr-grey">{deskTeam.name}</div>
                                                                                </div>
                                                                            </div>))}
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>) : (null)}
                                                    {/* End: Assigned to dropdown */}
                                                </>) : (
                                                    <span className='link-btn assigned' type="button" id="assignedTicket">{this.state.assignedToName}
                                                        <span className="ml-3 cursor-pointer" onClick={() => { this.setState({ assignedTo: '', assignedToName: '', assignedToEntity: '', assignedToStatus: '', assignedToRoleType: '', assignedToAgentData: '' }) }}><svg xmlns="http://www.w3.org/2000/svg" width="9.5" height="9.5" viewBox="0 0 9.5 9.5"><path id="ic_clear_24px" d="M14.5,5.957,13.543,5,9.75,8.793,5.957,5,5,5.957,8.793,9.75,5,13.543l.957.957L9.75,10.707,13.543,14.5l.957-.957L10.707,9.75Z" transform="translate(-5 -5)" fill="#fff"></path></svg></span>
                                                    </span>
                                                )}
                                        </div>
                                        <div className='ticket-row mb-4'>
                                            <label htmlFor="statusTicket"><h5>Status</h5></label>
                                            <select name="statusTicket" id="statusTicket" onChange={this.handleChange} value={this.state.statusTicket} disabled={!this.state.ticketingEnabled}>
                                                {this.state.statusIDs.map((status) => (<option key={status.id} value={status.id} checked={status.id === this.state.statusTicket}>{status.status_name}</option>))}
                                            </select>
                                            {this.state.statusTicket_error ? (<p className="error">{this.state.statusTicket_error}</p>) : (null)}
                                        </div>
                                        <div className='ticket-row mt-5'>
                                            <h5>Priority</h5>
                                            <div className='priority-view mt-4'>
                                                <div className='pri-btn' id="priorityStatus_low" style={{ background: this.state.priorityStatus === 'low' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="low" name="priorityStatus" defaultValue="low" onClick={this.handleChange} disabled={!this.state.ticketingEnabled} />
                                                    <label htmlFor="low">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#ccc" />
                                                        </svg>
                                                        Low</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_medium" style={{ background: this.state.priorityStatus === 'medium' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="medium" name="priorityStatus" defaultValue="medium" onClick={this.handleChange} disabled={!this.state.ticketingEnabled} />
                                                    <label htmlFor="medium">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 2 25 20">
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#95e452" />
                                                        </svg>
                                                        Medium</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_high" style={{ background: this.state.priorityStatus === 'high' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="high" name="priorityStatus" defaultValue="high" onClick={this.handleChange} disabled={!this.state.ticketingEnabled} />
                                                    <label htmlFor="high">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 -5 12 24" style={{ transform: 'rotate(180deg)' }}>
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#e68484" />
                                                        </svg>
                                                        High</label>
                                                </div>
                                                <div className='pri-btn' id="priorityStatus_urgent" style={{ background: this.state.priorityStatus === 'urgent' ? "#DFF0FD" : "#ffffff" }}>
                                                    <input type="radio" id="urgent" name="priorityStatus" defaultValue="urgent" onClick={this.handleChange} disabled={!this.state.ticketingEnabled} />
                                                    <label htmlFor="urgent">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.87" height="15" viewBox="0 -5 12 24" style={{ transform: 'rotate(180deg)' }}>
                                                            <path id="right-arrow" d="M11.243.222a.75.75,0,0,0-1.066,1.055l5.4,5.4H.746A.743.743,0,0,0,0,7.429a.751.751,0,0,0,.746.757H15.582l-5.4,5.395a.765.765,0,0,0,0,1.066.747.747,0,0,0,1.066,0l6.684-6.684a.734.734,0,0,0,0-1.055Z" transform="translate(14.87 0) rotate(90)" fill="#f00" />
                                                        </svg>
                                                        Urgent</label>
                                                </div>
                                                {this.state.priorityStatus_error ? (<p className="error">{this.state.priorityStatus_error}</p>) : (null)}
                                            </div>
                                        </div>
                                        <div className='ticket-row mt-5 description'>
                                            <label className='mb-0' htmlFor="descriptionTicket"><h5 >Description</h5></label>
                                            <span className='small-font'>The chat transcript will be included in the ticket description, you can add additional info.</span>
                                            <textarea id="descriptionTicket" name="descriptionTicket" rows={4} cols={38} placeholder="Type Description..." onChange={this.handleChange} value={this.state.descriptionTicket} disabled={!this.state.ticketingEnabled} />
                                            {this.state.descriptionTicket_error ? (<p className="error">{this.state.descriptionTicket_error}</p>) : (null)}
                                        </div>
                                    </>) : (null)}
                                    <div className='ticket-row mt-5'>
                                        <input type="submit" value="Save Settings" onClick={this.saveSettings} disabled={!this.state.ticketingEnabled} />
                                    </div>
                                    {!this.state.ticketingEnabled ? (<p className="error">{this.state.ticketingEnabled_error}</p>) : (null)}
                                </div>
                            </div>


                            {this.state.showDeskBuyPopup ? (<div className="trialPopup fixed">
                                <div className="trialPopupBody">
                                    <span className="closeBtn" onClick={() => { this.setState({ showDeskBuyPopup: false }) }}></span>
                                    <img src={require("../../assets/images/free_trial.svg")} alt="Buy Appy Pie Desk" />
                                    <h4 className="mb-3">You have not purchased Appy Pie Desk!</h4>
                                    <p className="mb-3">No Problem! Please click the button below to purchase...</p>
                                    <button className="btnBlueSettings" onClick={this.goToCheckoutPage}>Purchase Appy Pie Desk</button>
                                </div>
                            </div>) : (null)}
                        </div>
                    </div>
                </div>
            </div>
        </>);

    }
}

export default Ticketing;