/**
 * @class AgentResetPassword  
 * @description Component Class For Agent Varifyfication Process  
 * @version 1.0
 * @author Bineet kumar Chaubey<bineet@appypiellp.com>
 * @file AgentResetPassword.js
 * 
 */
import React, { Component } from "react";
import RawTooltip from "../../components/Tooltip/rawtooltip";
import TextField from "@material-ui/core/TextField";
//import { forgetPassword, resetPassword, validation } from "../../config/SignupLogin";
import data from "../../localization/login-registration.json";
import { checkIsUserOrOwnerLogin } from "../organization/OnboardHelper";
//import { mailUrl } from "../../config/mailFunctions.js";
import { DOMAIN_NAME, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
import { NotificationContainer, NotificationManager } from "react-notifications";
// import db from '../../config/firebase';
import {
    checkVerificationCodeWithDB,
    sendAgentPasswordResetCode,
    getDynamicUrl
} from "../organization/Model/DbModel";

import { sendAgentResetCode, resetPasswordAws, getOwnerDetails, sendOwnerResetCode, resetPasswordOwner, getUserDetailsFromEmail } from "../../services/service";
import firebaseServices from "../../firebase";

const publicIp = require("public-ip");


class AgentResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailError: "",
            verificationcode: "",
            verificationcodeError: "",
            newPassword: "",
            newRePassword: "",
            loader: false,
            showNewPasswordForm: false,
            newPasswordError: "",
            newRePasswordError: "",
            IPv4: "",
            agent_org_key: "",
            org_alias: '',
            company_name: '',
            upperCaseVerify: false,
            lowerCaseVerify: false,
            numericVerify: false,
            lengthVerify: false,
            specialCharacterVerify: false,
            startedTyping: false,
            emailType: 'Owner'
        };
    }

    /**
     * check if login or not
     */
    componentDidMount = async () => {
        this.setState({ loader: true });
        await checkIsUserOrOwnerLogin();

        // this.setState({ loader: false });
        publicIp.v4().then((ip4) => {
            this.setState({
                IPv4: ip4
            })
        });
        this.verifyClientOrganization();
    }

    /**
     * verify if organization exist or not
     */
    verifyClientOrganization = () => {
        this.setState({
            loader: true
        });
        ///// Check owner identiire exist or not ///////
        let ownerIdentifire = this.props.match.params.organization_name ? this.props.match.params.organization_name : "";
        ownerIdentifire = ownerIdentifire.trim();
        // this.setState()
        firebaseServices.db.collection("users").where("organization_alias", "==", ownerIdentifire).limit(1).get().then(users => {
            if (users.size === 0) {
                window.location.href = "/login";
                return false;
            } else {

                const idKey = users.docs[0].id;
                const company_name = users.docs[0]?.data()?.company_name

                this.setState({ agent_org_key: idKey, loader: false, org_alias: ownerIdentifire, company_name: company_name })
            }
        });
    }


    validateEmail = email => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    validatePassword = password => {
        this.setState({
            upperCaseVerify: false,
            lowerCaseVerify: false,
            numericVerify: false,
            lengthVerify: false,
            specialCharacterVerify: false,
            allVerify: false
        })

        var re = /^[A-Za-z\d@$#!%*?&]{8,16}$/;
        var re1 = /[a-z]/;
        var re2 = /[A-Z]/;
        var re3 = /\d/;
        var re4 = /[@$#!%*?&]/;
        if (re.test(password)) {
            this.setState({
                lengthVerify: true
            })
        }
        if (re1.test(password)) {
            this.setState({
                lowerCaseVerify: true
            })
        }
        if (re2.test(password)) {
            this.setState({
                upperCaseVerify: true
            })
        }
        if (re3.test(password)) {
            this.setState({
                numericVerify: true
            })
        }
        if (re4.test(password)) {
            this.setState({
                specialCharacterVerify: true
            })
        }
        if (re1.test(password) && re2.test(password) && re3.test(password) && re4.test(password)) {
            this.setState({
                allVerify: true
            })
        }

        if (re1.test(password) && re2.test(password) && re3.test(password) && re4.test(password) && re.test(password)) {
            return true;
        } else {
            return false;
        }
    };
    handleEmail = e => {
        if (!this.validateEmail(e.target.value)) {
            this.setState({
                emailError: 'Enter valid email id',
                email: e.target.value
            });
        } else {
            if (e.key === "Enter") {
                this.checkedVerificationCode()
            }
            this.setState({
                email: e.target.value,
                emailError: ""
            });
        }
    };

    /**
     * Handle password Filed 
     */
    handlePassword = e => {
        this.setState({
            startedTyping: true
        })
        if (!this.validatePassword(e.target.value)) {
            this.setState({
                newPasswordError: 'Password not in accordance to prescribed format',
                newPassword: e.target.value
            });
        } else {
            this.setState({
                newPassword: e.target.value,
                newPasswordError: ""
            });
        }
    };

    /**
     * Handle Re-type Password 
     */
    handleRePassword = e => {
        const {
            newPassword
        } = this.state
        const newRePassword = e.target.value
        if (newRePassword !== newPassword) {
            this.setState({
                newRePasswordError: "Entered passwords don't match",
                newRePassword: e.target.value
            })
            return
        }

        this.setState({
            newRePassword: e.target.value,
            newRePasswordError: ""
        });
    }

    /**
     * handle varification code filed
     */
    handleVarificationCode = e => {
        this.setState({
            verificationcode: e.target.value,
            verificationcodeError: ""
        });
    };

    /**
      * @description Handle to valifation code varification   and new password 
      *  then new password and varification code  to update password 
      */
    checkedVerificationCode = async () => {
        this.setState({ loader: true });
        const {
            email,
            verificationcode,
            agent_org_key,
            org_alias
        } = this.state;

        if (this.validateVerificationForm()) {
            const checkOtpDat = await checkVerificationCodeWithDB(agent_org_key, email, verificationcode)
            this.setState({ loader: false });
            // if agent already verified then no need to furthor varified
            if (checkOtpDat.statusCode === 100 && checkOtpDat.varifyStatus) {
                const url = getDynamicUrl()
                NotificationManager.success("", 'Email is alredy verified', 5000);
                setTimeout(() => {
                    window.location = `${url}/${org_alias}/login`;
                }, 2000)

                return
            }

            if (checkOtpDat.statusCode === 200) {
                this.setState({
                    loader: false,
                    agentID: checkOtpDat.agentID,
                    showNewPasswordForm: true
                });
                let company_name = this.state.company_name;
                let agent_name = checkOtpDat?.agent_name

                let emailBody = [`email=${email.toLowerCase()}&type=verified_email_success&&agent_name=${agent_name}&&company_name=${company_name}`];
                this.sendEmail(emailBody)
                NotificationManager.success("", 'Verification code has been successfully verified', 5000);

            } else if (checkOtpDat.statusCode === 201 || checkOtpDat.statusCode === 202) {

                this.setState({
                    loader: false,
                    verificationcodeError: checkOtpDat.message
                });
            } else {
                this.setState({
                    loader: false,
                    emailError: "The email is not associated with any agent's account."
                });
                NotificationManager.error("", checkOtpDat.message, 5000);
            }
        } else {
            this.setState({ loader: false });
            return;
        }
    };

    /**
     * validate verification form data
     */
    validateVerificationForm = () => {
        const {
            email,
            emailError,
            verificationcode,
        } = this.state;

        let isValid = true
        if (emailError) {
            isValid = false
        }
        if (email.trim() === '') {
            this.setState({ emailError: "Email field can not be blank" })
            isValid = false
        }

        if (verificationcode.trim() === '') {
            this.setState({ verificationcodeError: "Varification code field can not be blank" })
            isValid = false
        }

        if (isValid) {
            return true
        } else {
            return false
        }
    }

    /**
     *  Send varificationcode
     */
    sendVerificationCode = async () => {
        const {
            email,
            emailError,
            org_alias
        } = this.state;

        if (emailError) {
            return false
        }
        if (email.trim() === '') {
            this.setState({ emailError: "Email field can not be blank" })
            return false
        }
        this.setState({ loader: true });
        let ownerDetails = await getOwnerDetails(org_alias);
        if (ownerDetails.status === 200) {
            let ownerData = ownerDetails.data;
            if (ownerData.email === email) {
                this.setState({
                    emailType: 'Owner'
                })
                const getResponce = await sendOwnerResetCode(email);
                if (getResponce.status == 200) {
                    NotificationManager.success("", "If the email is associated with any account then a verification code will get sent to it.", 5000);
                    this.setState({
                        loader: false,
                        showNewPasswordForm: true
                    });
                } else if (getResponce.status === 400) {
                    NotificationManager.error("", "Email is not associated with any account", 5000);
                    this.setState({ loader: false });
                } else {
                    this.setState({ loader: false });
                    NotificationManager.error("", getResponce.message, 5000);
                }
            } else {
                this.setState({
                    emailType: 'Agent'
                })
                const getResponce = await sendAgentResetCode(email, org_alias);
                if (getResponce.status == 200) {
                    NotificationManager.success("", "If the email is associated with an agent's account then a verification code will get sent to it.", 5000);
                    this.setState({
                        loader: false,
                        showNewPasswordForm: true
                    });
                } else if (getResponce.status === 400) {
                    NotificationManager.error("", "Email is not associated with any account", 5000);
                    this.setState({ loader: false });
                } else {
                    this.setState({ loader: false });
                    NotificationManager.error("", getResponce.message, 5000);
                }
            }
        } else {
            this.setState({
                loader: false
            })
        }
        return false;
    }


    /**
     *  re-send varificationcode
     */
    resendVerificationCode = async () => {

        const {
            email,
            emailError,
            agent_org_key
        } = this.state;

        if (emailError) {
            return false
        }
        if (email.trim() === '') {
            this.setState({ emailError: "Email field can not be blank" })
            return false
        }

        this.setState({ loader: true });

        /**
         *  Resent a new Code
         */
        const getResponce = await sendAgentPasswordResetCode(agent_org_key, email)

        if (getResponce.status === 200) {
            let code = getResponce?.verificationCode
            let name = getResponce?.agentName;
            let company_name = this.state.company_name
            var body = [
                `email=${email}&&verification_code=${code}&type=resend_verification_code&agent_name=${name}&company_name=${company_name}`
            ];

            this.sendEmail(body);
            this.setState({
                loader: false,
                agentID: getResponce.agentid,
                company_name: company_name,
                agent_name: name,
                showNewPasswordForm: true
            });
            NotificationManager.success("", "New verification code has been sent to your registered email", 5000);
        } else {
            this.setState({ loader: false });
            NotificationManager.error("", getResponce.message, 5000);
        }
    }

    /**
     * Update Agent New Password
     */
    handleUpdateNewPassword = async () => {
        this.setState({ loader: true, startedTyping: true });
        const {
            email,
            newPassword,
            newRePassword,
            org_alias,
            verificationcode,
            company_name
        } = this.state;

        if (!this.validateAllRequiredField(newPassword, newRePassword)) {
            this.setState({ loader: false });
            return;
        } else {

            // update password if code is varified 
            if (this.state.emailType === 'Owner') {
                const resultData = await resetPasswordOwner(email, newPassword, verificationcode);

                if (resultData.status === 200) {
                    let emailBody = [`email=${email.toLowerCase()}&type=password_reset_success&&req_ip=${this.state.IPv4}&&currnt_url=${DOMAIN_NAME}`];
                    this.sendEmail(emailBody)
                    const url = getDynamicUrl()
                    NotificationManager.success("", 'New password has been saved Successfully ', 5000);
                    setInterval(() => {
                        window.location.href = `${url}/${org_alias}/login`;
                    }, 2000);
                    return
                } else {
                    NotificationManager.error("", resultData.message, 5000);
                    this.setState({ loader: false });
                }
            } else {
                const resultData = await resetPasswordAws(org_alias, email, newPassword, verificationcode)
                if (resultData.status === 200) {
                    let userDetailsOrg = { ownerIdentifire: org_alias }
                    let agentDetails = await getUserDetailsFromEmail(email, userDetailsOrg);
                    let agentName = '';
                    if (agentDetails.status === 200) {
                        agentName = agentDetails.data.last_name && agentDetails.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentDetails.data.first_name + ' ' + agentDetails.data.last_name : agentDetails.data.first_name;
                    }
                    let emailBody = [`email=${email.toLowerCase()}&type=agent_password_reset_success&req_ip=${this.state.IPv4}&currnt_url=${DOMAIN_NAME}&agent_name=${agentName}&company_name=${company_name}`];
                    this.sendEmail(emailBody)
                    const url = getDynamicUrl()
                    NotificationManager.success("", 'New password has been saved Successfully ', 5000);
                    setInterval(() => {
                        window.location.href = `${url}/${org_alias}/login`;
                    }, 2000);
                    return
                } else {
                    NotificationManager.error("", resultData.message, 5000);
                    this.setState({ loader: false });
                }
            }
        }
    }

    /**
     * validate BOth password before save 
     */
    validateAllRequiredField = (newp, newrp) => {

        const {
            email,
            verificationcode

        } = this.state

        let validForm = true;

        if (email.trim() === '') {
            validForm = false
            this.setState({ emailError: 'Field cannot be left blank' })
        }

        if (!this.validateEmail(email)) {
            validForm = false
            this.setState({ emailError: 'Email id is not correct' })
        }

        if (verificationcode.trim() === '') {
            validForm = false
            this.setState({ verificationcodeError: 'Field cannot be left blank' })
        }

        if (newp.trim() === '') {
            validForm = false
            this.setState({ newPasswordError: 'Field cannot be left blank' })
        }

        if (newp.trim() === '') {
            this.setState({ newRePasswordError: 'Field cannot be left blank' })
            validForm = false
        }

        if (!this.validatePassword(newp)) {
            validForm = false
        }

        if (!this.validatePassword(newrp)) {
            validForm = false
        }

        if (newp !== newrp) {
            validForm = false
        }
        if (validForm) {
            return true
        } else {
            return false;
        }
    }

    /**
     * When Agent click resent email varification code URL
     */
    sendEmail = (body) => {
        fetch(`${GATEWAY_URL}/sendEmail`, {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                // console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    render() {
        return (
            <div className="login-container loginBG">

                <NotificationContainer />

                {this.state.loader &&
                    <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>
                }
                <div className="loginPage">
                    <div className="container">
                        <div className="login-header">
                            <img
                                className="loginHeaderLogo"
                                src={require("../../assets/images/appypie-livechat.svg")}
                                alt="logo"
                            />
                        </div>
                    </div>

                    {this.state.showNewPasswordForm ? (
                        <div className="container">
                            <div className="loginBody pt-5">
                                <div className="login-description signup-description veryfy-your-mail-description">
                                    <h5>Set your password</h5>
                                    <p>
                                        Enter a password and then re-type it to confirm
                                        {this.state.emailType}
                                    </p>
                                </div>
                                <div className="row login-body-set-pass">
                                    <div className="col-xs-12 col-md-7 loginFormOrlienparent">
                                        <div className="login-form-reset">

                                            <TextField
                                                error={this.state.emailError === "" ? false : true}
                                                id="standard-password-input"
                                                label={data.Agent.email}
                                                type="text"
                                                margin="normal"
                                                onChange={this.handleEmail}
                                                onKeyPress={this.handleEmail}
                                                value={this.state.email}
                                                required
                                            />
                                            {this.state.emailError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.emailError}</span></div> : <div className="Loginwarning" style={this.state.emailError === "" ? { display: "block" } : { display: "none" }}></div>}


                                            <TextField
                                                error={this.state.verificationcodeError === "" ? false : true}
                                                id="standard-password-input"
                                                label={data.Agent.agentVerificationCode}
                                                type="text"
                                                margin="normal"
                                                onChange={this.handleVarificationCode}
                                                onKeyPress={this.handleVarificationCode}
                                                value={this.state.verificationcode}
                                                required
                                            />
                                            {this.state.verificationcodeError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.verificationcodeError}</span></div> : ''}

                                            <div className="text-right recnet-verification-code " onClick={() => this.sendVerificationCode()}><span className="cursor-pointer"> Resend verification code</span></div>


                                            <TextField
                                                error={this.state.newPasswordError === "" ? false : true}
                                                id="standard-password-input"
                                                label={data.hint.newPassword}
                                                type="password"
                                                margin="normal"
                                                onChange={this.handlePassword}
                                                onKeyPress={this.handlePassword}
                                                value={this.state.newPassword}
                                                autoComplete="current-password"
                                                required
                                            />

                                            {this.state.newPasswordError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.newPasswordError}</span></div> : <div className="Loginwarning" style={this.state.newPasswordError === "" ? { display: "block" } : { display: "none" }}></div>}

                                            <TextField
                                                error={this.state.newRePasswordError === "" ? false : true}
                                                id="standard-password-input"
                                                label={data.hint.newRePassword}
                                                type="password"
                                                margin="normal"
                                                onChange={this.handleRePassword}
                                                onKeyPress={this.handleRePassword}
                                                value={this.state.newRePassword}
                                                autoComplete="current-password"
                                                required
                                            />

                                            {this.state.newRePasswordError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.newRePasswordError}</span></div> : <div className="Loginwarning" style={this.state.newRePasswordError === "" ? { display: "block" } : { display: "none" }}></div>}

                                            <RawTooltip message={data.message} position={"right"}>
                                                <img alt="" src={require("../../assets/img/icon/infoIcon.svg")} />
                                            </RawTooltip>
                                            <div className="pt-8">
                                                <button className="btn-blue white-text btn-radius  px-10 py-4" onClick={this.handleUpdateNewPassword}>Reset Password</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-md-5 login-social-pass-require pt-10 mt-8">
                                        <div className="px-8">
                                            <p className="pass-reqir">Password Requirement</p>
                                            <ul className="pass-ver pl-8" style={{ listStyleType: 'disc' }}>
                                                <li style={{ color: this.state.startedTyping ? (this.state.lengthVerify ? 'green' : 'red') : (null) }}> Length between 8 and 16 characters</li>
                                                <li style={{ color: this.state.startedTyping ? (this.state.lowerCaseVerify && this.state.upperCaseVerify && this.state.specialCharacterVerify && this.state.numericVerify ? 'green' : 'initial') : (null) }}> Password must contain at least 1 character
                                                    <ul className="pl-5" style={{ listStyleType: 'disc' }}>
                                                        <li style={{ color: this.state.startedTyping ? (this.state.lowerCaseVerify ? 'green' : 'red') : (null) }}> in lower case (a-z) </li>
                                                        <li style={{ color: this.state.startedTyping ? (this.state.upperCaseVerify ? 'green' : 'red') : (null) }}> in Upper case (A-Z) </li>
                                                        <li style={{ color: this.state.startedTyping ? (this.state.specialCharacterVerify ? 'green' : 'red') : (null) }}> As spacial characters (!@#$%^&) </li>
                                                        <li style={{ color: this.state.startedTyping ? (this.state.numericVerify ? 'green' : 'red') : (null) }}> As numeric  (0-9) </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container">
                            <div className="loginBody pt-3">
                                <div className="login-description signup-description veryfy-your-mail-description">
                                    <h5>Forget your Password</h5>
                                    <p> Don't Worrry. We'll email you a Verification code </p>
                                </div>
                                <div className="veryfy-your-mail-body">
                                    <div className="veryfy-your-mail-form">
                                        <TextField
                                            error={this.state.emailError === "" ? false : true}
                                            id="standard-password-input"
                                            label={data.Agent.email}
                                            type="text"
                                            margin="normal"
                                            onChange={this.handleEmail}
                                            onKeyPress={this.handleEmail}
                                            value={this.state.email}
                                            required
                                        />
                                        {this.state.emailError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span>{this.state.emailError}</span></div> : <div className="Loginwarning" style={this.state.emailError === "" ? { display: "block" } : { display: "none" }}></div>}

                                        <div className="text-center pt-8">
                                            <button className="btn-blue white-text btn-radius  px-10 py-4" onClick={() => this.sendVerificationCode()}>
                                                Submit
                                            </button>
                                        </div>
                                        <div className="forgotOrSignupLoginPage pt-5">
                                            <a style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => this.props.history.push(`/${this.state.org_alias}/login`)}>
                                                <span className="login ">Back to login</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default AgentResetPassword;
