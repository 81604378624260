/**
 * @description Db model File 
 * @version 1.0
 * @package LiveChat
 * 
 */

// import firebase, { database } from "./../../../config/firebase";
import { IP_STACK_URL, IP_STACK_KEY, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY, commonLoginApi } from './../../../config/siteConfig';
// import db, { fb } from "./../../../config/firebase";
import { getUTCTimeDate } from "../OnboardHelper";
import { getDateInUTC } from "../../livechat/components/Comman";
import moment from "moment-timezone";
import { updateCompanyDetails, editOwnerInAws, getCookie } from "../../../services/service";
import { logInWithCustomToken } from "../../../config/SignupLogin";
import { onValue, ref, onDisconnect, set } from "firebase/database";
import { getApiAccessToken } from "../../../layout/utils";
import firebaseServices from "../../../firebase";
var defaultvalues = require('../../../localization/default-values.json');
var defaultValue = defaultvalues.chat_form;
const md5 = require("md5");
const JSONWEBTOKEN = require('jsonwebtoken');
const JSRSASign = require("jsrsasign");
const key = "$lovecchhaon@#@!7899nknkl@ER@Q&*!";
const header = {
	alg: "HS512",
	typ: "JWT"
};
const sHeader = JSON.stringify(header);
const publicIp = require("public-ip");
const agentInitialData = {
	addedon: 'timstamp',
	agent_short: 'DH',
	alias: '',
	deletetime: 0,
	departments: ['DEFAULT'],
	email: '',
	login_status: 1,
	name: '',
	password: '',
	profile_img: '',
	role: '', // TODO:  Dynamic Image
	status: 1,
	timezone: '',
	updatedon: 0 // TODO: 
}


const chatBotDocData = {
	appointment_scheduled: 1,
	bot_name: 'my bot', //TODO Copmanyname
	bot_template_category: 2,
	chats_transferred: 0,
	create_date: '', // current data time
	domain_url: '', // / todo Live  URl
	feedback_received: 0,
	feedback_received_agent: 0,
	flow_completed: 0,
	inquiries_collected: 0,
	status: 1,
	thank_you_status: true,
	type: 'Agent transfer bot',
	update_date: '',  // timestamp 
	post_chat_check: defaultValue.post_chat_check,
	post_chat_response_type: defaultValue.post_chat_response_type,
	thank_you_message: defaultValue.thank_you_message,
	feedback_message: defaultValue.feedback_message,
	feedback_type: defaultValue.feedback_type,
	feedback_response: defaultValue.feedback_response,
	form_type: defaultValue.form_type,
	pre_chat_form: defaultValue.pre_chat_form,
	post_chat_form: defaultValue.post_chat_form
}

/**
 * initial chat boat 
 */
const chatBoardInitialData = {
	0: {
		UI: 'welcome',
		Validation: {
			doValidate: false,
			type: '',
		},
		action: 'SaveInquiry',
		continue: true,
		data: {
			text: '`Hello! Welcome to Acme. My name is Agent, How may I help you today?`',
		},
		input: '',
		next: 1,
		trigger: [],
	},
	'00': {
		Validation: {
			doValidate: false,
			type: '',
		},
		action: '',
		continue: false,
		message: '',
		input: '',
		next: 0,
		trigger: [],
	},
	'online': {
		welcomemessage: {
			check: defaultValue.online.welcomemessage.check,
			text: defaultValue.online.welcomemessage.text
		},
		name: {
			check: defaultValue.online.name.check,
			required: defaultValue.online.name.required,
			label: defaultValue.online.name.label
		},
		email: {
			check: defaultValue.online.email.check,
			required: defaultValue.online.email.required,
			label: defaultValue.online.email.label
		},
		contact: {
			check: defaultValue.online.contact.check,
			required: defaultValue.online.contact.required,
			label: defaultValue.online.contact.label
		},
		query: {
			check: defaultValue.online.query.check,
			required: defaultValue.online.query.required,
			label: defaultValue.online.query.label
		}
	},
	'offline': {
		welcomemessage: {
			check: defaultValue.offline.welcomemessage.check,
			text: defaultValue.offline.welcomemessage.text
		},
		name: {
			check: defaultValue.offline.name.check,
			required: defaultValue.offline.name.required,
			label: defaultValue.offline.name.label
		},
		email: {
			check: defaultValue.offline.email.check,
			required: defaultValue.offline.email.required,
			label: defaultValue.offline.email.label
		},
		contact: {
			check: defaultValue.offline.contact.check,
			required: defaultValue.offline.contact.required,
			label: defaultValue.offline.contact.label
		},
		query: {
			check: defaultValue.offline.query.check,
			required: defaultValue.offline.query.required,
			label: defaultValue.offline.query.label
		},
		system_response: {
			check: defaultValue.offline.system_response.check,
			text: defaultValue.offline.system_response.text
		}
	},
	'customisation': {
		backgroundChatBot: '#ffffffff',
		botButtonColor: '#2c3049ff',
		botButtonText: 'lora',
		botButtonTextColor: '#ffffffff',
		botTextcolor: '#000',
		chatBotAvtar: '', // TODO: ,
		clientBackgroundColor: '#0184f8',
		clientBubbleClr: '#2c3049ff',
		clientchattextcolor: '#ffffffff',
		designLanguage: 'english(en)',
		designPage: 'default',
		headerClr: '#2c3049ff',
		headerName: 'Livechat bot',
		headertextcolor: '#ffffffff',
		messagebubble: '#2c30494d',
		sendButtonClr: '#2c3049ff',
		textfonts: 'roboto',
		widgetBackgroundColor: '#2c3049ff',
		widgetImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57', // TODO Image,
		widgetPosition: {
			bottom: 8,
			position: 'right',
			side: 8
		},
		widgetSize: 'large',
	},
	thank_you_message: defaultValue.thank_you_message,
	feedback_message: defaultValue.feedback_message,
	feedback_response: defaultValue.feedback_response

}

const ONBOARDINGGUIDE = {
	status: false,
	step: 0
}

const DEFAULTSETTINGS = {
	show_previous_chat: true,
	updatedon: '',
}

const IDLETIME = {
	agent_inactive_check: defaultvalues.timeoutSettings.agent_inactive_check,
	agent_inactive_min: defaultvalues.timeoutSettings.agent_inactive_min,
	agent_inactive_status: defaultvalues.timeoutSettings.agent_inactive_status,
	agent_reply_check: defaultvalues.timeoutSettings.agent_reply_check,
	agent_reply_min: defaultvalues.timeoutSettings.agent_reply_min,
	trigger_message: defaultvalues.timeoutSettings.trigger_message,
	trigger_message_check: defaultvalues.timeoutSettings.trigger_message_check,
	trigger_message_end: defaultvalues.timeoutSettings.trigger_message_end,
	trigger_message_interval: defaultvalues.timeoutSettings.trigger_message_interval,
	trigger_message_repeat: defaultvalues.timeoutSettings.trigger_message_repeat,
	trigger_message_time: defaultvalues.timeoutSettings.trigger_message_time,
	updatedon: '', // dateTime
	visitor_reply_check: defaultvalues.timeoutSettings.visitor_reply_check,
	visitor_reply_min: defaultvalues.timeoutSettings.visitor_reply_min
}

const NOTIFICATIONS_SOUND = {
	incoming_desktop_notification: true,
	incoming_play_sound: true,
	new_message_desktop_notification: true,
	new_message_play_sound: true,
	updatedon: ''
}

const GDPRCONSENT = {
	consent_from: defaultvalues.gdprConsent.consent_from,
	consent_content: defaultvalues.gdprConsent.consent_content,
	privacy_link: defaultvalues.gdprConsent.privacy_link,
	privacy_text: defaultvalues.gdprConsent.privacy_text,
	button_text: defaultvalues.gdprConsent.button_text
}

const DEPARTMENTS = {
	description: 'Default Team',
	name: 'All agents'
}


const defRoles = [
	{
		description: 'In addition to the administrator privileges, the owner also has complete access to data management and subscription. Permission for the owner role cannot be modified.',
		name: 'Owner',
		editable: false,
		type: 'default'
	},
	{
		description: 'In addition to regular agent privileges, the administrator can edit widget and account settings, manage agents, roles, permissions, and more. Permission for the administrator role cannot be modified.',
		name: 'Admin',
		editable: false,
		type: 'default'
	},
	{
		description: 'Agent is the most basic role in an account, and there primary responsibilty is to serve chats. Permission for the agent role can be modified.',
		name: 'Agent',
		editable: false,
		type: 'default'
	}
]

/**
 * Create initial Agent And Chat Data
 * 
 */
export const createInitialAgentAndChatBot = async (uid, objectData) => {
	var roleid = Date.now() + '' + Math.floor(1000000 + Math.random() * 9000000);

	/* For extra security sign in with token start */
	const agentuniquID = getUniqueID();
	const isLogedInInSecurity = await logInWithCustomToken(uid, agentuniquID);
	/* For extra security sign in with token end */

	// Get a new write batch
	let batch = firebaseServices.db.batch();
	defRoles.forEach((role, i) => {
		role.addedon = Date.now();
		var docId = '';
		if (role.name === 'Owner') {
			docId = roleid;
		} else {
			docId = Date.now() + '' + Math.floor(1000000 + Math.random() * 9000000);
		}
		let roleRef_i = firebaseServices.db.collection('users').doc(`${uid}`).collection('roles').doc(docId.toString());
		batch.set(roleRef_i, role);
	})

	/* For extra security sign in with token commented below line and add above */
	//const agentuniquID = getUniqueID();

	// Set the value of 'agentuniquID'
	let agentcRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('agents').doc(agentuniquID);
	let utcTime = await getDateInUTC();
	const emailArray = getNameAndDomainFormEmail(objectData.email);
	const newAgentData = agentInitialData;
	newAgentData.addedon = Date.now()
	newAgentData.role = roleid
	var firstName = '';
	var lastName = '';
	if (emailArray.name.split(' ').length > 1) {
		firstName = emailArray.name.split(' ').slice(0, -1).join(' ');
		lastName = emailArray.name.split(' ').slice(-1).join(' ');
	} else {
		firstName = emailArray.name;
		lastName = DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
	}
	var editOwner = await editOwnerInAws(firstName, firstName, lastName, objectData.email, moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess(), '', '', '', '');
	console.log('editOwnerInAws >>>> ', editOwner);
	newAgentData.name = emailArray.name
	newAgentData.roleType = 'Owner'
	newAgentData.last_login = getUTCTimeDate();
	newAgentData.online_status = 1
	newAgentData.last_activity_time = utcTime;
	newAgentData.timezone = moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess();
	batch.set(agentcRef, newAgentData);
	// insert chatbots
	chatBotDocData.thank_you_message = chatBotDocData.thank_you_message.replace("support@example.com", objectData.email);
	const initChatDoc = chatBotDocData
	initChatDoc.bot_name = objectData.companyName
	initChatDoc.create_date = Date.now()
	initChatDoc.update_date = Date.now()
	initChatDoc.domain_url = ''
	initChatDoc.feedback_message = `Please rate your conversation with ${objectData.companyName}.`;

	let chatBotRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('chatbots').doc(agentuniquID)
	batch.set(chatBotRef, initChatDoc);

	// insert editedBots
	const customChatbot = chatBoardInitialData;
	customChatbot.customisation.widgetImage = objectData.fbwtimage // save firebase store this image Display when first bot reply
	customChatbot.customisation.chatBotAvtar = 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/brandlogo%2Fbrand_logo.png?alt=media&token=7f5ec5b3-426f-43c9-957c-a8a9a7c9a389' // save firbase store White Image
	customChatbot.customisation.widgetBackgroundColor = objectData.settingbgColor
	customChatbot.customisation.headerClr = objectData.settingbgColor
	customChatbot.customisation.sendButtonClr = objectData.settingbgColor
	customChatbot.customisation.botButtonColor = objectData.settingbgColor
	customChatbot.customisation.clientBubbleClr = objectData.settingbgColor
	customChatbot.customisation.headerName = objectData.companyName
	customChatbot.customisation.clientBackgroundColor = objectData.clientBackgroundColor
	customChatbot[0].data.text = `Hello! Welcome to ${objectData.companyName}. My name is ${newAgentData.name}, How may I help you today?`;
	customChatbot.online.welcomemessage.text = `Hello! Welcome to ${objectData.companyName}. My name is ${newAgentData.name}, How may I help you today?`;


	let editedBots = firebaseServices.db.collection('users').doc(`${uid}`).collection('chatbots').doc(agentuniquID).collection('editedBots').doc('template')
	// batch.update(sfRef, {population: 1000000});
	batch.set(editedBots, customChatbot);


	DEFAULTSETTINGS.addedon = Date.now();
	let defaultSettingRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('settings').doc('DEFAULTSETTINGS')
	batch.set(defaultSettingRef, DEFAULTSETTINGS);

	GDPRCONSENT.addedon = Date.now();
	let gdprSettingRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('gdpr').doc('DEFAULT')
	batch.set(gdprSettingRef, GDPRCONSENT);


	IDLETIME.addedon = Date.now();
	let idealTimeRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('settings').doc('IDLETIME')
	batch.set(idealTimeRef, IDLETIME);

	DEPARTMENTS.addedon = Date.now();
	let departmtRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('departments').doc('DEFAULT');
	batch.set(departmtRef, DEPARTMENTS);

	let notifictionSoundRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('settings').doc('NOTIFICATIONS_SOUND')
	batch.set(notifictionSoundRef, NOTIFICATIONS_SOUND);

	let incomingGuideRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('onboarding_guide').doc('incoming');
	batch.set(incomingGuideRef, ONBOARDINGGUIDE);

	let livechatGuideRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('onboarding_guide').doc('livechat');
	batch.set(livechatGuideRef, ONBOARDINGGUIDE);

	let setupGuideRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('onboarding_guide').doc('setup');
	batch.set(setupGuideRef, ONBOARDINGGUIDE);

	let visitorGuideRef = firebaseServices.db.collection('users').doc(`${uid}`).collection('onboarding_guide').doc('visitor');
	batch.set(visitorGuideRef, ONBOARDINGGUIDE);

	// Delete Ex the city 'LA'
	// let laRef = firebaseServices.db.collection('cities').doc('LA');
	// batch.delete(laRef);
	// Commit the batch
	return batch.commit().then(function () {
		return {
			agentid: agentuniquID,
			userID: uid
		}

	}).catch(err => {
		// console.log(err);
		return false
	});
}


/**
 * Get name  and Domain name with email
 * @param {string} email 
 */
export const getNameAndDomainFormEmail = (email) => {
	let name = email.substring(0, email.lastIndexOf("@"));
	let domain = email.substring(email.lastIndexOf("@") + 1);
	return {
		name: name,
		domain: domain
	}

}


/**
 * Crate a new Users 
 * @param {string} email 
 * @param {string} Pasword 
 * @param {string} tocken 
 */
export const createNewUser = async (defaultUSerObject, n = 0) => {
	console.log('defaultUSerObject >>>', defaultUSerObject, n);
	// TODO:  need To Create All User Respective Data Here  
	let utcTime = await getDateInUTC();
	const paymentData = {
		addedon: utcTime,
		chat_messages: "500",
		cost: '',
		plan_type: 'Free',
		plan_id: '',
		billing_cycle: '',
		next_billing_date: '',
		no_of_agents: 1
	};


	const inserData = {
		email: md5(defaultUSerObject.email),
		// password: Pasword,
		token: defaultUSerObject.accessToken,
		company_name: defaultUSerObject.companyName ? defaultUSerObject.companyName : '',
		organization_alias: defaultUSerObject.organization_alias ? defaultUSerObject.organization_alias : '',
		widget_status: false,
		addedon: utcTime,
		updatedon: utcTime,
		deactivated: false,
		deleted: false,
		current_no_agent: 1,
		no_chat_served: 0,
		chat_limited: 500,
		no_of_agents: 1,
		payment_data: paymentData,
		notification_email: [defaultUSerObject.email],
		email_24: false,
		email_48: false,
		email_testdrive: 0,
		havelaunchedLiveChat: false,
		right_visitor_scheduler: "free"
	}

	const updateData = {
		companyName: defaultUSerObject.companyName ? defaultUSerObject.companyName : 'Welcome Lorem',
		settingrgbColor: defaultUSerObject.settingrgbColor ? defaultUSerObject.settingrgbColor : '',
		settingbgColor: defaultUSerObject.settingbgColor ? defaultUSerObject.settingbgColor : '#2c3049ff',
		settingChatIcon: defaultUSerObject.settingChatIcon ? defaultUSerObject.settingChatIcon : '',
		email: defaultUSerObject.email,
		whiteImage: defaultUSerObject.whiteImage ? defaultUSerObject.whiteImage : '',
		chatBlackImage: defaultUSerObject.chatBlackImage ? defaultUSerObject.chatBlackImage : 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57',
		fbwtimage: defaultUSerObject.fbwtimage ? defaultUSerObject.fbwtimage : '',
		clientBackgroundColor: defaultUSerObject.clientBackgroundColor ? defaultUSerObject.clientBackgroundColor : '',
		sendButtonClr: defaultUSerObject.settingbgColor ? defaultUSerObject.settingbgColor : '#2c3049ff',
	}

	let clientCountryAndIp = await getClientIpAndCountry();
	const ip4 = clientCountryAndIp.ip ? clientCountryAndIp.ip : '';
	const country = clientCountryAndIp.country ? clientCountryAndIp.country : '';
	// return publicIp.v4().then(async (ip4) => {
	// 	const countryDetails = await getCountrybyIp(ip4);
	// 	const country = countryDetails ? countryDetails : '';
	const DocID = getUniqueID();
	let insertDocData = inserData;
	insertDocData.country = country;
	insertDocData.ip4 = ip4;
	return firebaseServices.db.collection('users').where("email", '==', md5(defaultUSerObject.email)).where('deleted', '==', false).get().then(async (docs) => {
		console.log('docs', docs);
		if (docs.empty) {
			return firebaseServices.db
				.collection('users')
				.doc(`${DocID}`)
				.set(insertDocData)
				.then(async () => {
					// firebase set Method Does not return any thing
					const platformLanguage = "English";
					const emailArray = getNameAndDomainFormEmail(defaultUSerObject.email);
					const clientName = emailArray.name;
					if (defaultUSerObject.email && defaultUSerObject.companyName && DocID && platformLanguage && clientName && country) {
						var body = [
							`accountId=${encodeURIComponent(DocID)}`
						]
						//fetch(MAIL_FUNCTION_URL+"/mailFunctions/product_email_owner", {
						fetch(GATEWAY_URL + "/product_email_owner", {
							method: "post",
							body: body,
							headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
						}).then(response => {
							console.log(response);
						})
							.catch(e => { });
					}

					await updateCompanyDetails(DocID, defaultUSerObject.email, defaultUSerObject.organization_alias ? defaultUSerObject.organization_alias : '', defaultUSerObject.companyName ? defaultUSerObject.companyName : '');
					localStorage.removeItem('clientIp');
					localStorage.removeItem('clientCountry');
					//const chatID = await createInitialAgentAndChatBot(DocID, updateData);
					let file_name = getCookie('APPYID');
					addUser(DocID, defaultUSerObject.email, defaultUSerObject.organization_alias ? defaultUSerObject.organization_alias : '', defaultUSerObject.companyName ? defaultUSerObject.companyName : '', file_name);
					await createInitialAgentAndChatBot(DocID, updateData);
					// await upDateUserProfileWithData(DocID, updateData)
					return DocID;
				})
				.catch(error => {
					console.error("Error on adding main_user: ", error);
					return false;
				});
		} else {
			return false;
		}
	})
	// });
}

function addUser(user_id, email, organizationAlias, companyName, file_name) {
	return new Promise((resolve, reject) => {
		fetch(commonLoginApi + 'api/product/productuser', {
			method: 'POST',
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json',
				"Authorization": "Bearer " + localStorage.getItem('api_access_token')
			},
			body: JSON.stringify({ productname: 'livechat', email: email, user_id: user_id, company_identifier: organizationAlias, company: companyName , file_name: file_name}),
		}).then((response) => response.json())
			.then((responseJson) => {

				if (responseJson.status == 401) {
					getApiAccessToken();

				}
				else {
					if (responseJson.status == 200) {
						localStorage.setItem("app_access", 'yes');
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	});

}

export const saveIpInDb = async (ip, reason, response) => {
	let serverTimseStamp = await getDateInUTC();
	firebaseServices.db.collection('IPSTACK').doc(ip).set({
		ip: ip,
		addedon: serverTimseStamp,
		from: 'backend',
		reason: reason,
		response: response
	})
}

export const getCountrybyIp = (ip4) => {
	return new Promise(async (resolve, reject) => {
		try {
			fetch("https://chatbottest.appypie.com/getAdress?ip=" + ip4).then(
				res => {
					if (res.status === 404 || res.status === 500) {
						return res.text();
					} else {
						return res.json()
					}
				})
				.then(async (result) => {
					if (result.CountryName != undefined && result.CountryName != 'undefined') {
						resolve(result.CountryName);
					} else {
						saveIpInDb(ip4, 'not found', result);
						fetch(IP_STACK_URL + '/' + ip4 + "?access_key=" + IP_STACK_KEY)
							.then(resp => resp.json())
							.then((resp) => {
								if (resp.country_name !== undefined && resp.country_name !== 'undefined') {
									resolve(resp.country_name)
								} else {
									resolve('');
								}
							}).catch((err) => {
								console.log('IPSTACK_ERROR', err);
								resolve('');
							})
					}
				})
				.catch(async (err) => {
					saveIpInDb(ip4, 'error', err);
					fetch(IP_STACK_URL + '/' + ip4 + "?access_key=" + IP_STACK_KEY)
						.then(resp => resp.json())
						.then((resp) => {
							if (resp.country_name != undefined && resp.country_name != 'undefined') {
								resolve(resp.country_name)
							} else {
								resolve('');
							}
						}).catch((err) => {
							console.log('IPSTACK_ERROR', err);
							resolve('');
						})
				});
		} catch (err) {
			resolve('');
		}
	})
}


/**
 * update a  Users collection 
 * @param {string} userID 
 * @param {Object} data 
 */
export const upDateUserProfileWithData = async (DocID, data) => {

	// TODO:  need To Create All User Respective Data Here  
	return firebaseServices.db
		.collection('users')
		.doc(`${DocID}`)
		.update(data)
		.then((res) => {
			// firebase set Method Does not return any thing

			return true;
		})
		.catch(error => {
			console.error("Error on adding main_user: ", error);
			return false;
		});
}

/**
 * @description update Data in chatbots configuraion
 * 
 * @param {string} uid User Id 
 * @param {string} chatid User Chat Id
 * @param {Object} data Object   
 */
export const updateAgentAndChatBotdata = async (uid, chatid, data) => {

	return firebaseServices.db.doc(`/users/${uid}/chatbots/${chatid}/editedBots/template`).update(data)
		.then((res) => {
			// firebase set Method Does not return any thing

			return true;
		})
		.catch(error => {
			console.error("Error on adding main_user: ", error);
			return false;
		});
}

/**
 * @description Update Chat Data In table 
 * 
 * @param {string} uid 
 * @param {chatid} chatid 
 * @param {object} data 
 */
export const updateAgentData = async (uid, chatid, data) => {

	console.log('test>>>', uid, chatid, data);
	const agentuniquID = getUniqueID();
	chatid = chatid ? chatid : agentuniquID;

	return firebaseServices.db.doc(`/users/${uid}/agents/${chatid}`).update(data)
		.then((res) => {
			// firebase set Method Does not return any thing

			return true;
		})
		.catch(error => {
			console.error("Error on adding main_user: ", error);
			return false;
		});

}


/**
 * Create a Unique ID With DateTime 
 */
export const makingId = () => {
	var temp = Date.now();
	return temp.toString(36);
};
/**
 * Get Unique ID with Combination of Random And Date
 */
export function getUniqueID() {
	return Date.now() + ((Math.random() * 100000).toFixed())
}


/**
 * Update Tocken In Collection comming from AWs login
 * @param {string} token 
 * @param {string} email 
 */
export const updateSessioninCollection = async (token, docId) => {
	const accessToken = token ? token : '';
	/* commented by dharmesh for ticket - 882
	const userRef = firebaseServices.db.collection("users")
	return userRef.where('email', '==', email).where("deleted",'==',false)	
		.limit(1)
		.get().then(userQuerySnapshot => {
			if (!userQuerySnapshot.empty) {
				var userIDarray = userQuerySnapshot.docs.map(userDocumnetSnapShot => {
					return userDocumnetSnapShot.id
				})
				var id = userIDarray[0];


				return userRef.doc(id).update({ token: token })
					.then(() => {
						return true
					}).catch(err => {
						// console.log(err);
						return false
					})
			} else {
				return false
			}
		})
		*/
	try {
		const userRef = firebaseServices.db.collection("users").doc(docId);
		return userRef
			.get().then(userQuerySnapshot => {
				if (userQuerySnapshot.exists) {
					return userRef.update({ token: accessToken })
						.then(() => {
							return true
						}).catch(err => {
							// console.log(err);
							return false
						})
				} else {
					return false
				}
			})
	} catch (err) {
		return false;
	}

};


/**
 * Getting UserInformation
 * @param {string} email 
 */
export const gettingSessionIdFromCollection = async email => {
	var res = "";
	res = await firebaseServices.db
		.collection("users")
		.where('email', '==', email)
		.limit(1)
		.get()
		.then(userQuerySnapshot => {

			if (!userQuerySnapshot.empty) {

				var tokenArray = userQuerySnapshot.docs.map(userDocumentSnapshot => {
					return userDocumentSnapshot.data()
				})
				return tokenArray[0];
			} else {
				return false
			}

		}).catch(err => {
			return false
		})
	return res;
};


/**
 * get Owner ID
 */
export const getOwnerRoleID = async () => {

	return firebaseServices.db
		.collection("agent-roles")
		.where('name', '==', 'Owner')
		.limit(1)
		.get()
		.then(roleQuerySnapshot => {

			if (!roleQuerySnapshot.empty) {
				var tokenArray = roleQuerySnapshot.docs.map(roleDocumentSnapshot => {
					return roleDocumentSnapshot.id
				})
				return tokenArray[0];
			} else {
				return false
			}

		}).catch(err => {
			return false
		})
}


/**
 * Gey Owner detail for Loggin
 * @param {string} UserDocID 
 */
export const getOwnerUserDataForLogin = async (UserDocID, email) => {
	console.log('UserDocID>>>> ', UserDocID);
	console.log('email>>>>> ', email);
	return firebaseServices.db.collection("users").doc(UserDocID).get().then(async (ownerLogin) => {
		if (ownerLogin.exists) {
			const loginUserData = {
				...ownerLogin.data(),
				agentId: '',
				ownerId: ownerLogin.id,
				ownerIdentifire: ownerLogin.data().organization_alias,
				roleType: 'Owner'
			}

			const agentData = await firebaseServices.db.collection("users").doc(UserDocID).collection('agents').where('roleType', '==', 'Owner').limit(1).get().then((ownerAgentsQuerySnapshot) => {
				if (!ownerAgentsQuerySnapshot.empty) {

					const agentArray = ownerAgentsQuerySnapshot.docs.map(owrRow => {
						return {
							...owrRow.data(),
							agentId: owrRow.id
						}
					})
					return agentArray;
				} else {
					return false;
				}

			}).catch(err => {
				// console.log(err)
				return false;
			})

			console.log('agentData >>>> ', agentData);

			if (agentData) {
				return {
					...loginUserData,
					...agentData[0]
				}
			} else {
				return {
					...loginUserData
				}
			}
			// localStorage.setItem('agent',JSON.stringify(loginUserData));
			// this.props.history.push("/agent-setup");
		} else {
			// console.log('user not found');
			return false;
		}
	}).catch(err => {
		// console.log(err);
		return false;
	})
}

/**
 * get Dynamic url of current site
 */
export const getDynamicUrl = () => {

	const host = window.location.host
	const protocol = window.location.protocol
	const dybamicURL = `${protocol}//${host}`
	return dybamicURL

}


/**
 * check Company alise in collcetion 
 * @param {string} userID 
 * @param {Object} data 
 */
export const checkCompanyAliasAndCreateUnique = async (aliasname) => {

	// TODO:  need To Create All User Respective Data Here  
	return firebaseServices.db
		.collection('users')
		.where('organization_alias', '==', aliasname)
		.limit(1)
		.get()
		.then((resQuerySnapshot) => {
			// firebase set Method Does not return any thing

			if (!resQuerySnapshot.empty) {
				// return resQuerySnapshot.data();
				const resultArray = resQuerySnapshot.docs.map(resDOcSnapshot => {
					return resDOcSnapshot.data();
				})
				return resultArray;

			} else {
				return false;
			}
		})
		.catch(error => {
			console.error("Error on adding main_user: ", error);
			return false;
		});
}

/**
 * add last activity time on each page when agent is Visit 
 */
export const updateAgentLastActivityTime = async () => {
	var agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
	if (agent && agent.ownerId && agent.agentId) {

		try {
			let utcTime = await getDateInUTC();
			testRealTimeFn();
			firebaseServices.db.collection("users").doc(agent.ownerId).collection("agents").doc(agent.agentId).update({
				last_activity_time: utcTime
			}).then((updateStatus) => {
				// console.log('update last data of agent in activity table');
			}).catch(err => {
				console.log('this is error', err);
			})
		} catch (err) {
			console.log('this is error', err);
		}
	}
}

/**
 * 
 * @param {*} dockey 
 * @param {*} email 
 * @param {*} verificationcode 
 */
export const checkVerificationCodeWithDB = async (dockey, email, verificationcode, expiryTime, jwToken) => {

	try {
		return firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").where("email", "==", `${email}`).where('deletetime', '==', 0).limit(1)
			.get().then(async (agentQueryDocumentSnapshot) => {


				if (!agentQueryDocumentSnapshot.empty) {
					const agentData = agentQueryDocumentSnapshot.docs[0].data();
					const agentid = agentQueryDocumentSnapshot.docs[0].id;
					const agRef = agentQueryDocumentSnapshot.docs[0].ref
					const agent_name = agentData.name
					const varifyStatus = agentData.varifyStatus

					//var currentmilisecond = Date.now();
					//var milliseconds = agentData.varification_code_time?.seconds * 1000 + agentData.varification_code_time?.nanoseconds / 1000000
					// console.log((currentmilisecond - milliseconds)/(60*60*1000));
					//var hours = (currentmilisecond - milliseconds) / (60 * 60 * 1000);

					if (varifyStatus) {
						return {
							statusCode: 100, // error found
							status: false,
							message: 'Already varified',
							varifyStatus: varifyStatus
						}
					}

					if (jwToken === agentData.verifyToken) {
						if (agentData.verificationCode === verificationcode) {
							let utcTime = await getDateInUTC();
							if (expiryTime >= utcTime) {
								// varify email is true;
								await agRef.update({ emailVerify: true });
								return {
									statusCode: 200, // error found
									status: true,
									message: 'Successfully Verified',
									agentID: agentid,
									agent_name: agent_name,
									varifyStatus: varifyStatus
								}

							} else {
								return {
									statusCode: 201, // error found
									status: false,
									message: 'Verification link has been expired'
								}
							}
						} else {

							return {
								statusCode: 202, // error found
								status: false,
								message: 'Verification link has been expired'
							}
						}
					} else {
						return {
							statusCode: 202, // error found
							status: false,
							message: 'Verification link has been expired'
						}
					}
				} else {
					return {
						statusCode: 405, // error found
						message: "The email is not associated with any agent's account"
					}
				}

			}).catch(err => {

				return {
					statusCode: 406, // error found
					message: err.message
				}
			})
	} catch (err) {

		return {
			statusCode: 406, // error found
			message: err.message
		}
	}
}

/**
 * Create verification code for Agent verification
 * 
 * @param {string} dockey 
 * @param {string} email 
 */
export const sendAgentVerificationCode = async (dockey, email) => {

	try {
		const responce = await firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").where("email", "==", `${email}`).where('deletetime', '==', 0).limit(1)
			.get().then(async (agentQueryDocumentSnapshot) => {

				if (!agentQueryDocumentSnapshot.empty) {
					const agentData = agentQueryDocumentSnapshot.docs[0].data();
					const agentid = agentQueryDocumentSnapshot.docs[0].id;
					const agentName = agentData.name;
					const varifyStatus = agentData.varifyStatus;
					const agentRefrence = firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").doc(agentid)
					const randomNumber = Math.floor(Math.random() * 1000000) + 1;


					if (varifyStatus) {
						return {
							status: 100,
							message: "Already varified",
							verificationCode: randomNumber,
							agentName: agentName,
							varifyStatus: varifyStatus
						}
					}

					let addedon = await getDateInUTC();
					let code = randomNumber
					let expiry = addedon + 2 * 60 * 60 * 1000;
					let tokenDetails = {
						code: code,
						email: email,
						expiry: expiry
					}
					let token = await jwtEncrypt(tokenDetails);
					const agerresp = agentRefrence.update({
						varification_code_time: firebaseServices.fb.firestore.FieldValue.serverTimestamp(),
						verificationCode: randomNumber,
						verifyToken: token
					}).then(resultWrite => {
						const resturnRes = {
							status: 200,
							message: "updated",
							verificationCode: randomNumber,
							agentName: agentName,
							varifyStatus: varifyStatus,
							token: token
						}
						return resturnRes
					}).catch(err => {
						const resturnRes = {
							status: 404,
							message: err.message,
						}
						return resturnRes
					})
					return agerresp;
				} else {


					const resturnRes = {
						status: 405,
						message: "The email is not associated with any agent's account.",
					}
					return resturnRes
				}

			}).catch(err => {
				const resturnRes = {
					status: 406,
					message: err.message,
				}
				return resturnRes
			})

		return responce;
	} catch (err) {

		const resturnRes = {
			status: 406,
			message: err.message,
		}
		return resturnRes
	}
}

/**
 * 
 * @param {*} agent_org_key 
 * @param {*} agentID 
 */
export const updateAgentPassword = async (dockey, agentID, pwd) => {

	const agentRefrence = firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").doc(agentID)
	const responcedata = await agentRefrence.get().then(async agentdocumentSnapshot => {
		if (agentdocumentSnapshot.exists) {
			const password = md5(pwd)

			const agent_name = agentdocumentSnapshot.data().name
			return await agentRefrence.update({
				password: password,
				varifyStatus: true,
				verificationCode: '###'
			}).then(updateResult => {
				return {
					statusCode: 200,
					message: 'record found',
					agent_name: agent_name
				}

			}).catch(err => {
				return {
					statusCode: 404,
					message: err.message
				}
			})

		} else {
			return {
				statusCode: 404,
				message: '!Opps reords not found'
			}
		}
	}).catch(err => {
		return {
			statusCode: 404,
			message: err.message
		}
	})
	return responcedata;
}

export const updateAgentPasswordWithVerificationCode = async (dockey, email, pwd, key) => {
	const agentRefrence = firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").where('email', '==', email).where('deletetime', '==', 0).limit(1)
	const responcedata = await agentRefrence.get().then(async agentdocumentSnapshot => {
		if (!agentdocumentSnapshot.empty) {
			const password = md5(pwd)
			const agentData = agentdocumentSnapshot.docs[0].data()
			const rowagentRef = agentdocumentSnapshot.docs[0].ref
			const agent_name = agentData.name
			const resetPasswordCode_time = agentData.resetPasswordCode_time
			const resetPasswordCode = agentData.resetPasswordCode

			var currentmilisecond = Date.now();
			var milliseconds = resetPasswordCode_time?.seconds * 1000 + resetPasswordCode_time?.nanoseconds / 1000000
			// console.log((currentmilisecond - milliseconds)/(60*60*1000));
			var minutes = (currentmilisecond - milliseconds) / (60 * 1000);

			if (resetPasswordCode !== parseInt(key)) {
				return {
					statusCode: 201,
					message: "Invalid Code"
				}
			}

			if (minutes > 10) {
				return {
					statusCode: 202,
					message: "Code is expired"
				}
			}
			return await rowagentRef.update({
				password: password,
				// varifyStatus: true,
				resetPasswordCode: ''
			}).then(updateResult => {
				return {
					statusCode: 200,
					message: 'Record found',
					agent_name: agent_name
				}

			}).catch(err => {
				return {
					statusCode: 401,
					message: err.message
				}
			})

		} else {
			return {
				statusCode: 402,
				message: '!Opps reords not found'
			}
		}
	}).catch(err => {
		return {
			statusCode: 402,
			message: err.message
		}
	})
	return responcedata;
}


/**
 * 
 * @param {*} dockey 
 * @param {*} email 
 */
export const sendAgentPasswordResetCode = async (dockey, email) => {

	try {
		const responce = await firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").where("email", "==", `${email}`).where("deletetime", '==', 0).limit(1)
			.get().then((agentQueryDocumentSnapshot) => {

				if (!agentQueryDocumentSnapshot.empty) {
					const agentData = agentQueryDocumentSnapshot.docs[0].data();
					const agentid = agentQueryDocumentSnapshot.docs[0].id;
					const agentName = agentData.name;
					// const varifyStatus=  agentData.varifyStatus;
					const agentRefrence = firebaseServices.db.collection("users").doc(`${dockey}`).collection("agents").doc(agentid)
					const randomNumber = Math.floor(Math.random() * 1000000) + 1;
					const agerresp = agentRefrence.update({
						resetPasswordCode_time: firebaseServices.fb.firestore.FieldValue.serverTimestamp(),
						resetPasswordCode: randomNumber
					}).then(resultWrite => {
						const resturnRes = {
							status: 200,
							message: "updated",
							verificationCode: randomNumber,
							agentName: agentName,
							agentid: agentid
							// varifyStatus: varifyStatus
						}
						return resturnRes
					}).catch(err => {
						const resturnRes = {
							status: 404,
							message: err.message,
						}
						return resturnRes
					})
					return agerresp;
				} else {
					const resturnRes = {
						status: 405,
						message: "The email is not associated with any agent's account.",
					}
					return resturnRes
				}

			}).catch(err => {
				const resturnRes = {
					status: 406,
					message: err.message,
				}
				return resturnRes
			})

		return responce;
	} catch (err) {

		return {
			status: 406,
			message: err.message,
		}
	}
}

export const changeAgentStatus = async () => {
	var agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
	if (agent && agent.ownerId && agent.agentId) {
		let IdleTime = await getIdleTime(agent.ownerId);
		if (IdleTime.activityCheck === true) {
			firebaseServices.db.collection('users').doc(agent.ownerId).collection('agents').where('online_status', '==', 1).get().then(async (docs) => {
				if (docs.empty === false) {
					let utcTime = await getDateInUTC();
					utcTime = utcTime - (IdleTime.idleTime * 60 * 1000);
					docs.forEach((doc) => {
						if (doc.id !== agent.agentId && (utcTime >= doc.data().last_activity_time || doc.data().last_activity_time === undefined)) {
							firebaseServices.db.collection('users').doc(agent.ownerId).collection('agents').doc(doc.id).update({ online_status: 0 });
						}
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
}

const getIdleTime = async (ownerId) => {
	let idleTime = 0;
	let activityCheck = false;
	await firebaseServices.db.collection('users').doc(ownerId).collection('settings').doc('IDLETIME').get().then((docs) => {
		if (docs.exists) {
			idleTime = docs.data().agent_inactive_min;
			activityCheck = docs.data().agent_inactive_check;
		}
	}).catch((err) => {
		console.log(err)
	});
	return { idleTime: idleTime, activityCheck: activityCheck };
}

export const jwtEncrypt = async (tokenDetails) => {
	var sPayload = JSON.stringify(tokenDetails);
	const sJWT = JSRSASign.jws.JWS.sign("HS512", sHeader, sPayload, key);
	return sJWT;
}

export const jwtDecrypt = async (token) => {
	const aJWT = token.split(".");
	//const uHeader = JSRSASign.b64utos(aJWT[0]);
	const uClaim = JSRSASign.b64utos(aJWT[1]);
	//const pHeader = JSRSASign.jws.JWS.readSafeJSONString(uHeader);
	const pClaim = JSRSASign.jws.JWS.readSafeJSONString(uClaim);
	return pClaim;
}

export const jwtVerify = async (token) => {
	return new Promise((res, rej) => {
		JSONWEBTOKEN.verify(token, key, (err, decryptToken) => {
			if (err) {
				res(false);
			}
			res(decryptToken);
		})
	})
}

export const testRealTimeFn = () => {
	console.log('testRealTimeFn');
	var agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
	if (agent && agent.ownerId && agent.agentId) {
		try {
			var amOnline = firebaseServices.fb.database().ref("/.info/connected"); //new Firebase('https://<demo>.firebaseio.com/.info/connected');
			console.log('amOnline', amOnline);
			var userRef = firebaseServices.fb.database().ref("allUsers/" + agent.ownerId + "/presence/" + agent.agentId); //new Firebase('https://<demo>.firebaseio.com/presence/' + userid);
			amOnline.on('value', function (snapshot) {
				if (snapshot.val()) {
					console.log('snapshot.val()', snapshot.val());
					userRef.onDisconnect().set(false);
					userRef.set(true);
					console.log('userRef', userRef);
				}
			});
		} catch (err) { }
	}
}

export const checkSession = () => {
	return new Promise((resolve, reject) => {
		var agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
		if (agent && agent.ownerId) {
		  const connectedRef = ref(firebaseServices.database, '/.info/connected');
		  const myConnectionsRef = ref(firebaseServices.database, '/users/' + agent.ownerId + '/agents/' + agent.agentId);
		  onValue(connectedRef, (snap) => {
			
			if (snap.val()) {
			  console.log("connected");
			  set(myConnectionsRef, true);
			  onDisconnect(myConnectionsRef).set(false);
			  
			  resolve(true);
			} else {
			  onDisconnect(myConnectionsRef).set(false);
			  
			  console.log("not connected");
			  resolve(false);
			}
		  });
		} 
	  });
}

export const getClientIpAndCountry = async () => {
	return new Promise(async (resolve, reject) => {
		let clientIp = localStorage.getItem('clientIp') ? localStorage.getItem('clientIp') : false;
		let clientCountry = localStorage.getItem('clientCountry') ? localStorage.getItem('clientCountry') : false;
		if (clientIp && clientCountry) {
			resolve({ ip: clientIp, country: clientCountry });
		} else if (clientIp) {
			let clientCountry = await getCountrybyIp(clientIp);
			localStorage.setItem('clientCountry', clientCountry);
			resolve({ ip: clientIp, country: clientCountry });
		} else {
			publicIp.v4().then(async (ip4) => {
				let clientCountry = await getCountrybyIp(ip4);
				localStorage.setItem('clientIp', ip4);
				localStorage.setItem('clientCountry', clientCountry);
				resolve({ ip: ip4, country: clientCountry });
			}).catch((err) => {
				resolve({ ip: '', country: '' })
			});
		}
	})
}